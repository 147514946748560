import React from 'react'
import { IoIosArrowBack, IoIosArrowForward, IoIosPricetag, IoMdArrowDropdown } from "react-icons/io";
import BarGraph from './BarChart';
import { BiExport } from "react-icons/bi";
import { HiChartSquareBar } from "react-icons/hi";
import { IoNewspaper } from "react-icons/io5";
import { FaUserPlus } from "react-icons/fa";
import Donut from './Donut';


const tableData = [
  {
    id: "01",
    name: "UI/UX Designing From Scratch",
    sales: "45%",
    popularity: "60%",
    primarycolor: "#0095FF",
    lightcolor: "#CDE7FF",
    salesbg: "bg-blue-50"
  },
  {
    id: "02",
    name: "Frontend Development",
    sales: "29%",
    popularity: "45%",
    primarycolor: "#00E096",
    lightcolor: "#8CFAC7",
    salesbg: "bg-green-50"
  },
  {
    id: "03",
    name: "Introduction to Python",
    sales: "18%",
    popularity: "75%",
    primarycolor: "#884DFF",
    lightcolor: "#C5A8FF",
    salesbg: "bg-purple-50"
  },
  {
    id: "04",
    name: "Graphic Designing",
    sales: "25%",
    popularity: "35%",
    primarycolor: "#FF8F0D",
    lightcolor: "#FFD5A4",
    salesbg: "bg-orange-50"
  },
]


const todaySales = [
  {
    icon: <HiChartSquareBar />,
    quant: "$1K",
    title: "Total Sales",
    sale: 8,
    bgColor: "bg-[#FFE2E5]",
    iconbg: "bg-[#FA5A7D]",
  },
  {
    icon: <IoNewspaper />,
    quant: "300",
    title: "Total Order",
    sale: 5,
    bgColor: "bg-[#FFF4DE]",
    iconbg: "bg-[#FF947A]",
  },
  {
    icon: <IoIosPricetag />,
    quant: "5",
    title: "Product Sold",
    sale: 12,
    bgColor: "bg-[#dcfce7]",
    iconbg: "bg-[#3cd856]",
  },
  {
    icon: <FaUserPlus />,
    quant: "8",
    title: "New Customers",
    sale: 5,
    bgColor: "bg-[#F3E8FF]",
    iconbg: "bg-[#BF83FF]",
  },
]

const AdminDashboard = () => {
  return (
    <div className=' w-full flex flex-col gap-10 px-5 md:px-10 py-28 bg-[#EEF5FF]' >
      <div className='w-full flex md:flex-row flex-col gap-10'>

        {/* bar chart */}
        <div className=' w-full md:w-[40%] flex flex-col p-5 bg-white rounded-lg shadow-lg'>
          <div className='flex flex-col sm:flex-row justify-between items-start xs:items-center'>
            <div className='flex flex-col gap-1'>
              <span className='text-base text-gray-500'>Total Revenue</span>
              <h1 className='text-2xl text-black font-semibold'>$980,273.00</h1>
            </div>
            <button className='px-2 py-2 rounded-xl bg-[#fff8e5] sm:self-start self-end flex items-center font-semibold gap-1 text-[#ffc029] shadow-md text-xs'>
              This Year <IoMdArrowDropdown className=' text-lg' />
            </button>
          </div>

          <div className='w-full'>
            <BarGraph />
          </div>
        </div>

        {/* top products table */}
        <div className=' w-full md:w-[60%] flex flex-col p-5 bg-white rounded-lg shadow-lg '>
          <h1 className='text-2xl text-black font-semibold'>Top Products</h1>
          <div className='w-full overflow-x-scroll '>
          <table className='w-full text-left mt-10 '>
            <thead>
              <tr>
                <th className='border-y px-4 py-3 text-xs text-gray-500'>#</th>
                <th className='border-y  py-3 text-xs text-gray-500'>Name</th>
                <th className='border-y px-4 py-3 text-xs text-gray-500'>Popularity</th>
                <th className='border-y px-4 py-3 text-xs text-gray-500 flex justify-center'>Sales</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((d, i) => (
                <tr key={d.id}>
                  <td className='px-4 py-3 text-blue-gray-600'>{d.id}</td>
                  <td className='py-3 text-sm text-blue-gray-600'>{d.name}</td>
                  <td className='py-3'>
                    <div className='w-full relative h-1.5 rounded-full' style={{ backgroundColor: d.lightcolor }}>
                      <div className='absolute top-0 left-0 rounded-full h-1.5' style={{ width: d.popularity, backgroundColor: d.primarycolor }}></div>
                    </div>
                  </td>
                  <td className='px-4 py-3 flex justify-center'>
                    <div className={`flex w-fit h-fit text-sm rounded-lg border items-center justify-center px-3 py-1 ${d?.salesbg}`}
                        style={{ borderColor: d.primarycolor, color: d.primarycolor }}>
                      {d.sales}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
              </div>
        </div>
      </div>


      <div className='w-full flex xl:flex-row flex-col gap-10'>

        {/* todays sale */}
        <div className=' w-full xl:w-[70%] flex flex-col p-5 bg-white rounded-lg shadow-lg'>
        <div className='flex justify-between items-center'>
            <div className='flex flex-col gap-1'>
              <span className='text-2xl text-gray-900 font-semibold'>Today's Sale</span>
              <h1 className='text-sm text-gray-500 '>Sales Summary</h1>
            </div>
            <button className='px-2 py-2 rounded-md flex items-center font-semibold gap-1 text-gray-600 border border-gray-400 text-xs'>
            <BiExport className=' text-lg' /> Export 
            </button>
          </div>

          <div className='w-full grid grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-6 mt-10'>
            {todaySales.map((d,i) => (
              <div className={`col-span-1 p-5 shadow-md rounded-lg flex flex-col gap-3 ${d.bgColor}`}>
              <div className={`p-1 w-fit rounded-full ${d.iconbg} text-white text-2xl`}>
                {d.icon}
              </div>
              <h1 className='text-xl font-semibold text-[#151D48]'>{d.quant}</h1>
              <h1 className='text-lg  text-gray-800'>{d.title}</h1>
              <span className='text-base text-[#4079ED] '>+{d.sale}% from yesterday</span>
            </div>
            ))}
          </div>
        </div>


        {/* donut chart */}
        <div className=' w-full xl:w-[30%] flex flex-col p-5 bg-white rounded-lg shadow-lg'>
          <div className=' flex w-full justify-between'>
          <IoIosArrowBack />
            <h1 className=' text-lg font-semibold'>March 2023</h1>
            <IoIosArrowForward />
          </div>
          <div className='w-full'>
            <Donut />
          </div>
        </div>
      

      </div>
    </div>
  )
}

export default AdminDashboard;