import React from 'react'
import { PiWarningFill } from 'react-icons/pi'

const JobDesc = () => {
  return (
    <div className='w-full flex flex-col mt-10 p-10'>
      <div className='w-full flex rounded-xl border-2 border-gray-300 p-10'>

        <div className='w-2/3 '>
          <div className='flex gap-4'>
            <img src='/home-banner/logo-foot.png' className=' w-14 h-14'/>
            <div className='flex flex-col gap-2'>
              <h1 className='text-2xl font-semibold'>Blockmosaic</h1>
              <span className='text-sm'>Aptitude Preparation</span>
            </div>
          </div>
        </div>

        <div className='w-1/3 flex flex-col gap-3 pl-5 border-l-2 border-gray-300'>
          <span className='text-base'>Work From Home</span>
          <span className='text-sm text-wrap'>Aptitude, Mathematical, English & Reasoning , Communication</span>
          <span className='text-sm'>50 -100 Employees</span>
          <span className='text-sm'>Upto 10k stipend (Performance based and for Complete 3 month duration)</span>
        </div>
  
      </div>

    <div className='w-full flex gap-10 rounded-xl my-14 '>

      <div className='w-2/3 flex flex-col gap-3 rounded-lg border-2 border-gray-300 p-10 '>
        {/* ------About company----------- */}
        <h1 className=' text-2xl font-semibold'>About the Company</h1>
        <p className='text-base text-gray-700'>Blockmosaic is more than just an educational platform it's a dynamic community and movement towards a tech-savvy future. Founded by a team of passionate individuals, Chain Edge aims to revolutionize the way people learn about blockchain and AI. We believe in the power of education to empower individuals to shape the world around them, and we're committed to providing accessible, engaging, and effective learning experiences.</p>

        {/* ------Job Description----------- */}
        <h1 className=' text-2xl font-semibold mt-6'>Job Description</h1>
        <span className='text-base font-semibold text-gray-700'>Job Overview:</span>
        <p className='text-base text-gray-700'>We are looking for experienced and enthusiastic teaching assistants to join our team at Blockmosaic. As a teaching assistant, you will provide support and guidance to students enrolled in our courses by addressing their curriculum-related queries. The position requires you to work for daily 2-hour slots for a duration of 3 months, 6 days a week. </p>

        <span className='text-base font-semibold text-gray-700 mt-5'>Key Responsibilities:</span>
        <ol className='text-base  text-gray-700 list-decimal'>
          <li>Assist students in resolving their curriculum-related doubts. </li>
          <li>Actively participate in the Doubt Resolution Process during the allotted slot. Being present and available online during the slot is mandatory.</li>
        </ol>

        <span className='text-base font-semibold mt-5 text-gray-700'>Please Note:</span>
        <ol className='text-base text-gray-700 list-decimal '>
          <li>The TA slots will be allotted based on availability during the recruitment process. </li>
          <li>Applying for this opportunity does not guarantee selection as a TA. Selections will be made based on the requirements, cutoff scores (both in Batch Score and TA Assessment), and application date.</li>
        </ol>

        {/* ------Job Description----------- */}
        <h1 className=' text-2xl font-semibold mt-6'>Requirements</h1>
        <ol className='text-base text-gray-700 list-decimal '>
          <li>You must be a current or former student of the Aptitude Preparation course at Blockmosaic. </li>
          <li>Excellent communication and interpersonal skills.</li>
          <li>Strong understanding of the subject matter and ability to explain concepts clearly</li>
        </ol>


      </div>

      <div className='w-1/3 h-fit flex flex-col gap-3 p-5 border-2 rounded-lg border-gray-300'>
        <h1 className=' text-2xl font-semibold'>Application Status</h1>

        <div className='flex w-full gap-5 pb-5 border-b-2 border-gray-300'>
          <div className='flex items-center'>
          <PiWarningFill className='text-5xl text-red-500' />
          </div>

          <div className='flex flex-col gap-2'>
            <span className='text-sm font-semibold text-gray-500'>You are not eligible for this job opening</span>
            <span className='text-sm text-gray-700'>You need to complete at least one course from each of the following groups with 80% of score</span>
          </div>
        </div>
      </div>

    </div>


    </div>
  )
}

export default JobDesc