const Pending = () => {
    return (

<div>
            <div className="mt-6 border border-blue-300 p-16 text-center rounded">
              <p>No Resolved Doubts</p>
            </div>
          </div>
    );
};

export default Pending;