import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AdminDashboardIcon, AdminInstructorIcon, AdminCourseIcon, AdminCommonIcon } from "../../component/Icon";

const Sidebar = () => {
  const location = useLocation(); // Hook to get the current route
  const [isInstructorDropdownOpen, setInstructorDropdownOpen] = useState(false);
  const [isCoursesDropdownOpen, setCoursesDropdownOpen] = useState(false);
  const [isEnrollmentDropdownOpen, setEnrollmentDropdownOpen] = useState(false);
  const [isInvoicesDropdownOpen, setInvoicesDropdownOpen] = useState(false);
  const [isTADropdownOpen, setTADropdownOpen] = useState(false);

  // Function to determine if the tab is active
  const isActive = (path) => location.pathname === path;

  return (
    <div className="lg:w-64 w-full bg-white p-4 lg:relative lg:h-full lg:top-0 lg:left-0 lg:bottom-0 lg:z-10 lg:overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-200">
      {/* Logo */}
      <div className=" flex w-full justify-between"> 
      <Link to="/">
        <img
          src="/home-banner/logo.png"
          alt="Admin Panel Logo"
          className="w-40 h-8 mb-6"
        />
      </Link>

      
      </div>

      {/* Menu Items */}
      
      <ul>
        <Link to="/admin/dashboard">
          <li>
            <button
              className={`block p-4 w-full text-left flex items-center bg-white text-[#61677F] gap-7 hover:bg-[#EEF5FF] hover:rounded ${
                isActive("/admin/dashboard") ? "bg-[#EEF5FF] text-[#3780e6]" : ""
              }`}
            >
              <AdminDashboardIcon />
              Dashboard
            </button>
          </li>
        </Link>

        {/* Instructor Dropdown */}
        <li>
          <button
            onClick={() => setInstructorDropdownOpen(!isInstructorDropdownOpen)}
            className={`block p-4 w-full text-left flex items-center justify-between text-[#61677F] gap-8 hover:bg-[#EEF5FF] hover:rounded ${
              isInstructorDropdownOpen ? "bg-gray-200" : ""
            }`}
          >
            <AdminInstructorIcon />
            Instructors
            <span
              className={`transform transition-transform ${
                isInstructorDropdownOpen ? "rotate-90" : "rotate-0"
              }`}
            >
              ⮞
            </span>
          </button>
          {isInstructorDropdownOpen && (
            <ul className="pl-6">
              <Link to="/admin/instructor">
                <li
                  className={`block p-2 w-full text-left text-[#61677F] hover:bg-[#EEF5FF] hover:rounded ${
                    isActive("/admin/instructor") ? "bg-[#EEF5FF] text-[#3780e6]" : ""
                  }`}
                >
                  Instructor List
                </li>
              </Link>
              <Link to="/admin/instructor-pending-requests">
                <li
                  className={`block p-2 w-full text-left text-[#61677F] hover:bg-[#EEF5FF] hover:rounded ${
                    isActive("/admin/instructor-pending-requests")
                      ? "bg-[#EEF5FF] text-[#3780e6]"
                      : ""
                  }`}
                >
                  Pending Requests
                </li>
              </Link>
            </ul>
          )}
        </li>

        {/* Courses Dropdown */}
        <li>
          <button
            onClick={() => setCoursesDropdownOpen(!isCoursesDropdownOpen)}
            className={`p-4 w-full text-left flex items-center justify-between text-[#61677F] gap-8 hover:bg-[#EEF5FF] hover:rounded ${
              isCoursesDropdownOpen ? "bg-gray-200" : ""
            }`}
          >
            <AdminCourseIcon />
            Courses
            <span
              className={`transform transition-transform ${
                isCoursesDropdownOpen ? "rotate-90" : "rotate-0"
              }`}
            >
              ⮞
            </span>
          </button>
          {isCoursesDropdownOpen && (
            <ul className="pl-6">
              <Link to="/admin/courses-category">
                <li
                  className={`block p-2 w-full text-left text-[#61677F] hover:bg-[#EEF5FF] hover:rounded ${
                    isActive("/admin/courses-category")
                      ? "bg-[#EEF5FF] text-[#3780e6]"
                      : ""
                  }`}
                >
                  Categories
                </li>
              </Link>
              <Link to="/admin/courses-list">
                <li
                  className={`block p-2 w-full text-left text-[#61677F] hover:bg-[#EEF5FF] hover:rounded ${
                    isActive("/admin/courses-list") ? "bg-[#EEF5FF] text-[#3780e6]" : ""
                  }`}
                >
                  Course List
                </li>
              </Link>
            </ul>
          )}
        </li>

        {/* Enrollments Dropdown */}
        <li>
          <button
            onClick={() => setEnrollmentDropdownOpen(!isEnrollmentDropdownOpen)}
            className={`p-4 w-full text-left flex items-center justify-between text-[#61677F] gap-8 hover:bg-[#EEF5FF] hover:rounded ${
              isEnrollmentDropdownOpen ? "bg-gray-200" : ""
            }`}
          >
            <AdminCommonIcon />
            Enrollments
            <span
              className={`transform transition-transform ${
                isEnrollmentDropdownOpen ? "rotate-90" : "rotate-0"
              }`}
            >
              ⮞
            </span>
          </button>
          {isEnrollmentDropdownOpen && (
            <ul className="pl-6">
              <Link to="/admin/enroll-history">
                <li
                  className={`block p-2 w-full text-left text-[#61677F] hover:bg-[#EEF5FF] hover:rounded ${
                    isActive("/admin/enroll-history") ? "bg-[#EEF5FF] text-[#3780e6]" : ""
                  }`}
                >
                  Enroll History
                </li>
              </Link>
            </ul>
          )}
        </li>

        {/* Invoices Dropdown */}
        {/* <li>
          <button
            onClick={() => setInvoicesDropdownOpen(!isInvoicesDropdownOpen)}
            className={`p-4 w-full text-left flex items-center justify-between text-[#61677F] gap-8 hover:bg-[#EEF5FF] hover:rounded ${
              isInvoicesDropdownOpen ? "bg-gray-200" : ""
            }`}
          >
            <AdminCommonIcon />
            Invoices
            <span
              className={`transform transition-transform ${
                isInvoicesDropdownOpen ? "rotate-90" : "rotate-0"
              }`}
            >
              ⮞
            </span>
          </button>
          {isInvoicesDropdownOpen && (
            <ul className="pl-6">
              <li className="block p-2 w-full text-left text-[#61677F] hover:bg-[#EEF5FF] hover:rounded">
                Student
              </li>
              <li className="block p-2 w-full text-left text-[#61677F] hover:bg-[#EEF5FF] hover:rounded">
                Instructor
              </li>
            </ul>
          )}
        </li> */}

        {/* Teaching Assistant Dropdown */}
        <li>
          <button
            onClick={() => setTADropdownOpen(!isTADropdownOpen)}
            className={`p-4 w-full text-left flex items-center justify-between text-[#61677F] hover:bg-[#EEF5FF] hover:rounded ${
              isTADropdownOpen ? "bg-gray-200" : ""
            }`}
          >
            <AdminCommonIcon />
            Teaching Assistant
            <span
              className={`transform transition-transform ${
                isTADropdownOpen ? "rotate-90" : "rotate-0"
              }`}
            >
              ⮞
            </span>
          </button>
          {isTADropdownOpen && (
            <ul className="pl-6">
              <Link to="/admin/ta-list">
                <li
                  className={`block p-2 w-full text-left text-[#61677F] hover:bg-[#EEF5FF] hover:rounded ${
                    isActive("/admin/ta-list") ? "bg-[#EEF5FF] text-[#3780e6]" : ""
                  }`}
                >
                  TA List
                </li>
              </Link>
              <Link to="/admin/ta-progress">
                <li
                  className={`block p-2 w-full text-left text-[#61677F] hover:bg-[#EEF5FF] hover:rounded ${
                    isActive("/admin/ta-progress") ? "bg-[#EEF5FF] text-[#3780e6]" : ""
                  }`}
                >
                  TA Progress
                </li>
              </Link>
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
