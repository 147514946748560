import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faSortAmountAsc,
  faUserSlash,
  faEye,
  faMagnifyingGlass,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const base_url = process.env.REACT_APP_BASE_URL;

function InstructorTab() {
  const [instructors, setInstructors] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false);
  const [selectedInstructor, setSelectedInstructor] = useState("");
  const [suspensionReason, setSuspensionReason] = useState("");
  const [customSuspensionReason, setCustomSuspensionReason] = useState("");
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState();
  const [isSuspending, setIsSuspending] = useState(false);
  const [loading, setLoading] = useState(true);

  const buttonRefs = useRef([]);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchInstructors = async () => {
      setLoading(true);
      const token = localStorage.getItem("token");
      try {
        const response = await axios.get(`${base_url}/users?role=Coordinator`, {
          headers: {
            Accept: "*/*",
            Token: token,
          },
        });

        console.log("API Response:", response);

        const data = response.data.responseData;

        if (Array.isArray(data)) {
          const formattedInstructors = data.map((instructor) => ({
            name: `${instructor.fullName}`,
            id: `${instructor._id}`,
            courses: instructor.courses,
            phone: instructor.mobile || "-",
            country: instructor.country || "-",
            status: instructor.is_active || "Inactive",
            createdCourses: instructor.createdCourses,
          }));
          setInstructors(formattedInstructors);
        } else {
          console.error("Unexpected data format:", data);
        }
      } catch (error) {
        console.error("Error Fetching instructors:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchInstructors();
  }, [suspensionReason]);

  const toggleDropdown = (index) => {
    if (dropdownIndex === index) {
      setDropdownIndex(null);
    } else {
      setDropdownIndex(index);

      const button = buttonRefs.current[index];
      const rect = button.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left,
      });
    }
  };

  const handleCloseDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdownIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleCloseDropdown);
    return () => {
      document.removeEventListener("mousedown", handleCloseDropdown);
    };
  }, []);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdownSort = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCloseSuspendModal = () => {
    setIsSuspendModalOpen(false);
  };
  const handleSuspendModal = async (id) => {
    setSelectedInstructor(id);
    setIsSuspendModalOpen(true);
  };
  const handleViewDetail = async (id) => {
    navigate(`/admin/instructor-details/${id}`);
  };
  const handleSuspend = async () => {
    if (!selectedInstructor) {
      console.error("No instructor selected for suspension");
      return;
    }

    const reason =
      suspensionReason === "Custom Reason"
        ? customSuspensionReason
        : suspensionReason;

    const token = localStorage.getItem("token");
    console.log("Token:", token);

    if (!token) {
      console.error("Token is missing!");
      return;
    }

    setIsSuspending(true);

    try {
      const response = await axios.get(
        `${base_url}/suspend-instructor?id=${selectedInstructor}&reason=${suspensionReason}`,
        {
          headers: {
            Token: token,
          },
        }
      );

      console.log("Suspend Response:", response.data); // Log the response data

      if (response.data.success) {
        toast("Instructor suspended successfully!")
        handleCloseSuspendModal();
        setSelectedInstructor("");
        setSuspensionReason("");
      } else {
        console.error("Failed to suspend instructor:", response.data);
        toast("Failed to suspend instructor.")
        if (response.data.responseCode === 403) {
          console.error("Invalid session token or unauthorized.");
        }
      }
    } catch (error) {
      console.error(
        "Error suspending instructor:",
        error.response || error.message
      
      );
      if (error.response && error.response.status === 403) {
        console.error("Invalid session token or unauthorized.");
      }
      toast("Error occurred while suspending instructor.")
    } finally {
      setIsSuspending(false);
    }
  };

  const handleApprove = async (id) => {
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(
        `${base_url}/action-instructor?id=${id}&action=Approve`,
        {
          headers: {
            token: token,
          },
        }
      );

      console.log("Approve Response:", response.data.responseData);

      if (response.data.success) {
        toast("Instructor activated successfully!")
        setInstructors((prevInstructors) => {
          return prevInstructors.map((instructor) => {
            if (instructor.id === id) {
              return { ...instructor, status: "Approve" };
            }
            return instructor;
          });
        });
        setDropdownIndex(null);
      } else {
        console.error(
          "Failed to approve instructor:",
          response.data.responseData
        );
        toast("Failed to activate instructor.")
      }
    } catch (error) {
      console.error("Error approving instructor:", error);
      toast("Error occurred while activating instructor.")
    }
  };

  return (
    <div className="instructor-tab p-4 md:p-6 bg-[#EEF5FF] h-full">
      <div className="relative flex flex-col md:flex-row justify-center items-center mb-4">
        <div className="relative w-full md:w-1/3 mb-4 md:mb-0">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#9EB1C2]"
          />
          <input
            type="text"
            placeholder="Search by Name"
            className="pl-10 p-2 w-full border border-gray-300 rounded-xl text-center placeholder-[#9EB1C2] text-[#9EB1C2]"
          />
        </div>
      </div>

      {/* Filters and Sorting Options */}
      <div className="flex flex-col md:flex-row justify-between items-center mt-8 mb-4">
        <div className="flex justify-end w-full items-center mb-4 md:mb-0">
          <button className="p-2 pr-4 pl-4 border border-gray-300 rounded-xl bg-white">
            <FontAwesomeIcon icon={faFilter} className="text-[#9EB1C2]" />
          </button>
          <div className="ml-4 p-2 pr-4 pl-4 border border-gray-300 rounded-xl bg-white">
            <button onClick={toggleDropdownSort}>
              <FontAwesomeIcon
                icon={faSortAmountAsc}
                className="text-[#9EB1C2]"
              />
              <span className="ml-2 text-sm font-semibold text-[#9EB1C2] relative">
                Sort: Status
              </span>
            </button>
          </div>

          {isDropdownOpen && (
            <div className="absolute right-0 mt-44 w-48 bg-white border border-gray-300 rounded-xl shadow-lg z-50">
              <ul className="py-2 text-[#9EB1C2]">
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                  Status
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                  Country
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                  Category
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* Instructor List */}
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="w-12 h-12 border-4 border-t-transparent border-blue-500 rounded-full animate-spin"></div>
        </div>
      ) : (
        <div className="overflow-x-auto lg:overflow-x-visible xl:overflow-x-visible">
          <table className="w-full bg-white rounded shadow relative">
            <thead>
              <tr>
                <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                  Instructor
                </th>
                <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                  Enrolled Courses
                </th>
                <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                  Phone
                </th>
                <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                  Country
                </th>
                <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                  Active Courses
                </th>
                <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                  Status
                </th>
                <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {instructors.map((instructor, index) => (
                <tr
                  key={index}
                  className="border-b text-[#9EB1C2] border-gray-300"
                >
                  <td className="p-2">{instructor.name}</td>
                  <td className="p-2">
                    <div className="flex flex-col items-start">
                      {instructor.courses && instructor.courses.length > 0 ? (
                        <>
                          <span>{instructor.courses.join(", ")}</span>
                          <Link to="#" className="mt-2 text-[#3A8BC3]">
                            View All Courses
                          </Link>
                        </>
                      ) : (
                        <span>-</span>
                      )}
                    </div>
                  </td>
                  <td className="p-2">{instructor.phone}</td>
                  <td className="p-2">{instructor.country}</td>
                  <td className="p-2">
                    {Array.isArray(instructor.createdCourses) &&
                    instructor.createdCourses.length > 0
                      ? instructor.createdCourses.length
                      : "-"}
                  </td>

                  <td className="p-2 flex items-center">
                    <span
                      className={`inline-block px-2 py-1 rounded-full text-sm ${
                        instructor.status === "Approve"
                          ? "text-green-700 bg-green-200"
                          : "text-red-700 bg-red-200"
                      }`}
                    >
                      {instructor.status === "Approve" ? "Active" : "Suspend"}
                    </span>
                  </td>
                  <td className="p-2 relative">
                    <button
                      ref={(el) => (buttonRefs.current[index] = el)}
                      onClick={() => toggleDropdown(index)}
                      className="p-2 bg-inherit rounded-full"
                    >
                      &#65049;
                    </button>
                    {dropdownIndex === index && (
                      <div
                        ref={dropdownRef} // Attach the ref to the dropdown
                        className="absolute z-50 w-40 bg-white border border-gray-300 rounded shadow-lg"
                      >
                        <ul>
                          <li>
                            <button
                              onClick={() => handleSuspendModal(instructor.id)}
                              className={`w-full text-left p-2 hover:bg-gray-100 flex items-center ${
                                instructor.status === "Suspend"
                                  ? "cursor-not-allowed text-gray-400"
                                  : ""
                              }`}
                              disabled={instructor.status === "Suspend"}
                            >
                              <FontAwesomeIcon
                                icon={faUserSlash}
                                className="mr-2"
                              />
                              Suspend
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => handleViewDetail(instructor.id)}
                              className="w-full text-left p-2 hover:bg-gray-100 flex items-center"
                            >
                              <FontAwesomeIcon icon={faEye} className="mr-2" />
                              View Details
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => handleApprove(instructor.id)}
                              className={`w-full text-left p-2 hover:bg-gray-100 flex items-center ${
                                instructor.status === "Approve"
                                  ? "cursor-not-allowed text-gray-400"
                                  : ""
                              }`}
                              disabled={instructor.status === "Approve"}
                            >
                              <FontAwesomeIcon
                                icon={faUserPlus}
                                className="mr-2"
                              />
                              Active
                            </button>
                          </li>
                        </ul>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {/* Suspend Modal */}
      {isSuspendModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-xl w-full sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl">
            <h2 className="text-3xl text-center mb-10 text-[#5A5A5A]">
              Suspending Instructor
            </h2>

            <div className="mb-4 relative">
              <label className="absolute left-16 lg:left-44 md:left-40 sm:left-28 top-[-10px] text-[#9EB1C2] bg-white px-2 text-sm font-semibold">
                Suspension Reason
              </label>
              <select
                value={suspensionReason}
                onChange={(e) => setSuspensionReason(e.target.value)}
                className="max-w-sm w-full p-2 border border-gray-300 rounded ml-0 md:ml-16 placeholder-[#9EB1C2] text-[#9EB1C2] mb-6"
              >
                <option value="">Select Reason</option>
                <option value="Disciplinary Issue">Disciplinary Issue</option>
                <option value="Custom Reason">Custom Reason</option>
              </select>

              {/* Conditional rendering of the text field */}
              {suspensionReason === "Custom Reason" && (
                <div className="mt-4">
                  <label className="block mb-2 text-[#9EB1C2] font-semibold">
                    Custom Suspension Reason
                  </label>
                  <input
                    type="text"
                    value={customSuspensionReason}
                    onChange={(e) => setCustomSuspensionReason(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded text-[#9EB1C2] placeholder-[#9EB1C2]"
                    placeholder="Enter custom reason"
                  />
                </div>
              )}
            </div>

            <div className="flex justify-center md:justify-end mt-6">
              <button
                onClick={handleCloseSuspendModal}
                className="px-4 py-2 bg-[#F4F4F4] rounded mr-2 text-[#848484] mb-4 md:mb-0"
              >
                Cancel
              </button>
              <button
                onClick={handleSuspend}
                className={`px-4 py-2 rounded mb-4 md:mb-0 flex items-center justify-center ${
                  isSuspending
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-[#3A8BC3] text-white"
                }`}
                disabled={isSuspending}
              >
                {isSuspending ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                    ></path>
                  </svg>
                ) : (
                  "Suspend"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default InstructorTab;
