import React from 'react';
import EventsSection from './eventSection';
import WebinarsSection from './webinarSection';

const WebinarPage = () => {
  return (
    <div className="container mx-auto px-4">
      <EventsSection />
      <WebinarsSection />
    </div>
  );
};

export default WebinarPage;
