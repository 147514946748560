import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faSortAmountAsc,
  faMagnifyingGlass,
  faEllipsisVertical,
  faCheck,
  faXmark,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import toast from "react-hot-toast";

const PendingDetails = () => {
  const [pendings, setPendings] = useState([]);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const buttonRefs = useRef([]);
  const dropdownRef = useRef(null);

  const base_url = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");

  const getStatusClass = (is_active) => {
    switch (is_active) {
      case "Active":
        return "text-green-700 bg-green-200";
      case "Inactive":
        return "text-red-700 bg-red-200";
      case "Pending":
        return "text-yellow-700 bg-yellow-200";
      case "Suspend":
        return "text-blue-700 bg-blue-200";
      default:
        return "text-gray-700 bg-gray-200";
    }
  };

  const toggleDropdown = (index) => {
    if (dropdownIndex === index) {
      setDropdownIndex(null);
    } else {
      setDropdownIndex(index);

      const button = buttonRefs.current[index];
      const rect = button.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left,
      });
    }
  };

  const toggleDropdownSort = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const fetchPendings = async () => {
    try {
      if (!token) {
        console.error("No token found in local storage");
        return; // Exit early if no token
      }

      const response = await axios.get(
        `${base_url}/instructor-pending-requests`,
        {
          headers: {
            accept: "*/*",
            token: token,
          },
        }
      );

      const fetchedPendings = Array.isArray(response.data.responseData)
        ? response.data.responseData
        : [];
      setPendings(fetchedPendings);
      console.log(fetchedPendings); // Log the data to debug
    } catch (error) {
      console.error("Error fetching pending requests:", error);
      setError("Failed to fetch pending requests. Please try again later.");
      toast("Failed to fetch pending requests.")
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPendings();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        dropdownIndex !== null
      ) {
        setDropdownIndex(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownIndex]);

  const handleDropdownAction = async (action, pendingIndex) => {
    const pending = pendings[pendingIndex];
  
    if (!token) {
      console.error("No token found");
      return;
    }
  
    
    const updatedPendings = [...pendings];
    if (action === "Approve") {
      updatedPendings.splice(pendingIndex, 1); 
    } else if (action === "Decline") {
      updatedPendings[pendingIndex] = {
        ...pending,
        is_active: "Suspend", 
      };
    }
    setLoading(true);
    setPendings(updatedPendings);
  
    const url = `${base_url}/action-instructor?id=${pending._id}&action=${action}`;
  
    try {
      const response = await axios.get(url, {
        headers: { accept: "*/*", token: token },
      });
  
      if (!response.data.responseData.success) {
        console.error("Action failed:", response.data.responseData.message);
        toast(`${action} action successful!`)
      
        fetchPendings(); 
      }
    } catch (error) {
      console.error(`Error performing ${action} action:`, error);
      toast(`Error performing ${action}. Please try again.`)
      fetchPendings(); 
    } finally {
      setLoading(false);
      setDropdownIndex(null);
    }
  };
  
  return (
    <div className="pending-tab p-4 md:p-6 bg-[#EEF5FF] h-full">
      <div className="relative flex flex-col md:flex-row justify-center items-center mb-4">
        <div className="relative w-full md:w-1/3 mb-4 md:mb-0">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#9EB1C2]"
          />
          <input
            type="text"
            placeholder="Search by Name"
            className="pl-10 p-2 w-full border border-gray-300 rounded-xl text-center placeholder-[#9EB1C2] text-[#9EB1C2]"
          />
        </div>
      </div>

      {/* Filters and Sorting Options */}
      <div className="flex flex-col md:flex-row justify-between items-center mt-8 mb-4">
        <div className="flex justify-end w-full items-center mb-4 md:mb-0">
          <button className="p-2 pr-4 pl-4 border border-gray-300 rounded-xl bg-white">
            <FontAwesomeIcon icon={faFilter} className="text-[#9EB1C2]" />
          </button>
          <div className="ml-4 p-2 pr-4 pl-4 border border-gray-300 rounded-xl bg-white">
            <button onClick={toggleDropdownSort}>
              <FontAwesomeIcon
                icon={faSortAmountAsc}
                className="text-[#9EB1C2]"
              />
              <span className="ml-2 text-sm font-semibold text-[#9EB1C2] relative">
                Sort: Status
              </span>
            </button>
          </div>

          {isDropdownOpen && (
            <div className="absolute right-0 mt-44 w-48 bg-white border border-gray-300 rounded-xl shadow-lg z-50">
              <ul className="py-2 text-[#9EB1C2]">
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                  Status
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                  Country
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                  Category
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      {/* Pending List */}
      <div className="overflow-x-auto lg:overflow-x-visible xl:overflow-x-visible">
        <table className="relative w-full bg-white rounded shadow">
          <thead>
            <tr>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                Instructor
              </th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                Email
              </th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                Phone
              </th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                Country
              </th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                Status
              </th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="6" className="text-center p-4">
                  <div className="flex justify-center items-center">
                    <div className="w-10 h-10 border-4 border-blue-400 border-t-transparent border-solid rounded-full animate-spin"></div>
                  </div>
                </td>
              </tr>
            ) : error ? (
              <tr>
                <td colSpan="6" className="text-center p-4 text-red-600">
                  {error}
                </td>
              </tr>
            ) : pendings.length === 0 ? (
              <tr>
                <td colSpan="6" className="text-center p-4 text-gray-500">
                  No pending request available
                </td>
              </tr>
            ) : (
              pendings.map((pending, index) => (
                <tr
                  key={index}
                  className="border-b text-[#9EB1C2] border-gray-300"
                >
                  <td className="p-2">{pending.fullName}</td>
                  <td className="p-2">{pending.email}</td>
                  <td className="p-2">{pending.mobile || "-"}</td>
                  <td className="p-2">{pending.country || "-"}</td>
                  <td className="p-2">
                    <span
                      className={`inline-block px-4 py-2 rounded-lg text-xs font-semibold ${getStatusClass(
                        pending.is_active
                      )}`}
                    >
                      {pending.is_active}
                    </span>
                  </td>
                  <td className="p-2">
                    <button
                      ref={(el) => (buttonRefs.current[index] = el)}
                      onClick={() => toggleDropdown(index)}
                      className="px-2 py-1 text-[#9EB1C2] bg-white"
                    >
                      <FontAwesomeIcon icon={faEllipsisVertical} />
                    </button>

                    {dropdownIndex === index && (
                      <div
                        ref={dropdownRef} // Attach ref to dropdown menu
                        className="absolute bg-white border border-gray-300 rounded shadow mt-1 z-10"
                      >
                        <button
                          className="block px-4 py-2  hover:bg-gray-100"
                          onClick={() => handleDropdownAction("Approve", index)}
                        >
                          <FontAwesomeIcon icon={faCheck} /> Approve
                        </button>
                        <button
                          className="block px-4 py-2  hover:bg-gray-100"
                          onClick={() => handleDropdownAction("Decline", index)}
                        >
                          <FontAwesomeIcon icon={faXmark} /> Decline
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PendingDetails;
