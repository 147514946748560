import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faSortAmountAsc,
  faEllipsisV,
  faUserCheck,
  faUserSlash,
  faEye,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

const students = [
  {
    user: "Faisal Khan",
    enrolledCourses: ["UI/UX Designing"],
    phone: "+91 - 9895472301",
    country: "Australia",
    payment: "Paid",
    status: "Active",
    courseDetails: [
      "User-centered design",
      "Wireframes & Prototypes",
      "Design Thinking",
    ],
  },
  {
    user: "Priyanshu",
    enrolledCourses: ["UI/UX Designing", "Frontend Development"],
    phone: "+91 - 8710231230",
    country: "Italy",
    payment: "Paid",
    status: "Inactive",
    courseDetails: [
      "HTML/CSS Basics",
      "Responsive Design",
      "Advanced JavaScript",
    ],
  },
  {
    user: "Himanshu",
    enrolledCourses: ["UI/UX Designing", "Backend Development"],
    phone: "+91 - 1234567890",
    country: "USA",
    payment: "Paid",
    status: "Inactive",
    courseDetails: ["Node.js", "API Design", "Database Management"],
  },
  {
    user: "Ajay ",
    enrolledCourses: ["UI/UX Designing"],
    phone: "+91 - 9895472301",
    country: "Australia",
    payment: "Paid",
    status: "Active",
    courseDetails: [
      "User-centered design",
      "Wireframes & Prototypes",
      "Design Thinking",
    ],
  },
  {
    user: "Sandeep",
    enrolledCourses: ["UI/UX Designing", "Frontend Development"],
    phone: "+91 - 8710231230",
    country: "Italy",
    payment: "Paid",
    status: "Pending",
    courseDetails: [
      "HTML/CSS Basics",
      "Responsive Design",
      "Advanced JavaScript",
    ],
  },
  {
    user: "Aman",
    enrolledCourses: ["UI/UX Designing", "Backend Development"],
    phone: "+91 - 1234567890",
    country: "USA",
    payment: "Paid",
    status: "Suspend",
    courseDetails: ["Node.js", "API Design", "Database Management"],
  },
];
const Student = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("studentInfo");
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState();
  const buttonRefs = useRef([]);
  const dropdownRef = useRef(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const toggleCourseDetails = (index) =>
    setExpandedIndex(expandedIndex === index ? null : index);

  const handleDropdownAction = (action, studentIndex) => {
    // Implement action handling
    if (action === "Suspend") {
      console.log("Suspend action for:", students[studentIndex]);
    } else if (action === "View Details") {
      console.log("View details action for:", students[studentIndex]);
    }
    setDropdownIndex(null); // Close dropdown after action
  };

  const toggleDropdown = (index) => {
    if (dropdownIndex === index) {
      setDropdownIndex(null); // Close dropdown if it's already open
    } else {
      setDropdownIndex(index); // Open dropdown for the clicked student
    }
  };

  const handleCloseDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdownIndex(null); // Close dropdown if clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleCloseDropdown);
    return () => {
      document.removeEventListener("mousedown", handleCloseDropdown);
    };
  }, []);



  return (
    <div className="p-4 md:p-6 lg:p-8">
      <div className="flex flex-col md:flex-row justify-between items-center mb-6">
        <div className="flex items-center mb-4 md:mb-0">
          <button className="p-2 border border-gray-300 rounded-xl bg-white">
            <FontAwesomeIcon icon={faFilter} className="text-[#757D8A]" />
          </button>
          <div className="ml-4 p-3 text-[#757D8A] text-sm border border-gray-300 rounded-xl bg-white">
            <button>
              <FontAwesomeIcon
                icon={faSortAmountAsc}
                className="text-[#757D8A]"
              />
              Sort: Graphic Design
            </button>
          </div>
        </div>
        <button
          onClick={openModal}
          className="bg-[#3A8BC3] text-white px-4 py-2 rounded-md"
        >
          + Add Student
        </button>
      </div>

      {/* Table for Students */}
      <div className="overflow-x-auto lg:overflow-x-visible xl:overflow-x-visible">
        <table className="w-full bg-white border border-gray-200 rounded-lg text-[#9EB1C2] relative">
          <thead className="bg-white">
            <tr>
              <th className="py-3 px-4 md:px-6 text-left">User</th>
              <th className="py-3 px-4 md:px-6 text-left">Enrolled Courses</th>
              <th className="py-3 px-4 md:px-6 text-left">Phone</th>
              <th className="py-3 px-4 md:px-6 text-left">Country</th>
              <th className="py-3 px-4 md:px-6 text-left">Status</th>
              <th className="py-3 px-4 md:px-6"></th>
            </tr>
          </thead>
          <tbody>
            {students.map((student, index) => (
              <tr key={index} className="border-b border-gray-200">
                <td className="py-4 px-4 md:px-6">{student.user}</td>
                <td className="py-4 px-4 md:px-6 relative">
                  <div>
                    <p>{student.enrolledCourses[0]}</p>
                    <button
                      className="text-blue-500 mt-1"
                      onClick={() => toggleCourseDetails(index)}
                    >
                      View More
                    </button>
                    {expandedIndex === index && (
                      <div className="absolute left-0 mt-2 bg-white border border-gray-200 p-3 rounded-lg shadow-lg z-10 w-48">
                        {student.courseDetails.map((detail, idx) => (
                          <p key={idx} className="text-gray-700 text-sm">
                            {detail}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </td>
                <td className="py-4 px-4 md:px-6">{student.phone}</td>
                <td className="py-4 px-4 md:px-6">{student.country}</td>
                <td className="py-4 px-4 md:px-6">
                  <span
                    className={`py-1 px-3 rounded-full text-sm ${
                      student.status === "Active"
                        ? "bg-green-100 text-green-700"
                        : student.status === "Inactive"
                        ? "bg-red-100 text-red-700"
                        : student.status === "Pending"
                        ? "bg-yellow-100 text-yellow-700"
                        : student.status === "Suspend"
                        ? "bg-blue-100 text-blue-700"
                        : ""
                    }`}
                  >
                    {student.status}
                  </span>
                </td>   
                <td className="p-2 relative">
                <button
                  ref={(el) => (buttonRefs.current[index] = el)}
                  onClick={() => toggleDropdown(index)}
                  className="p-2 bg-inherit rounded-full"
                >
                  <FontAwesomeIcon icon={faEllipsisV} />
                </button>
                {dropdownIndex === index && (
                  <div
                    ref={dropdownRef}
                    className="absolute z-50 w-40 bg-white border border-gray-300 rounded shadow-lg"
                  >
                    <ul>
                      <li>
                        <button
                          onClick={() => handleDropdownAction("Suspend", index)}
                          className="w-full text-left p-2 hover:bg-gray-100 flex items-center"
                        >
                          <FontAwesomeIcon icon={faUserSlash} className="mr-2" />
                          Suspend
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleDropdownAction("View Details", index)}
                          className="w-full text-left p-2 hover:bg-gray-100 flex items-center"
                        >
                          <FontAwesomeIcon icon={faEye} className="mr-2" />
                          View Details
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => handleDropdownAction("Active", index)}
                          className="w-full text-left p-2 hover:bg-gray-100 flex items-center"
                        >
                          <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
                          Active
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
              </td>
            
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white w-full max-w-4xl p-4 md:p-6 lg:p-8 rounded-lg shadow-lg">
            <div className="flex flex-col md:flex-row justify-between items-center mb-4">
              <h2 className="text-xl md:text-2xl font-bold">Add Student</h2>
              <button onClick={closeModal} className="text-gray-500">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>

            <div>
              <div className="flex flex-col md:flex-row border-b border-gray-200 mb-4">
                <button
                  onClick={() => setActiveTab("studentInfo")}
                  className={`flex-1 py-2 text-center ${
                    activeTab === "studentInfo"
                      ? "border-b-2 border-blue-500"
                      : "border-b-2 border-transparent"
                  }`}
                >
                  Student Information
                </button>
                <button
                  onClick={() => setActiveTab("courses")}
                  className={`flex-1 py-2 text-center ${
                    activeTab === "courses"
                      ? "border-b-2 border-blue-500"
                      : "border-b-2 border-transparent"
                  }`}
                >
                  Courses
                </button>
              </div>

              {activeTab === "studentInfo" && (
                <div className="mt-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                    <div>
                      <label className="block text-gray-700 font-semibold mb-1">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 font-semibold mb-1">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                    <div>
                      <label className="block text-gray-700 font-semibold mb-1">
                        Email
                      </label>
                      <input
                        type="email"
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 font-semibold mb-1">
                        Phone Number
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                    <div>
                      <label className="block text-gray-700 font-semibold mb-1">
                        Date of Birth
                      </label>
                      <input
                        type="date"
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 font-semibold mb-1">
                        Course to Enroll
                      </label>
                      <select className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500">
                        <option>Course 1</option>
                        <option>Course 2</option>
                        <option>Course 3</option>
                      </select>
                    </div>
                  </div>

                  <div className="flex flex-col-reverse md:flex-row justify-end mt-4 gap-2">
                    <button
                      className="bg-gray-400 text-white px-4 py-2 rounded-md"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button className="bg-blue-500 text-white px-4 py-2 rounded-md">
                      Add Student
                    </button>
                  </div>
                </div>
              )}

              {activeTab === "courses" && (
                <div className="mt-4">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                    <div>
                      <label className="block text-gray-700 font-semibold mb-1">
                        Address Line 1
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 font-semibold mb-1">
                        Address Line 2
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                    <div>
                      <label className="block text-gray-700 font-semibold mb-1">
                        State
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                      />
                    </div>
                    <div>
                      <label className="block text-gray-700 font-semibold mb-1">
                        Country
                      </label>
                      <input
                        type="text"
                        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col-reverse md:flex-row justify-end mt-4 gap-2">
                    <button
                      className="bg-gray-400 text-white px-4 py-2 rounded-md"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button className="bg-blue-500 text-white px-4 py-2 rounded-md">
                      Update Address
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Student;
