import React from 'react'

const FeatureRow = ({
    feature,
    isChainEdgeAvailable,
    isFreeResourceAvailable,
    isOtherCourseAvailable,
  }) => {
  return (
    <div className="w-full px-4 md:px-8 lg:px-16 mb-12">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 items-center text-center">
        <p className="font-bold text-base sm:text-lg text-[#525252] text-left">
          {feature}
        </p>
        <p className="text-xl">{isChainEdgeAvailable ? "✔️" : "❌"}</p>
        <p className="text-xl">{isFreeResourceAvailable ? "✔️" : "❌"}</p>
        <p className="text-xl">{isOtherCourseAvailable ? "✔️" : "❌"}</p>
      </div>
    </div>
  )
}

export default FeatureRow