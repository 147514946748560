import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSortAmountAsc } from '@fortawesome/free-solid-svg-icons';
import { DeleteIcon, EditIcon } from '../../views/Icons';
import axios from 'axios';

const CourseList = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const base_url = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchCourses = async () => {
      try{
        const response = await axios.get(`${base_url}/get-courses`, {
          headers: {
            accept:"*/*",
            token: token,
          }
        });
        setCourses(response.data.responseData);
      } catch (err) {
        setError(err.messgae);
      } finally {
        setLoading(false);
      }
    }; 
    fetchCourses();
  }, []);

  if (loading) {
    return <div>Loading...</div>; 
  }

  if (error) {
    return <div>Error: {error}</div>; 
  }
  return (
    <div className="p-4 md:p-6 bg-[#EEF5FF] min-h-screen">
      {/* Search Bar and Add Course Button */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
        {/* Search Bar */}
        <div className="relative w-full md:w-1/2 lg:w-1/3">
          <i className="fas fa-search absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"></i>
          <input
            type="text"
            placeholder="Search by Name"
            className="pl-10 p-2 w-full border border-gray-300 rounded-xl text-center"
          />
        </div>
      </div>

      {/* Filter, Sort, and Date Selection */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
        <p className="bg-white border border-gray-200 py-2 px-4 rounded-lg text-[#6B6B6B] text-xl font-bold">
          Total Courses: 15
        </p>
        <div className="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-4">
          <button className="bg-white border border-gray-200 px-4 py-2 rounded-md flex items-center space-x-2">
            <FontAwesomeIcon icon={faFilter} className="text-[#757D8A]" />
          </button>
          <button className="bg-white border border-gray-200 px-4 text-sm text-[#757D8A] py-2 rounded-md flex items-center space-x-2">
            <FontAwesomeIcon icon={faSortAmountAsc} className="text-[#757D8A]" />
            <span>Sort: Chronological</span>
          </button>
          <input
            type="month"
            className="bg-white border border-gray-200 text-[#757D8A] px-4 py-2 rounded-md"
            defaultValue="2021-08"
          />
        </div>
      </div>

      {/* Course Table */}
      <div className="overflow-x-auto lg:overflow-x-visible xl:overflow-x-visible">
        <table className="min-w-full bg-white rounded-lg shadow-md">
          <thead>
            <tr className="bg-white">
              <th className="text-left py-3 px-2 md:px-4 font-semibold text-[#9EB1C2]">Course Name</th>
              <th className="text-left py-3 px-2 md:px-4 font-semibold text-[#9EB1C2]">Category</th>
              <th className="text-left py-3 px-2 md:px-4 font-semibold text-[#9EB1C2]">Instructor</th>
              <th className="text-left py-3 px-2 md:px-4 font-semibold text-[#9EB1C2]">Total Lessons</th>
              <th className="text-left py-3 px-2 md:px-4 font-semibold text-[#9EB1C2]">Enrolled Students</th>
              <th className="text-left py-3 px-2 md:px-4 font-semibold text-[#9EB1C2]">Status</th>
              <th className="text-left py-3 px-2 md:px-4 font-semibold text-[#9EB1C2]">Price</th>
              <th className="text-left py-3 px-2 md:px-4 font-semibold text-[#9EB1C2]">Actions</th>
            </tr>
          </thead>
          <tbody>
            {courses.map((course, index) => (
              <tr key={index} className="border-b hover:bg-gray-50">
                <td className="py-3 px-2 md:px-4 text-[#9EB1C2]">{course.title}</td>
                <td className="py-3 px-2 md:px-4 text-[#9EB1C2]">{course.category}</td>
                <td className="py-3 px-2 md:px-4 text-[#9EB1C2]">{course.createdByName}</td>
                <td className="py-3 px-2 md:px-4 text-[#9EB1C2]">{course.lessons.length}</td>
                <td className="py-3 px-2 md:px-4 text-[#9EB1C2]">{course.enrolledStudents.length}</td>
                <td className="py-3 px-2 md:px-4">
                  <span
                    className={`px-3 py-1 rounded-full text-sm font-medium ${
                      course.isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                    }`}
                  >
                    {course.isActive ? 'Active' : 'Inactive'}
                  </span>
                </td>
                <td className="py-3 px-2 md:px-4 text-gray-700">
                  {course.price}
                </td>
                <td className="py-3 px-2 md:px-4">
                  <div className="flex space-x-2">
                    <button>
                 <EditIcon/>

                    </button>
                    <button>
                   <DeleteIcon/>  
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CourseList;
