// Resolved.js
const Resolved = () => {
    return (
        <div>
        <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <div className="relative flex flex-col sm:flex-row items-start sm:items-center">
            <label
              htmlFor="dateRange"
              className="block text-sm font-medium text-gray-700 mb-2 sm:mb-0"
            >
              Select Date Range
            </label>
            <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
              <input
                type="date"
                name="startDate"
                id="startDate"
                className="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm w-full sm:w-auto"
              />
              <input
                type="date"
                name="endDate"
                id="endDate"
                className="border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm w-full sm:w-auto sm:ml-4"
              />
            </div>
          </div>
          <button
            type="button"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-full sm:w-auto"
          >
            Apply
          </button>
        </div>

        <div className="mt-6 border border-blue-300 p-8 sm:p-16 text-center rounded">
          <p>No Resolved Doubts</p>
        </div>
      </div>
    );
  };
  
  export default Resolved;
  