import React from 'react'

const Hero = () => {
  return (
    <section className=' hero-section w-full pt-5 flex xl:flex-row flex-col-reverse justify-center items-center rounded-xl'>
        <div className=' w-full xl:w-1/2 text-white px-10 pt-16 xl:pt-40 pb-20 flex flex-col gap-10'>
            <h1 className=' text-5xl font-semibold text-wrap'>
            Blockchain Anchors Trust, AI Unleashes Potential.
            </h1>

            <span className=' text-3xl font-medium text-wrap'>
            Blockchain and AI are the twin pillars of the digital revolution, together forging a future where trust is decentralized, intelligence is augmented, and innovation knows no bounds.
            </span>

            <button className=' text-[#0B5397] bg-white text-lg font-semibold px-6  py-2 rounded-full w-fit'>Contact us</button>
        </div>

        <img src='/home-banner/herosection.png' className=' aspect-auto w-[500px] h-[350px] md:w-[650px] md:h-[400px] self-center xl:self-end translate-x-0 xl:translate-x-5' alt='hero' />
    </section>
  )
}

export default Hero