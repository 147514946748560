import React from 'react'
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend } from 'recharts';

const data = [
  { name: 'Option A 2.3k', value: 400 },
  { name: 'Option B 19.5k', value: 300 },
  { name: 'Option C 8.7k', value: 300 },
  { name: 'Option D 12.4k', value: 200 },
];

const COLORS = ['#344BFD', '#F4A79D', '#F68D2B', '#FFD200'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Donut = () => {
  return (
    <div style={{ width: '100%', height: 300 }}>
      <ResponsiveContainer>
    <PieChart width={350} height={300}>
      <Pie
        data={data}
        
        labelLine={false}
        outerRadius={100}
        innerRadius={70}
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Legend />
    </PieChart>
    </ResponsiveContainer>
      </div>
  )
}

export default Donut