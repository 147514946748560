import React, {useState, useEffect} from 'react';

const Calendar = () => {

    const [view, setView] = useState("day");
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      
  const [selectedWeek, setSelectedWeek] = useState(0); // State for selected week
  const [weeksInYear, setWeeksInYear] = useState([]);
  useEffect(() => {
    const generatedWeeks = generateWeeksInYear(2024); // Adjust year if necessary
    setWeeksInYear(generatedWeeks);
  }, []);

  const handleWeekChange = (direction) => {
    if (direction === "prev") {
      setSelectedWeek((prev) =>
        prev === 0 ? weeksInYear.length - 1 : prev - 1
      );
    } else {
      setSelectedWeek((prev) =>
        prev === weeksInYear.length - 1 ? 0 : prev + 1
      );
    }
  };

  // Dummy data for number of days in each month (can be dynamic)
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
 

  // Calculate the weeks and days for the selected month
  const generateWeeks = (month) => {
    const totalDays = daysInMonth[month];
    const weeks = [];
    let day = 1;

    for (let i = 0; i < 5; i++) {
      const week = [];
      for (let j = 0; j < 7; j++) {
        if (day <= totalDays) {
          week.push(day);
          day++;
        } else {
          week.push("");
        }
      }
      weeks.push(week);
    }
    return weeks;
  };

  const weeks = generateWeeks(selectedMonth);

      const generateWeeksInYear = (year) => {
        const weeks = [];
        const startDate = new Date(year, 0, 1); // Start from January 1st
        let currentWeek = [];
        let currentDate = new Date(startDate);
    
        while (currentDate.getFullYear() === year) {
          currentWeek.push(
            `${currentDate.getDate()} ${currentDate.toLocaleString("default", {
              month: "short",
            })}`
          );
    
          // Move to next day
          currentDate.setDate(currentDate.getDate() + 1);
    
          // If we've reached the end of the week (Sunday), start a new week
          if (currentDate.getDay() === 1 || currentWeek.length === 7) {
            weeks.push(currentWeek);
            currentWeek = [];
          }
        }
    
        return weeks;
      };

    const handleMonthChange = (direction) => {
        if (direction === "prev") {
          setSelectedMonth((prev) => (prev === 0 ? 11 : prev - 1));
        } else {
          setSelectedMonth((prev) => (prev === 11 ? 0 : prev + 1));
        }
      };

      const renderDayView = () => {
        // Insert "Hours" heading and adjust the hours array for spacing
        const hours = Array.from(
          { length: 24 },
          (_, i) => `${i % 12 || 12} ${i < 12 ? "AM" : "PM"}`
        );
    
        return (
          <div className="day-view">
            {/* Header row for the time slots */}
            <div className="header-row">
              <div className="time-label"></div>
              {Array.from({ length: 7 }).map((_, idx) => (
                <div key={idx} className="day-column"></div>
              ))}
            </div>
    
            {/* Time slots for each hour and day */}
            <div className="time-slots">
              {hours.map((hour, hourIdx) => (
                <div key={hourIdx} className="hour-row">
                  <div className="time-label">{hour}</div>
                  {Array.from({ length: 7 }).map((_, dayIdx) => (
                    <div key={dayIdx} className="day-slot"></div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        );
      };

      const renderWeekView = () => {
        const hours = Array.from(
          { length: 24 },
          (_, i) => `${i % 12 || 12} ${i < 12 ? "AM" : "PM"}`
        );
        const selectedWeekDates = weeksInYear[selectedWeek]; // Array of the selected week's dates
    
        return (
          <div className="week-view">
            {/* Header row for the days of the selected week */}
            <div className="week-header">
              <div className="time-label"></div>
              {daysOfWeek.map((day, idx) => (
                <div key={idx} className="day-header">
                  <div className="day-name">{day}</div>
                  <div className="date">{selectedWeekDates[idx]}</div>
                </div>
              ))}
            </div>
    
            {/* Time slots for each hour and day */}
            <div className="time-slots">
              {hours.map((hour, hourIdx) => (
                <div key={hourIdx} className="hour-row">
                  <div className="time-label">{hour}</div>
                  {daysOfWeek.map((_, dayIdx) => (
                    <div key={dayIdx} className="week-day-slot"></div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        );
      };

      const renderMonthView = () => (
        <div className="month-view">
          {/* Day Headers */}
          <div className="month-header">
            {daysOfWeek.map((day, idx) => (
              <div key={idx} className="month-day-header">
                {day}
              </div>
            ))}
          </div>
    
          {/* Calendar Dates for the selected month */}
          <div className="month-dates">
            {weeks.map((week, weekIdx) => (
              <div key={weekIdx} className="week-row">
                {week.map((day, dayIdx) => (
                  <div
                    key={dayIdx}
                    className={`month-day-cell ${day ? "active-day" : ""}`}
                    onClick={() => day && handleDateClick(day)}
                  >
                    {day}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      );


      const handleDateClick = (date) => {
        setSelectedDate(date);
      };
      
    return(
<>
            <div className="calendar-container mx-auto max-w-full md:max-w-5xl bg-gray-100 rounded-lg shadow-md p-5">
      {/* Header section with view toggle buttons */}
      <div className="calendar-header relative">
        <div
          className="header-background bg-cover bg-center bg-no-repeat p-6 md:p-10 rounded-lg relative text-white flex flex-col md:flex-row items-center justify-between"
          style={{
            backgroundImage: 'url("/images/calendar.png")',
            height: "150px", // Adjust height for smaller screens
            width: "100%"
          }}
        >
          {/* Calendar icon and Heading */}
          <div className="calendar-header relative">
  {/* Flex container for image and heading */}
  <div className="flex items-center space-x-2 md:space-x-4">
    <img
      src="/images/cal-sm.png"
      alt="Calendar Icon"
      className="w-6 h-6 md:w-10 md:h-10"
    />
    <h1 className="text-lg md:text-2xl font-bold">Mentor Calendar</h1>
  </div>

  {/* Tabs for Day, Week, Month views */}
  <div className="view-buttons flex space-x-2 mt-2 md:mt-0 md:ml-auto">
    <button
      onClick={() => setView("day")}
      className={`view-button ${view === "day" ? "active" : ""} px-4 py-2 rounded-lg border`}
    >
      Day
    </button>
    <button
      onClick={() => setView("week")}
      className={`view-button ${view === "week" ? "active" : ""} px-4 py-2 rounded-lg border`}
    >
      Week
    </button>
    <button
      onClick={() => setView("month")}
      className={`view-button ${view === "month" ? "active" : ""} px-4 py-2 rounded-lg border`}
    >
      Month
    </button>
  </div>
</div>
          {/* Selected Date/Month with Arrows */}
          {view === "month" && (
            <div className="selected-month absolute bottom-2 left-2 md:bottom-5 md:left-5 flex items-center">
              <button onClick={() => handleMonthChange("prev")} className="arrow-btn">
                &lt;
              </button>
              <span className="mx-1 md:mx-2">{months[selectedMonth]}</span>
              <button onClick={() => handleMonthChange("next")} className="arrow-btn">
                &gt;
              </button>
            </div>
          )}
          {view === "week" && (
            <div className="selected-week absolute bottom-2 left-2 md:bottom-5 md:left-5 flex items-center">
              <button onClick={() => handleWeekChange("prev")} className="arrow-btn">
                &lt;
              </button>
              <span className="mx-1 md:mx-2">{`${weeksInYear[selectedWeek][0]} to ${weeksInYear[selectedWeek][6]}`}</span>
              <button onClick={() => handleWeekChange("next")} className="arrow-btn">
                &gt;
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Calendar view depending on state */}
      <div className="calendar-view overflow-auto">
        {view === "day" && renderDayView()}
        {view === "week" && renderWeekView()}
        {view === "month" && renderMonthView()}
      </div>
    </div>

            {/* CSS styles */}
            <style jsx>{`
              .calendar-container {
                max-width: 100%
                margin: 0 auto;
                background-color: #f9f9f9;
                border-radius: 8px;
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
                padding: 20px;
                  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Prevent vertical overflow if unnecessary */
              }

              .calendar-header {
                display: flex;
                flex-direction: column;
                align-items: center; /* Center aligns the heading and view buttons */
                position: relative;
                margin-bottom: 20px;
              }

              .header-background {
                background-size: cover; /* Ensures the image covers the whole area */
                background-position: center;
                background-repeat: no-repeat; /* Prevents image repetition */
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: center; /* Center-aligns the content vertically */
              }

              h1 {
                margin-left: 60px; /* Adjusted for better alignment with calendar icon */
                font-size: 2rem;
                font-weight: bold;
              }

              .view-buttons {
                display: flex;
                gap: 10px;
              }

              .view-button {
                padding: 10px 20px;
               font-size: 0.875rem;
              }

              .view-button.active {
                background-color: #007bff;
                color: white;
              }

              .selected-date {
                position: absolute;
                bottom: 20px;
                left: 20px;
                background-color: rgba(0, 0, 0, 0.5);
                padding: 5px 10px;
                border-radius: 4px;
                font-size: 0.9rem;
                color: white; /* Ensure text is visible */
              }

              .calendar-view {
                background-color: white;
                border-radius: 8px;
                overflow: hidden;
              }

              .day-view, .week-view, .month-view {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-gap: 10px;
      }
         .selected-month,
      .selected-week {
        font-size: 0.875rem; // smaller text for mobile
      }
        .day-view, .week-view {
  display: flex;
  flex-direction: column;
  min-width: 800px; /* Ensure horizontal scrolling when content exceeds screen width */
  width: 100%; /* Let the container take the full width */
  height: 100vh; /* Full height */
}
  .header-row, .hour-row {
  display: flex;
  flex-shrink: 0; /* Prevent shrinking of rows */
}
  .day-column, .week-day-slot {
  width: 150px; /* Set a fixed width for each day column */
  min-width: 150px; /* Ensure the column width stays consistent */
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
  .time-label {
  width: 100px; /* Fixed width for time labels */
  flex-shrink: 0; /* Prevent shrinking of time labels */
  text-align: center;
  background-color: #f5f5f5;
  border-right: 1px solid #ddd;
}

.hour-row .time-label {
  width: 100px; /* Ensure time labels maintain consistent width */
  border-right: 1px solid #ddd;
  text-align: center;
}
  /* General Month View Layout */
.month-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-x: auto; /* Enable horizontal scrolling if necessary */
}

/* Grid Layout for Month View (7 columns by default) */
.month-dates {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* 7 equal columns for desktop */
  grid-auto-rows: 100px; /* Set height for each day cell */
  background-color: white;
}

/* Responsive Layout for Small Screens */
@media (max-width: 640px) {
  .month-dates {
    grid-template-columns: repeat(4, 1fr); /* Reduce to 4 columns on small screens */
    grid-auto-rows: 80px; /* Adjust row height */
  }

  .month-day-header,
  .month-day-cell {
    font-size: 0.875rem; /* Reduce font size on small screens */
    padding: 5px; /* Reduce padding */
  }

  .month-day-cell {
    height: auto; /* Ensure cells adjust their height as needed */
  }

  /* Adjust the header background to fit smaller screens */
  .header-background {
    height: 120px; /* Adjust height for small screens */
    padding: 10px; /* Reduce padding */
  }

  h1 {
    font-size: 1.5rem; /* Reduce heading size */
  }

  /* Adjust the view buttons */
  .view-buttons {
    gap: 5px; /* Reduce the gap between buttons */
  }

  .view-button {
    padding: 5px 10px; /* Smaller buttons on mobile */
    font-size: 0.75rem; /* Reduce font size */
  }

  /* Adjust the month selector */
  .selected-month {
    font-size: 1rem; /* Reduce font size for month display */
  }
}


              /* Time Column (left) */
              body,
              html {
                margin: 0;
                padding: 0;
                height: 100%;
                width: 100%;
              }

              .day-view {
                display: flex;
                flex-direction: column;
                width: 100vw;
                height: 100vh;
                overflow-x: auto; /* Allow horizontal scrolling */
                border: 1px solid #ddd;
              }

              .header-row {
                display: flex;
                background-color: #f5f5f5;
                border-bottom: 1px solid #ddd;
              }

              .time-label {
                width: 100px;
                text-align: center;
                font-weight: bold;
                border-right: 1px solid #ddd;
                background-color: #f5f5f5;
                flex-shrink: 0; /* Prevent shrinking of time column */
              }

              .day-column {
                width: 150px; /* Fixed width for each day column */
                border-left: 1px solid #ddd;
              }

              .time-slots {
                display: flex;
                flex-direction: column;
              }

              .hour-row {
                display: flex;
              }

              .hour-row .time-label {
                width: 100px; /* Match the width of the time column */
                text-align: center;
                border-right: 1px solid #ddd;
              }

              .day-slot {
                border-left: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                width: 150px; /* Match the width of the header columns */
                height: calc(100vh / 24);
              }
              /* Week View */
              .week-view {
                display: flex;
                flex-direction: column;
                width: 100vw;
                height: 100vh;
                overflow-x: auto; /* Allow horizontal scrolling */
                border: 1px solid #ddd;
              }

              .week-header {
                display: flex;
                background-color: #f5f5f5;
                border-bottom: 1px solid #ddd;
              }

              .time-label {
                width: 100px;
                text-align: center;
                font-weight: bold;
                border-right: 1px solid #ddd;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #f5f5f5;
                flex-shrink: 0; /* Prevent shrinking of time column */
              }

              .week-day-slot {
                border-left: 1px solid #ddd;
                border-bottom: 1px solid #ddd;
                height: calc(100vh / 24);
              }

              .hour-row {
                display: flex;
              }

              .week-header .day-header {
                width: 150px; /* Fixed width for each day */
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-right: 1px solid #ddd;
              }

              .day-name {
                font-weight: bold;
              }

              .date {
                font-size: 12px;
                color: gray;
              }

              .week-day-slot {
                width: 150px; /* Match the width of the header */
                height: calc(100vh / 24);
                border-right: 1px solid #ddd;
              }

              .hour-row .time-label {
                background-color: #f5f5f5;
                border-right: 1px solid #ddd;
                flex-shrink: 0;
              }
              /* Selected week display */
              .selected-week {
                font-size: 1rem;
                font-weight: bold;
              }

              .arrow-btn {
                background-color: transparent;
                color: white;
                border: none;
                font-size: 1.2rem;
                cursor: pointer;
              }

              /* Month View */
              .month-view {
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: 1200px;
                margin: 0 auto;
                background-color: #f9f9f9;
                border-radius: 8px;
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
                padding: 20px;
              }

              .month-selector {
                display: grid;
                grid-template-columns: repeat(
                  12,
                  1fr
                ); /* 12 equal columns for months */
                margin-bottom: 20px;
              }

              .month-item {
                padding: 10px;
                text-align: center;
                cursor: pointer;
                transition: background-color 0.3s;
                border: 1px solid #ddd;
                background-color: white;
              }

              .active-month {
                background-color: #007bff;
                color: white;
                font-weight: bold;
              }

              .month-item:hover {
                background-color: #007bff;
                color: white;
              }

              .month-header {
                display: grid;
                grid-template-columns: repeat(7, 1fr); /* 7 equal columns */
                border-bottom: 2px solid #ddd;
              }

              .month-day-header {
                text-align: center;
                background-color: #e0e0e0;
                padding: 10px;
                font-weight: bold;
                font-size: 1rem;
                border: 1px solid #ddd;
              }

              .month-dates {
                display: grid;
                grid-template-columns: repeat(
                  7,
                  1fr
                ); /* Ensure 7 columns for days */
                grid-auto-rows: 100px; /* Adjust the height of each cell */
                background-color: white;
              }

              .week-row {
                display: contents; /* Ensures it spans full width, keeping grid intact */
              }

              .month-day-cell {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                border: 1px solid #ddd;
                font-size: 1rem;
                background-color: white;
                cursor: pointer;
                transition: background-color 0.3s;
              }

              .active-day {
                color: black;
                background-color: white;
              }

              .month-day-cell:hover {
                background-color: #007bff;
                color: white;
              }

              .month-day-cell:empty {
                cursor: default; /* Empty cells are not clickable */
              }
              .selected-month {
                font-size: 1.25rem;
                font-weight: bold;
              }

              .arrow-btn {
                padding: 0.5rem;
                border-radius: 50%;
                cursor: pointer;
                font-weight: bold;
                transition: background-color 0.3s;
              }

              .arrow-btn:hover {
                background-color: rgba(255, 255, 255, 0.2);
              }
            `}</style>
          </>
    )
}
export default Calendar;