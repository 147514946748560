import React, { useState } from "react";
import {
  LessonIcon,
  EditIcon,
  DeleteIcon,
  FileIcon,
  DownArrowIcon,
} from "../../views/Icons";
import { Link } from "react-router-dom";

const videoData = [
  { id: 1, thumbnail: "/images/course-category.png", title: "Video 1" },
  { id: 2, thumbnail: "/images/course-category.png", title: "Video 2" },
  { id: 3, thumbnail: "/images/course-category.png", title: "Video 3" },
];

const studyMaterials = [
  { id: 1, icon: <FileIcon />, title: "Material 1" },
  { id: 2, icon: <FileIcon />, title: "Material 2" },
  { id: 3, icon: <FileIcon />, title: "Material 3" },
  { id: 4, icon: <FileIcon />, title: "Material 4" },
];

const doubts = [
  {
    id: 1,
    name: "Atishek Singh",
    message:
      "Subtitles are not matching with the words. It's hard to understand what he is trying to say.",
    time: "2 Days ago",
    profileImage: "/images/profile.png",
  },
  {
    id: 2,
    name: "Zaman Akbar Zaidi",
    message:
      "Subtitles are not matching with the words. It's hard to understand what he is trying to say.",
    time: "2 Days ago",
    profileImage: "/images/zaman.png",
  },
];

const EditComponent = () => {
  const [expandedSection, setExpandedSection] = useState(null);
  const [editingDoubt, setEditingDoubt] = useState(null);
  const [replyText, setReplyText] = useState("");

  const toggleSection = (index) => {
    setExpandedSection(expandedSection === index ? null : index);
  };

  const handleReply = (doubtId) => {
    setEditingDoubt(doubtId);
    setReplyText("");
  };

  return (
    <div className="max-w-full md:max-w-4xl mx-auto bg-white shadow-md p-4 sm:p-6 rounded-lg">
    <h1 className="text-center text-xl sm:text-2xl font-semibold mb-4">
      Introduction to C++
    </h1>

    {["Section 1", "Section 2"].map((section, index) => (
      <div key={index} className="mb-4 sm:mb-8">
        <button
          onClick={() => toggleSection(index)}
          className="flex items-center w-full bg-[#3A8BC3] text-white text-left py-2 px-4 rounded-t gap-4"
        >
          <DownArrowIcon className="mr-2 h-5 w-5 sm:h-6 sm:w-6" />
          <span>{section}</span>
        </button>

        <div className="border rounded-b p-3 sm:p-4">
          {expandedSection === index ? (
            <>
              <div className="mb-4">
                <h2 className="font-semibold mb-2 text-[#61677F] text-sm sm:text-base">Videos</h2>
                <div className="flex flex-col sm:flex-row justify-between items-start">
                  <div className="flex flex-wrap gap-3">
                    {videoData.map((video) => (
                      <div key={video.id} className="relative">
                        <img
                          src={video.thumbnail}
                          alt={video.title}
                          className="w-28 h-20 sm:w-36 sm:h-24 rounded shadow"
                        />
                        <div className="flex gap-1 mt-1">
                          <button>
                            <EditIcon />
                          </button>
                          <button>
                            <DeleteIcon />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="mt-3 sm:mt-0">
                    <button className="bg-[#3A8BC3] text-white px-3 py-1 rounded text-sm sm:text-base">
                      Edit Quiz
                    </button>
                  </div>
                </div>
              </div>

              {/* Study Material Section */}
              <div className="mb-4">
                <h2 className="font-semibold mb-2 text-[#61677F] text-sm sm:text-base">
                  Study Material
                </h2>
                <div className="flex flex-wrap gap-3">
                  {studyMaterials.map((material) => (
                    <div key={material.id} className="relative">
                      <div className="w-16 h-24 sm:w-20 sm:h-28 flex items-center justify-center bg-gray-100 rounded shadow">
                        {material.icon}
                      </div>
                      <div className="flex gap-1 mt-1">
                        <button>
                          <EditIcon />
                        </button>
                        <button>
                          <DeleteIcon />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <div className="mb-4">
              <h2 className="font-semibold mb-2 text-sm sm:text-base">Videos</h2>
              <div className="flex flex-wrap gap-3">
                {videoData.map((video) => (
                  <div key={video.id} className="relative">
                    <img
                      src={video.thumbnail}
                      alt={video.title}
                      className="w-28 h-20 sm:w-36 sm:h-24 rounded shadow"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    ))}

    <div className="flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-4 mt-6">
      <Link to="/instructor/courses">
        <button className="bg-gray-100 text-gray-600 px-3 py-2 rounded-md text-sm sm:text-base">
          Cancel
        </button>
      </Link>
      <button className="bg-[#3A8BC3] text-white px-3 py-2 rounded-md text-sm sm:text-base hover:bg-blue-600">
        Save Information
      </button>
    </div>
  </div>
  );
};

export default EditComponent;
