import React, { useState, useEffect } from "react";
import { Bar, Line } from "react-chartjs-2";
import "chart.js/auto";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { InstructorPersonalDetailIcon, InstructorOverviewIcon, InstructorCourseIcon, InstructorReviewIcon } from "../Icon";

const InstructorDetail = () => {
  const { id } = useParams();

  const [activeTab, setActiveTab] = useState("Personal Information");
  const [coursesData, setCoursesData] = useState(courses);
  const [reviewsData, setReviewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({});
  const location = useLocation();
  const base_url = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${base_url}/user-detail?id=${id}`, {
          headers: {
            token: token,
          },
        });
        setUserData(response.data.responseData);
      } catch (error) {
        console.error("Error fetching user details:", error);
        setError("Failed to load user details.");
      } finally {
        setLoading(false);
      }
    };
    fetchUserDetails();
  }, [base_url, token]);

  useEffect(() => {
    const fetchReviews = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${base_url}/instructor-course-review?id=${id}`,
          {
            headers: {
              token: token,
            },
          }
        );
        if (Array.isArray(response.data.responseData)) {
          setReviewsData(response.data.responseData);
        } else {
          console.error(
            "Expected an array, but got:",
            response.data.responseData
          );
          setReviewsData([]); 
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
        setError("Failed to load reviews."); 
        setReviewsData([]); 
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  useEffect(() => {
    const hash = location.hash;
    if (hash === "#courses") {
      setActiveTab("Courses");
    }
  }, [location]);

  const handleDeleteCourse = (index) => {
    const updatedCourses = coursesData.filter(
      (_, courseIndex) => courseIndex !== index
    );
    setCoursesData(updatedCourses);
  };

  const handleDeleteReview = (index) => {
    const updatedReviews = reviewsData.filter(
      (_, reviewIndex) => reviewIndex !== index
    );
    setReviewsData(updatedReviews);
  };
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span key={i} className={i <= rating ? "text-yellow-500" : "text-gray-300"}>
          ★
        </span>
      );
    }
    return <div className="flex">{stars}</div>;
  };

  return (
    <div className="p-5 bg-white">
    {/* Tabs Navigation */}
    <nav className="flex flex-wrap mb-5 text-[#9EB1C2] border-b border-gray-300">
      {["Personal Information", "Overview", "Courses", "Review"].map((tab) => {
        let icon;
  
        switch (tab) {
          case "Personal Information":
            icon = <InstructorPersonalDetailIcon />;
            break;
          case "Overview":
            icon = <InstructorOverviewIcon />;
            break;
          case "Courses":
            icon = <InstructorCourseIcon />;
            break;
          case "Review":
            icon = <InstructorReviewIcon />;
            break;
          default:
            icon = null;
        }
  
        return (
          <button
            key={tab}
            className={`py-2 px-4 cursor-pointer border-b-2 ${
              activeTab === tab
                ? "border-blue-500 font-bold text-blue-500"
                : "border-transparent text-gray-500"
            } flex items-center mb-2 mr-2`}
            onClick={() => setActiveTab(tab)}
          >
            <div>{icon}</div>
            <span className="hidden sm:block">{tab}</span>
          </button>
        );
      })}
    </nav>
  
    {/* Tab Content */}
    {activeTab === "Personal Information" && (
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-4 text-[#9EB1C2]">
          Personal Information
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 text-[#9EB1C2] mb-10">
          <div className="font-semibold">Title</div>
          <div>Mr</div>
          <div className="font-semibold">First Name</div>
          <div>{userData?.fullName?.split(" ")[0] || "N/A"}</div>
  
          <div className="font-semibold">Date of Birth</div>
          <div>{userData.dob || "13 Dec 1998"}</div>
          <div className="font-semibold">Last Name</div>
          <div>{userData?.fullName?.split(" ")[1] || "N/A"}</div>
  
          <div className="font-semibold">Mobile Number</div>
          <div>{userData.mobile || "0123456789"}</div>
          <div className="font-semibold">Email Address</div>
          <div>{userData.email || "N/A"}</div>
        </div>
  
        <h3 className="text-xl font-semibold mb-4 text-[#9EB1C2]">
          Additional Information
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 text-[#9EB1C2]">
          <div className="font-semibold">Joining Date</div>
          <div>
            {userData?.createdAt
              ? moment(userData.createdAt).format("MMMM DD, YYYY")
              : "N/A"}
          </div>
          <div className="font-semibold">Registration Method</div>
          <div>{userData.email ? "Email" : "Mobile" || "N/A"}</div>
  
          <div className="font-semibold">Country</div>
          <div>{userData.country || "India"}</div>
          <div className="font-semibold">City</div>
          <div>{userData.city || "Mumbai"}</div>
        </div>
      </section>
    )}
  
    {activeTab === "Overview" && (
      <section className="mb-10">
        <h2 className="text-2xl font-semibold mb-4 text-[#9EB1C2]">
          Overview
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 text-[#9EB1C2]">
          <div className="w-full">
            <h3 className="text-xl font-semibold mb-2">
              Customer Satisfaction
            </h3>
            <div className="w-full">
              <Line
                data={{
                  labels: [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ],
                  datasets: [
                    {
                      label: "Last Month",
                      data: [3200, 3000, 2500, 2000, 1500, 1800, 2000],
                      borderColor: "#36A2EB",
                      fill: false,
                    },
                    {
                      label: "This Month",
                      data: [3500, 3700, 4000, 4200, 3800, 4000, 4500],
                      borderColor: "#4BC0C0",
                      fill: false,
                    },
                  ],
                }}
              />
            </div>
          </div>
          <div className="w-full">
            <h3 className="text-xl font-semibold mb-2">Total Revenue</h3>
            <div className="w-full">
              <Bar
                data={{
                  labels: [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ],
                  datasets: [
                    {
                      label: "Online Sales",
                      data: [12000, 15000, 17000, 20000, 16000, 19000, 22000],
                      backgroundColor: "#36A2EB",
                    },
                    {
                      label: "Offline Sales",
                      data: [8000, 10000, 9000, 12000, 14000, 13000, 16000],
                      backgroundColor: "#4BC0C0",
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </section>
    )}
  
    {activeTab === "Courses" && (
      <section id="courses-section" className="mb-10">
        <h2 className="text-2xl font-semibold mb-4 text-[#9EB1C2]">
          Courses
        </h2>
        <div className="overflow-x-auto">
          <table className="w-full min-w-full border-collapse text-[#9EB1C2]">
            <thead>
              <tr>
                <th className="border-b border-gray-300 p-2 text-left">#</th>
                <th className="border-b border-gray-300 p-2 text-left">
                  Course Name
                </th>
                <th className="border-b border-gray-300 p-2 text-left">
                  Status
                </th>
                <th className="border-b border-gray-300 p-2 text-left">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
            {userData.enrolledCourses && userData.enrolledCourses.length > 0 ? (
              userData.enrolledCourses.map((course, index) => (
                <tr key={index}>
                  <td className="p-2 border-b border-gray-200">
                    {index + 1}
                  </td>
                  <td className="p-2 border-b border-gray-200">
                    {course.name}
                  </td>
                  <td className="p-2 border-b border-gray-200">
                    <span
                      className={`px-3 py-1 rounded-full text-sm font-semibold ${getStatusColorClass(
                        course.status
                      )}`}
                    >
                      {course.status}
                    </span>
                  </td>
                  <td className="p-2 border-b border-gray-200">
                    <button
                      className="text-red-600 hover:text-red-800"
                      onClick={() => handleDeleteCourse(index)}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
            <td colSpan="4" className="text-center p-4 text-gray-500">
              No data available
            </td>
          </tr>
        )}
            </tbody>
          </table>
        </div>
      </section>
    )}
  
    {activeTab === "Review" && (
      <>
        {loading ? (
          <div className="flex justify-center items-center h-24">
            <div className="w-10 h-10 border-4 border-blue-400 border-t-transparent border-solid rounded-full animate-spin"></div>
          </div>
        ) : error ? (
          <p className="text-red-600">Error: {error}</p>
        ) : (
          <section className="mb-10 text-[#9EB1C2]">
            <h2 className="text-2xl font-semibold mb-4">Review</h2>
            <div className="overflow-x-auto">
              {reviewsData.length > 0 ? (
                <table className="w-full min-w-full border-collapse">
                  <thead>
                    <tr>
                      <th className="border-b border-gray-300 p-2 text-left">
                        Student
                      </th>
                      <th className="border-b border-gray-300 p-2 text-left">
                        Course Name
                      </th>
                      <th className="border-b border-gray-300 p-2 text-left">
                        Rating
                      </th>
                      <th className="border-b border-gray-300 p-2 text-left">
                        Review
                      </th>
                      <th className="border-b border-gray-300 p-2 text-left">
                        Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {reviewsData.map((review) => (
                      <tr key={review.id}>
                        <td className="border-b border-gray-200 p-2">
                          {review.studentName}
                        </td>
                        <td className="border-b border-gray-200 p-2">
                          {review.courseName}
                        </td>
                        <td className="border-b border-gray-200 p-2">
                          {renderStars(review.rating)}
                        </td>
                        <td className="border-b border-gray-200 p-2">
                          {review.comment}
                        </td>
                        <td className="border-b border-gray-200 p-2">
                          {moment(review.date).format("MMMM DD, YYYY")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No reviews found.</p>
              )}
            </div>
          </section>
        )}
      </>
    )}
  </div>
  
  );
};

const StarRating = ({ rating }) => {
  const filledStars = Math.floor(rating);
  const halfStar = rating % 1 >= 0.5;

  return (
    <div className="flex items-center">
      {[...Array(5)].map((_, index) => (
        <svg
          key={index}
          className={`w-6 h-6 ${
            index < filledStars
              ? "text-yellow-400"
              : index === filledStars && halfStar
              ? "text-yellow-300"
              : "text-gray-300"
          }`}
          fill="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          {index < filledStars ? (
            <path d="M12 .587l3.668 7.568 8.332 1.185-6.055 5.918 1.427 8.302L12 17.563l-7.372 3.876L6.055 15.258 0 9.34l8.332-1.185L12 .587z" />
          ) : index === filledStars && halfStar ? (
            <path d="M12 .587l3.668 7.568 8.332 1.185-6.055 5.918 1.427 8.302L12 17.563l-7.372 3.876L6.055 15.258 0 9.34l8.332-1.185L12 .587z" />
          ) : (
            <path d="M12 .587l3.668 7.568 8.332 1.185-6.055 5.918 1.427 8.302L12 17.563l-7.372 3.876L6.055 15.258 0 9.34l8.332-1.185L12 .587z" />
          )}
        </svg>
      ))}
    </div>
  );
};

// Sample Data for Courses
const courses = [
  { name: "Fundamentals of User Interface", status: "Active" },
  { name: "Fundamentals of User Interface", status: "Inactive" },
  { name: "Fundamentals of User Interface", status: "Pending" },
  { name: "Fundamentals of User Interface", status: "Active" },
];

// Sample Data for Reviews
const reviews = [
  {
    student: "Shawn Michael",
    courseName: "Fundamentals of User Interface",
    rating: 5,
    review: "I will highly recommend this type of instructor.",
  },
  {
    student: "Shawn Michael",
    courseName: "Fundamentals of User Interface",
    rating: 5,
    review: "I will highly recommend this type of instructor.",
  },
  {
    student: "Shawn Michael",
    courseName: "Fundamentals of User Interface",
    rating: 5,
    review: "I will highly recommend this type of instructor.",
  },
];

const getStatusColorClass = (status) => {
  switch (status) {
    case "Active":
      return "bg-green-100 text-green-700";
    case "Inactive":
      return "bg-red-100 text-red-700";
    case "Pending":
      return "bg-orange-100 text-orange-700";
    default:
      return "bg-gray-100 text-gray-700";
  }
};

export default InstructorDetail;
