import React from "react";

const Adminn = ({ children }) => {
  return (
    <div className=" w-full flex flex-col">
      <div className="w-full p-10">{children}</div>
    </div>
  );
};

export default Adminn;
