import React, { useState, useRef, useEffect } from "react";
import '@fortawesome/fontawesome-free/css/all.min.css';

function InstructorProfile() {
  const [activeTab, setActiveTab] = useState("profile");

  return (
    <div className="flex flex-col bg-[#EEF5FF] min-h-screen">
      {/* Banner Section */}
      <div className="relative">
        <img
          src="/images/banner.png"
          alt="Banner"
          className="w-full h-40 md:h-52 object-cover"
        />
        {/* Profile Image */}
        <div className="absolute -bottom-16 left-1/2 transform -translate-x-1/2 w-24 h-24 md:w-32 md:h-32 rounded-full overflow-hidden border-4 border-white">
          <img
            src="/images/admin.png"
            alt="Profile"
            className="w-full h-full object-cover"
          />
        </div>
      </div>
      
      {/* Main Section */}
      <div className="flex flex-col md:flex-row">
        {/* Sidebar */}
        <div className="w-full md:w-80 bg-[#EEF5FF] p-4 md:p-6 mt-10 md:mt-14 border-t md:border-t-0 border-gray-200">
          <ul className="list-none p-0 m-0">
            <li
              className={`p-4 cursor-pointer border border-gray-300 rounded-t-md ${
                activeTab === "profile" ? "bg-[#3A8BC3] text-white" : "bg-white"
              } ${activeTab === "profile" ? "" : "border-b-0"}`}
              onClick={() => setActiveTab("profile")}
            >
              <i className="fa fa-user" aria-hidden="true"></i>
              <span className="ml-4">Profile Information</span>
            </li>
            <li
              className={`p-4 cursor-pointer border border-gray-300 ${
                activeTab === "password" ? "bg-[#3A8BC3] text-white" : "bg-white"
              } ${activeTab === "password" ? "" : "border-b-0"}`}
              onClick={() => setActiveTab("password")}
            >
              <i className="fa fa-unlock-alt" aria-hidden="true"></i>
              <span className="ml-4">Change Password</span>
            </li>
            <li
              className={`p-4 cursor-pointer border border-gray-300 rounded-b-md ${
                activeTab === "notifications" ? "bg-[#3A8BC3] text-white" : "bg-white"
              }`}
              onClick={() => setActiveTab("notifications")}
            >
              <i className="fa fa-bell" aria-hidden="true"></i>
              <span className="ml-4">Notification</span>
            </li>
          </ul>
        </div>

        {/* Content Section */}
        <div className="flex-1 p-4 md:p-6 overflow-y-auto">
          {activeTab === "profile" && <ProfileInfo />}
          {activeTab === "password" && <ChangePassword />}
          {activeTab === "notifications" && <Notifications />}
        </div>
      </div>
    </div>
  );
}

function ProfileInfo() {
  const profileImageInputRef = useRef(null);
  const bannerImageInputRef = useRef(null);

  const handleProfileImageClick = () => {
    if (profileImageInputRef.current) {
      profileImageInputRef.current.click();
    }
  };

  const handleBannerImageClick = () => {
    if (bannerImageInputRef.current) {
      bannerImageInputRef.current.click();
    }
  };

  return (
    <div>
      <div className="pt-8 md:pt-16">
        <h2 className="text-2xl font-bold">Welcome Your name!</h2>
        <form className="mt-6 space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="md:col-span-2 grid grid-cols-1 gap-6">
              {/* First Name */}
              <div className="flex flex-col">
                <label className="mb-1">First Name</label>
                <input
                  type="text"
                  className="p-2 rounded-full border"
                  placeholder="Enter Your First Name"
                />
              </div>

              {/* Last Name */}
              <div className="flex flex-col">
                <label className="mb-1">Last Name</label>
                <input
                  type="text"
                  className="p-2 rounded-full border"
                  placeholder="Enter Your Last Name"
                />
              </div>
            </div>

            {/* Select Profile and Banner Image Section */}
            <div className="flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
              {/* Profile Image Section */}
              <div className="flex flex-col items-center">
                <h3 className="text-lg font-semibold mb-4">Profile Image</h3>
                <button
                  type="button"
                  className="flex items-center justify-center bg-white w-24 h-24 md:w-32 md:h-32 rounded-full hover:bg-[#3A8BC3] hover:text-white transition-colors duration-300"
                  onClick={handleProfileImageClick}
                >
                  <i className="fa fa-pencil text-gray-700 text-2xl" aria-hidden="true"></i>
                </button>
                <input
                  type="file"
                  ref={profileImageInputRef}
                  className="hidden"
                  accept="image/*"
                />
              </div>

              {/* Banner Image Section */}
              <div className="flex flex-col items-center">
                <h3 className="text-lg font-semibold mb-4">Banner Image</h3>
                <button
                  type="button"
                  className="flex items-center justify-center bg-white w-24 h-24 md:w-32 md:h-32 rounded-full hover:bg-[#3A8BC3] hover:text-white transition-colors duration-300"
                  onClick={handleBannerImageClick}
                >
                  <i className="fa fa-pencil text-gray-700 text-2xl" aria-hidden="true"></i>
                </button>
                <input
                  type="file"
                  ref={bannerImageInputRef}
                  className="hidden"
                  accept="image/*"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
            <div className="flex flex-col col-span-2">
              <label className="mb-1">Email</label>
              <input
                type="email"
                className="p-2 rounded-full border"
                placeholder="Enter Your Email"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
            <div className="flex flex-col">
              <label className="mb-1">Facebook</label>
              <input
                type="text"
                className="p-2 rounded-full border"
                placeholder="Enter Your Facebook"
              />
            </div>
            <div className="flex flex-col">
              <label className="mb-1">Twitter</label>
              <input
                type="text"
                className="p-2 rounded-full border"
                placeholder="Enter Your Twitter"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
            <div className="flex flex-col">
              <label className="mb-1">Phone</label>
              <input
                type="text"
                className="p-2 rounded-full border"
                placeholder="Enter Phone Number"
              />
            </div>
            <div className="flex flex-col">
              <label className="mb-1">Gender</label>
              <select
                className="p-2 rounded-full border"
                defaultValue=""
              >
                <option value="" disabled>Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
            <div className="flex flex-col col-span-2">
              <label className="mb-1">Address</label>
              <input
                type="text"
                className="p-2 rounded-full border"
                placeholder="Enter Your Address"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
            <div className="flex flex-col">
              <label className="mb-1">City</label>
              <input
                type="text"
                className="p-2 rounded-full border"
                placeholder="Enter Your City"
              />
            </div>
            <div className="flex flex-col">
              <label className="mb-1">Country</label>
              <input
                type="text"
                className="p-2 rounded-full border"
                placeholder="Enter Your Country"
              />
            </div>
          </div>

          <div className="flex flex-col mt-6">
            <label className="mb-1">Your Bio</label>
            <textarea
              className="p-2 rounded-lg border h-32"
              placeholder="Enter Your Bio..."
            ></textarea>
          </div>

          <button className="bg-[#3A8BC3] text-white py-2 px-6 rounded-md mt-6">
            Edit
          </button>
        </form>
      </div>
    </div>
  );
}

function ChangePassword() {
  return (
    <div>
      <h2 className="text-2xl font-bold pt-8 md:pt-16">Change Password</h2>
      <form className="mt-6 space-y-4">
        <div className="flex flex-col">
          <label className="mb-1">Old Password</label>
          <input
            type="password"
            className="p-2 rounded-full border border-gray-300"
            placeholder="Enter Old Password"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col col-span-1">
            <label className="mb-1">New Password</label>
            <input
              type="password"
              className="p-2 rounded-full border border-gray-300"
              placeholder="Enter New Password"
            />
          </div>
          <div className="flex flex-col col-span-1">
            <label className="mb-1">Confirm Password</label>
            <input
              type="password"
              className="p-2 rounded-full border border-gray-300"
              placeholder="Confirm New Password"
            />
          </div>
        </div>
        <button className="bg-[#3A8BC3] text-white py-2 px-4 rounded-md mt-4">
          Update
        </button>
      </form>
    </div>
  );
}

function Notifications() {

  const [notifications, setNotifications] = useState(() => {
    const saved = localStorage.getItem('notifications');
    return saved ? JSON.parse(saved) : {
      likes: true,
      posts: true,
      bids: true,
      saleBids: true,
      payments: true,
    };
  });

  
  useEffect(() => {
    localStorage.setItem('notifications', JSON.stringify(notifications));
  }, [notifications]);

 
  const handleToggle = (key) => {
    setNotifications((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
    <div className="border-2 p-4 mt-14 bg-white">
      <h2 className="text-2xl font-bold pt-8 md:pt-16">My Activity Settings</h2>
      <p className="text-gray-600">
        Stay up to date with notifications on activity that involves you, including mentions, replies to your bids, new items, sales, and administrative updates.
      </p>
      <div className="mt-6 space-y-4">
        {[
          { key: 'likes', label: 'Like & Follows Notifications' },
          { key: 'posts', label: 'Post, Comments & Replies Notifications' },
          { key: 'bids', label: 'New Bids Notifications' },
          { key: 'saleBids', label: 'Bid on Sale Notifications' },
          { key: 'payments', label: 'Payment Notifications' }
        ].map(({ key, label }) => (
          <div key={key} className="flex items-center justify-between">
            <label className="text-gray-600">{label}</label>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={notifications[key]}
                onChange={() => handleToggle(key)}
                className="sr-only"
              />
              <div
                className={`w-12 h-6 rounded-full transition-colors duration-300 ${
                  notifications[key] ? "bg-blue-600" : "bg-gray-200"
                }`}
              >
                <div
                  className={`w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-300 ${notifications[key] ? 'translate-x-6' : 'translate-x-0'}`}
                ></div>
              </div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default InstructorProfile;
