import React, { useEffect, useState } from "react";
import axios from "axios";
import EventCard from "./eventCard.js";

const EventsSection = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  useEffect(() => {
    const fetchWebinars = async () => {
      try {
        const response = await axios.get(
          "https://api-new.chainedge.in/v0/webinar"
        );

        setUpcomingEvents(response.data.responseData);
      } catch (error) {
        console.log("Error Fetching webinars", error);
      }
    };
    fetchWebinars();
  }, []);

  return (
    <section className="my-12">
      <h2 className="text-3xl font-bold text-center lg:mb-20 mb-8">
        Register for Upcoming Events
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {upcomingEvents.length > 0 ? (
          upcomingEvents.map((event, index) => (
            <EventCard
              key={event._id}
              id={event._id}
              title={event.title}
              date={new Date(event.date).toLocaleString()}
              description={event.description}
              imageUrl="/images/register1.png" // Placeholder image, replace as needed
              tag="Upcoming"
              buttonText="Register Now"
              buttonLink="#"
            />
          ))
        ) : (
          <p className="text-center col-span-3">No upcoming events.</p>
        )}
      </div>
    </section>
  );
};

export default EventsSection;
