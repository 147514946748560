import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faSortAmountAsc,
  faEllipsisV,
  faMagnifyingGlass,
  faEdit,
  faTrash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { LessonIcon, EditIcon, DeleteIcon } from "../../views/Icons";
import axios from "axios";

const Courses = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const buttonRefs = useRef([]);

  const base_url = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");

  const getStatusClass = (status) => {
    return status === "Active"
      ? "bg-green-200 text-green-800"
      : "bg-red-200 text-red-800";
  };

  const toggleDropdown = (index) => {
    if (dropdownIndex === index) {
      setDropdownIndex(null);
    } else {
      setDropdownIndex(index);
      const button = buttonRefs.current[index];
      const rect = button.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left,
      });
    }
  };

  const toggleDropdownSort = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${base_url}/instructor-courses`, {
          headers: {
            accept: "*/*",
            token: token,
          },
        });
        console.log(response.data.responseData);
        setData(response.data.responseData);
      } catch (error) {
        console.error("Error fetching courses:", error);
        setError("Error fetching courses");
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <div className="relative flex flex-col md:flex-row justify-between items-center mb-4">
        <div className="relative w-full md:w-1/3 mb-4 md:mb-0">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#9EB1C2]"
          />
          <input
            type="text"
            placeholder="Search by Name"
            className="pl-10 p-2 w-full border border-gray-300 rounded-xl text-center placeholder-[#9EB1C2] text-[#9EB1C2]"
          />
        </div>

        {/* Add Course Button aligned to the right end of the screen */}
        <div className="flex-grow flex justify-end">
          <Link to="/instructor/courses/add-course">
            <button className="bg-[#3A8BC3] text-white p-2 rounded-lg">
              Add Course
            </button>
          </Link>
        </div>
      </div>

      {/* Filters and Sorting Options */}
      <div className="flex flex-col md:flex-row justify-between items-center mt-8 mb-4">
        <div className="flex justify-end w-full items-center mb-4 md:mb-0">
          <button className="p-2 pr-4 pl-4 border border-gray-300 rounded-xl bg-white">
            <FontAwesomeIcon icon={faFilter} className="text-[#9EB1C2]" />
          </button>
          <div className="ml-4 p-2 pr-4 pl-4 border border-gray-300 rounded-xl bg-white">
            <button onClick={toggleDropdownSort}>
              <FontAwesomeIcon
                icon={faSortAmountAsc}
                className="text-[#9EB1C2]"
              />
              <span className="ml-2 text-sm font-semibold text-[#9EB1C2]">
                Sort: Status
              </span>
            </button>
          </div>

          {isDropdownOpen && (
            <div className="fixed right-0 mt-44 w-48 bg-white border border-gray-300 rounded-xl shadow-lg z-50">
              <ul className="py-2 text-[#9EB1C2]">
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                  Status
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                  Country
                </li>
                <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                  Category
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      <div className="flex mb-4">
        <div className="bg-white p-4 border rounded-md shadow-sm">
          <p>Total Courses</p>
          <h2 className="text-2xl font-bold">
            {Array.isArray(data) ? data.length : 0}
          </h2>
        </div>
      </div>

      <div className="overflow-x-auto lg:overflow-x-visible xl:overflow-x-visible">
        <table className="w-full bg-white rounded shadow">
          <thead>
            <tr>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                Course Name
              </th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                Category
              </th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                Total Lessons
              </th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                Enrolled Students
              </th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                Status
              </th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                Price
              </th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(data) && data.length > 0 ? (
              data.map((course, index) => (
                <tr
                  key={index}
                  className="border-b text-[#9EB1C2] border-gray-300"
                >
                  <td className="p-2">{course.title}</td>
                  <td className="p-2">{course.category}</td>
                  <td className="p-2">
                    {course.lessons?.length > 0 ? course.lessons.length : 0}
                  </td>
                  <td className="p-2">
                    {course.enrolledStudents?.length > 0
                      ? course.enrolledStudents.length
                      : 0}
                  </td>
                  <td className="p-2">
                    <span
                      className={`inline-block px-2 py-1 rounded-full ${getStatusClass(
                        course.isActive
                      )}`}
                    >
                      {course.isActive ? "Active" : "Inactive"}
                    </span>
                  </td>
                  <td className="p-2">{course.price}</td>
                  <td className="p-2">
                    <div className="flex space-x-2">
                      <Link to={`/instructor/courses/add-lesson/${course._id}`}>
                        <button className="p-2 rounded-full hover:bg-gray-100 focus:outline-none">
                          <LessonIcon
                            className="w-5 h-5 text-[#9EB1C2]"
                            title="Add Lesson"
                          />
                        </button>
                      </Link>
                      <Link
                        to={`/instructor/courses/edit-course/${course._id}`}
                      >
                        <button className="p-2 rounded-full hover:bg-gray-100 focus:outline-none">
                          <EditIcon
                            className="w-5 h-5 text-[#9EB1C2]"
                            title="Edit Course"
                          />
                        </button>
                      </Link>
                      <button
                        className="p-2 rounded-full hover:bg-gray-100 focus:outline-none"
                        onClick={() => toggleDropdown(index)}
                        ref={(el) => (buttonRefs.current[index] = el)}
                      >
                        <DeleteIcon
                          className="w-5 h-5 text-[#9EB1C2]"
                          title="Delete Course"
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="p-4 text-center text-gray-500">
                  You haven't created any courses yet
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Courses;
