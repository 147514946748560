import React, { useState, useEffect } from "react";
import Dashboard from '../../component/TA/Dashboard';
import Workplace from '../../component/TA/Workplace';
import Resolved from '../../component/TA/Resolved';
import Pending from '../../component/TA/Pending';
import ReviewPending from '../../component/TA/ReviewPending';
import Incomplete from '../../component/TA/IncompleteTab';
import Record from '../../component/TA/Record';
import Leave from '../../component/TA/Leave';
import Calendar from '../../component/TA/CalendarTab';

import { FaBars, FaChevronDown, FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";


const DashboardAndDoubts = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDoubtsDropdownOpen, setIsDoubtsDropdownOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("dashboard");
  
  const [isTAshipDropdownOpen, setIsTAshipDropdownOpen] = useState(false);

  const handleTAshipDropdownToggle = () => {
    setIsTAshipDropdownOpen(!isTAshipDropdownOpen);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleDoubtsDropdownToggle = () => {
    setIsDoubtsDropdownOpen(!isDoubtsDropdownOpen);
  };
  const handleTabClick = (tab) => {
    console.log("Tab clicked: ", tab);
    setSelectedTab(tab);
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case "dashboard":
        return <Dashboard />;
      case "workplace":
        return <Workplace />;
      case "resolved":
        return <Resolved />;
      case "pending":
        return <Pending />;
      case "reviewPending":
        return <ReviewPending />;
      case "incomplete":
        return <Incomplete />;
      case "record":
        return <Record />;
      case "leave":
        return <Leave />;
      case "calendar":
        return <Calendar />;
      default:
        return <Dashboard />;
    }
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="lg:fixed lg:h-full lg:w-64 w-full bg-[#3A8BC3] text-white p-4 flex flex-col">
        <div className="flex items-center mb-6">
          <Link to="/">
            <img
              src="/home-banner/logoWhite.png"
              alt="Logo"
              className="w-36 h-12 cursor-pointer"
            />
          </Link>
          <FaBars className="ml-auto text-xl cursor-pointer lg:hidden" />
        </div>
        <div className="flex flex-col items-center mt-4 flex-1">
          <img
            src="/images/profile.png"
            alt="Profile"
            className="rounded-full w-16 h-16 mb-4"
          />
          <h2 className="text-xl font-semibold">Atishek Singh</h2>
          <p className="text-sm">singhatishek69@gmail.com</p>
          <nav className="mt-8">
            <Link
              className="block py-2.5 px-4 rounded hover:bg-blue-500"
              onClick={() => handleTabClick("dashboard")}
            >
              Dashboard
            </Link>
            <div
              className="block py-2.5 px-4 rounded hover:bg-blue-500 cursor-pointer flex justify-between items-center"
              onClick={handleDoubtsDropdownToggle}
            >
              <span>Doubts</span>
              {isDoubtsDropdownOpen ? (
                <FaChevronDown className="ml-2" />
              ) : (
                <FaChevronRight className="ml-2" />
              )}
            </div>

            {isDoubtsDropdownOpen && (
              <div className="pl-4 mt-2 space-y-2">
                <div
                  className="block py-1 px-4 rounded hover:bg-blue-500 cursor-pointer"
                  onClick={() => handleTabClick("workplace")}
                >
                  Workplace
                </div>
                <div
                  className="block py-1 px-4 rounded hover:bg-blue-500 cursor-pointer"
                  onClick={() => handleTabClick("resolved")}
                >
                  Resolved
                </div>
                <div
                  className="block py-1 px-4 rounded hover:bg-blue-500 cursor-pointer"
                  onClick={() => handleTabClick("pending")}
                >
                  Pending on User
                </div>
                <div
                  className="block py-1 px-4 rounded hover:bg-blue-500 cursor-pointer"
                  onClick={() => handleTabClick("reviewPending")}
                >
                  Review Pending
                </div>
              </div>
            )}
            <Link
              to="#"
              className="block py-2.5 px-4 rounded hover:bg-blue-500"
              onClick={() => handleTabClick("incomplete")}
            >
              Incomplete Content
            </Link>
            <Link
              to="#"
              className="block py-2.5 px-4 rounded hover:bg-blue-500"
              onClick={() => handleTabClick("calendar")}
            >
              Calendar
            </Link>
            <div
              className="block py-2.5 px-4 rounded hover:bg-blue-500 cursor-pointer flex justify-between items-center"
              onClick={handleTAshipDropdownToggle}
            >
              <span>TAship</span>
              {isTAshipDropdownOpen ? (
                <FaChevronDown className="ml-2" />
              ) : (
                <FaChevronRight className="ml-2" />
              )}
            </div>

            {isTAshipDropdownOpen && (
              <div className="pl-4 mt-2 space-y-2">
                <div
                  className="block py-1 px-4 rounded hover:bg-blue-500 cursor-pointer"
                  onClick={() => handleTabClick("record")}
                >
                  TAship-records
                </div>
                <div
                  className="block py-1 px-4 rounded hover:bg-blue-500 cursor-pointer"
                  onClick={() => handleTabClick("leave")}
                >
                  Leave Requests
                </div>
              </div>
            )}
          </nav>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-6 space-y-6 bg-gray-50 lg:ml-64">
        {/* Top Bar */}
        <div className="flex flex-col sm:flex-row justify-between items-center bg-gray-100 p-4 rounded mb-6 relative">
          <h1 className="text-xl font-bold mb-4 sm:mb-0">Dashboard</h1>
          <div className="relative flex items-center space-x-4">
            <div
              className="flex items-center cursor-pointer"
              onClick={handleDropdownToggle}
            >
              <span className="mr-2 text-sm sm:text-base">Atishek Singh</span>
              <img
                src="/images/profile.png"
                alt="User Avatar"
                className="w-8 h-8 sm:w-10 sm:h-10 rounded-full"
              />
              <FaChevronDown className="ml-2" />
            </div>
            {isDropdownOpen && (
              <div className="absolute top-12 right-0 bg-gray-100 shadow-md rounded w-40 z-10 text-black">
                <Link
                  to="#"
                  className="block px-4 py-2 hover:bg-blue-500 hover:text-white"
                >
                  Profile
                </Link>
                <Link
                  to="#"
                  className="block px-4 py-2 hover:bg-blue-500 hover:text-white"
                >
                  Settings
                </Link>
                <Link
                  to="#"
                  className="block px-4 py-2 hover:bg-blue-500 hover:text-white"
                >
                  Logout
                </Link>
              </div>
            )}
          </div>
        </div>
        {renderTabContent()}
       </div>
    </div>
  );
};

export default DashboardAndDoubts;
