import React from 'react'

const WorldMap = () => {
  return (
    <section className=' w-full flex md:flex-row flex-col mt-20'>
        <div className=' w-full md:w-[60%]'>
            <img src='/home-banner/map.png' className='w-full' alt='map' />
        </div>
        <div className=' w-full md:w-[40%] p-10 flex flex-col gap-10'>
            <h1 className=' text-[#2d81ba] text-3xl font-semibold text-center text-wrap'>
            Worldwide Access to Learning
            </h1>
            <div className='grid grid-cols-2 grid-rows-2 h-full'>
                <div className=' col-span-1 row-span-1 flex flex-col items-center justify-center gap-3 border-r border-b border-blue-gray-300'>
                    <h1 className='lg:text-5xl text-4xl font-semibold text-[#0b5397]'>7</h1>
                    <span className=' text-sm font-semibold text-gray-500'>Continents</span>
                </div>
                <div className=' col-span-1 row-span-1 flex flex-col items-center justify-center gap-3  border-b border-blue-gray-300'>
                    <h1 className='lg:text-5xl text-4xl font-semibold text-[#59970B]'>68</h1>
                    <span className=' text-sm font-semibold text-gray-500'>Countries</span>
                </div>
                <div className=' col-span-1 row-span-1 flex flex-col items-center justify-center gap-3 border-r border-blue-gray-300'>
                    <h1 className='lg:text-5xl text-4xl font-semibold text-[#0B9797]'>200+</h1>
                    <span className=' text-sm font-semibold text-gray-500'>Cities</span>
                </div>
                <div className=' col-span-1 row-span-1 flex flex-col items-center justify-center gap-3 border-blue-gray-300'>
                    <h1 className='lg:text-5xl text-4xl font-semibold text-[#830B97]'>200+</h1>
                    <span className=' text-sm font-semibold text-gray-500'>Cities</span>
                </div>
            </div>
        </div>
    </section>
  )
}

export default WorldMap