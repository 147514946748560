import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import "chart.js/auto";
import { DeleteIcon, EditIcon } from "../../views/Icons";
import moment from "moment";

const EnrollmentDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("Personal Information");
  const [coursesData, setCoursesData] = useState([]);
  const [personalInfo, setPersonalInfo] = useState(null);

  const base_url = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");

  const mockPersonalInfo = {
    title: "Mr",
    firstName: "Shawn",
    dob: "10 July, 2000",
    surname: "Michael",
    mobile: "+91-8734981280",
    email: "thewarhorse41@gmail.com",
    joiningDate: "10 July, 2018",
    registrationMethod: "Email",
    country: "India",
    leaveTaken: 10,
    city: "Lucknow",
    leaveAvailable: 30,
  };

  const mockCourses = [
    { name: "Fundamentals of User Interface", status: "Active" },
    { name: "Advanced JavaScript", status: "Inactive" },
    { name: "React for Beginners", status: "Pending" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${base_url}/users?role=Student`, {
          method: "GET",
          headers: {
            accept: "*/*",
            token: token,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const userData = data.responseData.find((user) => user._id === id);

        if (userData) {
          setPersonalInfo({
            title: "Mr/Ms",
            firstName: userData.fullName,
            dob: userData.dob || "Not provided",
            mobile: userData.mobile || "Not provided",
            email: userData.email,
            joiningDate: userData.createdAt,
            registrationMethod: "Email",
            country: userData.country || "Not provided",
            city: userData.city || "Not provided",
          });
          setCoursesData(userData.enrolledCourses);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get("tab");
    if (tabParam) {
      setActiveTab(tabParam);
    }
  }, [id, location.search, base_url]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);

    const queryParams = new URLSearchParams(location.search);
    queryParams.set("tab", tab);
    window.history.replaceState({}, "", `${location.pathname}?${queryParams}`);
  };

  const handleDeleteCourse = (index) => {
    const updatedCourses = coursesData.filter(
      (_, courseIndex) => courseIndex !== index
    );
    setCoursesData(updatedCourses);
  };

  return (
    <div className="p-5 bg-white">
      {/* Tabs Navigation */}
      <nav className="flex flex-wrap mb-5 text-[#9EB1C2] border-b border-gray-300">
        {["Personal Information", "Courses"].map((tab) => (
          <button
            key={tab}
            className={`py-2 px-4 cursor-pointer border-b-2 ${
              activeTab === tab
                ? "border-blue-500 font-bold"
                : "border-transparent"
            } flex items-center mb-2 mr-2`}
            onClick={() => handleTabChange(tab)}
          >
            <img
              src={
                tab === "Personal Information"
                  ? "/images/admin-personal.png"
                  : "/images/admin-course2.png"
              }
              alt={`${tab} Icon`}
              className="w-6 h-6 mr-2"
            />
            {tab}
          </button>
        ))}
      </nav>

      {activeTab === "Personal Information" && personalInfo && (
        <section className="mb-10">
          <h2 className="text-2xl font-semibold mb-4 text-[#9EB1C2]">
            Personal Information
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-[#9EB1C2] mb-10">
            <div className="col-span-1">
              <strong>Title</strong>
            </div>
            <div className="col-span-1">{personalInfo.title}</div>
            <div className="col-span-1">
              <strong>First Name</strong>
            </div>
            <div className="col-span-1">
              {personalInfo.firstName.split(" ")[0] || "N/A"}
            </div>

            <div className="col-span-1">
              <strong>Date of Birth</strong>
            </div>
            <div className="col-span-1">{personalInfo.dob}</div>
            <div className="col-span-1">
              <strong>Last Name</strong>
            </div>
            <div className="col-span-1">
              {personalInfo.firstName.split(" ")[1] || "N/A"}
            </div>

            <div className="col-span-1">
              <strong>Mobile Number</strong>
            </div>
            <div className="col-span-1">{personalInfo.mobile}</div>
            <div className="col-span-1">
              <strong>Email Address</strong>
            </div>
            <div className="col-span-1">{personalInfo.email}</div>
          </div>

          <h3 className="text-xl font-semibold mb-4 text-[#9EB1C2]">
            Additional Information
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-[#9EB1C2]">
            <div className="col-span-1">
              <strong>Joining Date</strong>
            </div>
            <div className="col-span-1">
              {personalInfo.joiningDate
                ? moment(personalInfo.joiningDate).format("MMMM DD, YYYY")
                : "N/A"}
            </div>
            <div className="col-span-1">
              <strong>Registration Method</strong>
            </div>
            <div className="col-span-1">{personalInfo.registrationMethod}</div>

            <div className="col-span-1">
              <strong>Country</strong>
            </div>
            <div className="col-span-1">{personalInfo.country}</div>

            <div className="col-span-1">
              <strong>City</strong>
            </div>
            <div className="col-span-1">{personalInfo.city}</div>
          </div>
        </section>
      )}

{activeTab === "Courses" && (
        <section id="courses-section" className="mb-10">
          <h2 className="text-2xl font-semibold mb-4 text-[#9EB1C2]">
            Courses
          </h2>
          {coursesData.length === 0 ? (
            <div className="text-center text-gray-500">
              No data available
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full min-w-full border-collapse text-[#9EB1C2]">
                <thead>
                  <tr>
                    <th className="border-b border-gray-300 p-2 text-left">#</th>
                    <th className="border-b border-gray-300 p-2 text-left">
                      Course Name
                    </th>
                    <th className="border-b border-gray-300 p-2 text-left">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {coursesData.map((course, index) => (
                    <tr key={index}>
                      <td className="p-2 border-b border-gray-200">
                        {index + 1}
                      </td>
                      <td className="p-2 border-b border-gray-200">
                        {course.title}
                      </td>
                      
                      <td className="p-2 border-b flex gap-2 border-gray-200">
                        <button>
                          <EditIcon />
                        </button>
                        <button onClick={() => handleDeleteCourse(index)}>
                          <DeleteIcon />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </section>
      )}
    </div>
  );
};


const getStatusColorClass = (is_active) => {
  const statusClasses = {
    Active: "bg-green-100 text-green-700",
    Inactive: "bg-red-100 text-red-700",
  };

  return statusClasses[is_active] || "bg-gray-100 text-gray-700";
};

export default EnrollmentDetails;
