import React, { useState } from 'react';
import 'chart.js/auto';

const TADetails = () => {
  const [activeTab, setActiveTab] = useState('Personal Information');
  const [coursesData, setCoursesData] = useState(courses);

  const handleDeleteCourse = (index) => {
    const updatedCourses = coursesData.filter((_, courseIndex) => courseIndex !== index);
    setCoursesData(updatedCourses);
  };

  return (
    <div className="p-5 bg-white">
      {/* Tabs Navigation */}
      <nav className="flex flex-wrap mb-5 text-[#9EB1C2] border-b border-gray-300">
        {['Personal Information', 'Courses'].map((tab) => {
          let iconSrc;

          switch (tab) {
            case 'Personal Information':
              iconSrc = '/images/admin-personal.png';
              break;
            case 'Courses':
              iconSrc = '/images/admin-course2.png';
              break;
            default:
              iconSrc = '';
          }

          return (
            <button
              key={tab}
              className={`py-2 px-4 cursor-pointer border-b-2 ${activeTab === tab ? 'border-blue-500 font-bold' : 'border-transparent'} flex items-center mb-2 mr-2`}
              onClick={() => setActiveTab(tab)}
            >
              <img
                src={iconSrc}
                alt={`${tab} Icon`}
                className="w-6 h-6 mr-2"
              />
              {tab}
            </button>
          );
        })}
      </nav>

      {activeTab === 'Personal Information' && (
  <section className="mb-10">
    <h2 className="text-2xl font-semibold mb-4 text-[#9EB1C2]">Personal Information</h2>
    <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-[#9EB1C2] mb-10">
      <div className="col-span-1"><strong>Title</strong></div>
      <div className="col-span-1">Mr</div>
      <div className="col-span-1"><strong>First Name</strong></div>
      <div className="col-span-1">Shawn</div>

      <div className="col-span-1"><strong>Date of Birth</strong></div>
      <div className="col-span-1">10 July, 2000</div>
      <div className="col-span-1"><strong>Surname</strong></div>
      <div className="col-span-1">Michael</div>

      <div className="col-span-1"><strong>Mobile Number</strong></div>
      <div className="col-span-1">+91-8734981280</div>
      <div className="col-span-1"><strong>Email Address</strong></div>
      <div className="col-span-1">thewarhorse41@gmail.com</div>
    </div>

    <h3 className="text-xl font-semibold mb-4 text-[#9EB1C2]">Additional Information</h3>
    <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-[#9EB1C2]">
      <div className="col-span-1"><strong>Joining Date</strong></div>
      <div className="col-span-1">10 July, 2018</div>
      <div className="col-span-1"><strong>Registration Method</strong></div>
      <div className="col-span-1">Email</div>

      <div className="col-span-1"><strong>Country</strong></div>
      <div className="col-span-1">India</div>
      
      {/* Leave Taken Section */}
      <div className="col-span-1"><strong>Leave Taken</strong></div>
      <div className="col-span-1">10</div>

      <div className="col-span-1"><strong>City</strong></div>
      <div className="col-span-1">Lucknow</div>

      {/* Leave Available Section */}
      <div className="col-span-1"><strong>Leave Available</strong></div>
      <div className="col-span-1">30</div>
    </div>
  </section>
)}

{activeTab === 'Courses' && (
        <section id="courses-section" className="mb-10">
          <h2 className="text-2xl font-semibold mb-4 text-[#9EB1C2]">Courses</h2>
          <div className="overflow-x-auto">
            <table className="w-full min-w-full border-collapse text-[#9EB1C2]">
              <thead>
                <tr>
                  <th className="border-b border-gray-300 p-2 text-left">#</th>
                  <th className="border-b border-gray-300 p-2 text-left">Course Name</th>
                  <th className="border-b border-gray-300 p-2 text-left">Status</th>
                  <th className="border-b border-gray-300 p-2 text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                {coursesData.map((course, index) => (
                  <tr key={index}>
                    <td className="p-2 border-b border-gray-200">{index + 1}</td>
                    <td className="p-2 border-b border-gray-200">{course.name}</td>
                    <td className="p-2 border-b border-gray-200">
                      <span className={`px-3 py-1 rounded-full text-sm font-semibold ${getStatusColorClass(course.status)}`}>
                        {course.status}
                      </span>
                    </td>
                    <td className="p-2 border-b border-gray-200">
                      <button
                        className="text-red-600 hover:text-red-800"
                        onClick={() => handleDeleteCourse(index)}
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      )}
    </div>
  );
};

// Sample Data for Courses
const courses = [
  { name: 'Fundamentals of User Interface', status: 'Active' },
  { name: 'Fundamentals of User Interface', status: 'Active' },
  { name: 'Fundamentals of User Interface', status: 'Active' },
];

const getStatusColorClass = (status) => {
    switch (status) {
      case 'Active':
        return 'bg-green-100 text-green-700';
      case 'Inactive':
        return 'bg-red-100 text-red-700';
      case 'Pending':
        return 'bg-orange-100 text-orange-700';
      default:
        return 'bg-gray-100 text-gray-700';
    }
  };

export default TADetails;
