import React from 'react'
import { Outlet } from 'react-router-dom'
import TopBanner from './TopBanner'
import Taships from './Taships'

const Internship  = ({children}) => {
  return (
    <div className=' w-full flex flex-col'>

        <div className=' w-full '>
            <TopBanner />
        </div>
        <div className='w-full p-10'>            
            {children}
        </div>
     
    </div>
  )
}

export default Internship;