import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClosedCaptioning } from "@fortawesome/free-solid-svg-icons";

const CourseContent = () => {
  const { id: courseId } = useParams();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [expandedSections, setExpandedSections] = useState({});
  const [course, setCourse] = useState({});
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [completedItems, setCompletedItems] = useState({});
  const [sections, setSections] = useState({});
  const [isNoteInputVisible, setIsNoteInputVisible] = useState(false);
  const [noteContent, setNoteContent] = useState("");
  const [activeTab, setActiveTab] = useState("notes");
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [replyText, setReplyText] = useState("");

  const handleReplyClick = () => setShowReplyInput(true);
  const handleCancelClick = () => {
    setShowReplyInput(false);
    setReplyText("");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    const getCourse = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/instructor-course-detail?id=${courseId}`,
          {
            headers: { token },
          }
        );
        const data = response.data.responseData;
        setCourse(data);

        // Set the first lesson's video URL if available
        if (data.lessons.length > 0) {
          const firstLessonVideoUrl = data.lessons[0].videoFileUrl;
          handleWatchLesson(firstLessonVideoUrl); // Start with the first lesson
        }

        // Group and sort lessons by section
        const groupedLessons = data.lessons.reduce((acc, lesson) => {
          const { section, order } = lesson;
          if (!acc[section]) acc[section] = [];
          acc[section].push(lesson);
          acc[section].sort((a, b) => a.order - b.order);
          return acc;
        }, {});

        setSections(groupedLessons);
      } catch (error) {
        console.error("Error fetching course", error);
      }
    };

    getCourse();
  }, [baseUrl, courseId]);

  useEffect(() => {
    // Initialize or update the video.js player
    if (videoRef.current) {
      if (!playerRef.current) {
        // Create a new Video.js player instance if it doesn't exist
        playerRef.current = videojs(videoRef.current, {
          controls: true,
          fluid: true,
        });
      }
      if (selectedVideoUrl) {
        // Update the video source directly when a new video is selected
        playerRef.current.src({
          src: `https://dqak5k10uk7br.cloudfront.net/${selectedVideoUrl}`,
          type: "video/mp4",
        });
      }

      // Handle video load errors
      playerRef.current.on("error", () => {
        const error = playerRef.current.error();
        console.error(`VideoJS Error: ${error.message}`);
        alert(`Error: ${error.message}`);
      });
    }
  }, [selectedVideoUrl]); // Re-run effect when selectedVideoUrl changes

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleWatchLesson = (videoUrl) => {
    const trimmedUrl = videoUrl.replace(
      "https://chainedge-bucket.s3.amazonaws.com/",
      ""
    );
    setSelectedVideoUrl(trimmedUrl); // Update the video URL without clearing the player
  };

  const handleVideoEnd = (itemKey) => {
    setCompletedItems((prev) => ({
      ...prev,
      [itemKey]: true,
    }));
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="bg-white flex flex-col lg:flex-row relative">
        {/* Left Section - Video Player */}
        <div className="w-full lg:w-2/3">
          <div className="relative h-full">
            <video
              ref={videoRef}
              className="video-js vjs-default-skin h-full w-full"
              onLoadedMetadata={() => console.log("Video metadata loaded")}
            />

            {/* Bottom Section - Notes, Overview, Announcements */}
            <div className="w-full p-6 bg-white mt-4 order-3">
              <div className="flex border-b border-gray-300 mb-4 bg-[#3A8BC3]">
                <button
                  className={`flex-1 text-center py-4 ${
                    activeTab === "overview"
                      ? "border-b-2 border-white font-semibold text-white"
                      : "text-white hover:border-white hover:bg-[#2A7AB6]"
                  }`}
                  onClick={() => setActiveTab("overview")}
                >
                  Overview
                </button>
                <button
                  className={`flex-1 text-center py-4 ${
                    activeTab === "notes"
                      ? "border-b-2 border-white font-semibold text-white"
                      : "text-white hover:border-white hover:bg-[#2A7AB6]"
                  }`}
                  onClick={() => setActiveTab("notes")}
                >
                  Notes
                </button>
                <button
                  className={`flex-1 text-center py-4 ${
                    activeTab === "announcements"
                      ? "border-b-2 border-white font-semibold text-white"
                      : "text-white hover:border-white hover:bg-[#2A7AB6]"
                  }`}
                  onClick={() => setActiveTab("announcements")}
                >
                  Announcements
                </button>
                <button
                  className={`flex-1 text-center py-4 ${
                    activeTab === "announcements"
                      ? "border-b-2 border-white font-semibold text-white"
                      : "text-white hover:border-white hover:bg-[#2A7AB6]"
                  }`}
                  onClick={() => setActiveTab("doubts")}
                >
                  Doubts
                </button>
              </div>

              {/* Content Sections */}
              {activeTab === "overview" && (
                <div>
                  <h1 className="text-3xl font-bold mb-4">
                    Full Stack Web Development
                  </h1>
                  <div className="flex items-center mb-4">
                    <span className="text-xl font-semibold mr-4">
                      4.7 ⭐ (6241 ratings)
                    </span>
                  </div>
                  <div className="flex space-x-4 mb-4">
                    <span className="text-gray-500">
                      Last updated on 6/2024
                    </span>
                    <span className="text-gray-700">English, Hindi</span>
                    <span className="text-gray-700 flex items-center">
                      <FontAwesomeIcon
                        icon={faClosedCaptioning}
                        className="mr-2"
                      />
                      English
                    </span>
                  </div>
                </div>
              )}
              {activeTab === "notes" && (
                <div>
                  {!isNoteInputVisible ? (
                    <div
                      className="flex justify-between items-center p-4 border border-gray-300 rounded-lg mb-4 cursor-pointer"
                      onClick={() => setIsNoteInputVisible(true)}
                    >
                      <span className="text-gray-600">Create a Note</span>
                      <button className="text-2xl font-semibold text-blue-500">
                        +
                      </button>
                    </div>
                  ) : (
                    <div>
                      <textarea
                        value={noteContent}
                        onChange={(e) => setNoteContent(e.target.value)}
                        placeholder="Write your note here..."
                        className="w-full p-3 border border-gray-300 rounded-lg mb-4 h-32"
                      ></textarea>
                      <div className="flex justify-end space-x-4">
                        <button
                          className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg"
                          onClick={() => {
                            setIsNoteInputVisible(false);
                            setNoteContent("");
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="px-4 py-2 bg-blue-500 text-white rounded-lg"
                          onClick={() => {
                            // Handle note save logic here
                            setIsNoteInputVisible(false);
                          }}
                        >
                          Save Note
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {activeTab === "announcements" && (
                <div>
                  {/* Instructor Profile Image and Name */}
                  <div className="flex items-center mb-2">
                    <img
                      src="/images/profile.png" // Replace with the correct path to the instructor's image
                      alt="Atishek Singh"
                      className="w-12 h-12 rounded-full mr-3"
                    />
                    <div>
                      <span className="text-lg font-semibold">
                        Atishek Singh
                      </span>
                      <p className="text-gray-500 text-sm">
                        posted an announcement
                      </p>
                    </div>
                  </div>

                  {/* Announcement Heading */}
                  <p className="text-lg font-semibold mb-4">
                    Great News! Course Fully Updated for 2024!
                  </p>

                  {/* Announcement Content */}
                  <p className="text-gray-700 mb-2">Hey,</p>
                  <p className="text-gray-700 mb-2">
                    How is your new year going? Have you set a new year's
                    resolution to finally learn to code? Or do you have a goal
                    to change careers this year? No matter your motivation for
                    picking up the Complete Web Development Bootcamp, I've got
                    fantastic news for you!
                  </p>
                  <p className="text-gray-700 mb-2">
                    We just released the fully updated Authentication module!
                    This means that all modules in this course are now fully up
                    to date! It took a lot of effort and we made lots of updates
                    to make this course relevant for programmers in 2024.
                  </p>
                  <p className="text-gray-700 mb-2">
                    So if you bought this course a million years ago and still
                    haven't started or watched some videos but never made the
                    jump to actually writing some code. Then this is your sign
                    to get started today!
                  </p>
                  <p className="text-gray-700 mb-2">
                    We've been making free updates to this course ever since it
                    was launched in 2018. Never charging students an extra penny
                    and hopefully we will continue to be able to make these
                    updates for you in the future.
                  </p>
                  <p className="text-gray-700 mb-2">
                    If you still need an extra push to get started, take a look
                    at this documentary that Udemy made to showcase the work of
                    one of my students, Alvin Lim. Alvin took my iOS course and
                    used those skills to build an app that got to the top of the
                    Apple App Store. He then built a venture-backed company and
                    has over a million users worldwide!
                  </p>
                  <p className="text-gray-700 mb-2">
                    Wishing you every success that you dream of for 2024!
                  </p>
                  <p className="text-gray-700 mb-2">
                    Your instructor, <br />
                    Afshak Singh
                  </p>
                </div>
              )}
              {activeTab === "doubts" && (
                <div>
                  {/* Student Message */}
                  <div className="flex items-start mb-2">
                    <img
                      src="/images/profile.png"
                      alt="Atishek Singh"
                      className="w-12 h-12 rounded-full mr-3"
                    />
                    <div>
                      <span className="text-lg font-semibold">
                        Atishek Singh
                      </span>
                      <p className="text-gray-500 text-sm">2 Days ago</p>
                      <p className="mt-1 text-gray-800 border-b border-black py-4">
                        subtitles is not matching with the words. So, it’s not
                        easy to understand what he is trying to say.
                      </p>
                    </div>
                  </div>

                  {/* Instructor Message with Like and Reply buttons */}
                  <div className="flex items-start mt-5 ml-10">
                    <img
                      src="/images/profile.png"
                      alt="Instructor"
                      className="w-12 h-12 rounded-full mr-3"
                    />
                    <div>
                      <span className="text-lg font-semibold">Instructor</span>
                      <p className="text-gray-500 text-sm">1 Day ago</p>
                      <p className="mt-1 text-gray-800 py-3">
                        subtitles is not matching with the words. So, it’s not
                        easy to understand what he is trying to say.
                      </p>
                      <div className="flex gap-4 text-gray-500 text-sm mt-2">
                        <button className="hover:underline">Like</button>
                        <button
                          onClick={handleReplyClick}
                          className="hover:underline"
                        >
                          Reply
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Reply Input Field */}
                  {showReplyInput && (
                    <div className="flex items-start mt-4 ml-10">
                      <img
                        src="/images/profile.png"
                        alt="Atishek Singh"
                        className="w-12 h-12 rounded-full mr-3"
                      />
                      <div className="w-full">
                        <textarea
                          value={replyText}
                          onChange={(e) => setReplyText(e.target.value)}
                          className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                          rows="2"
                          placeholder="Write your reply..."
                        />
                        <div className="flex gap-4 mt-2">
                          <button
                            onClick={() => {
                              // Handle send action here
                              setShowReplyInput(false);
                            }}
                            className="bg-[#3A8BC3] text-white px-4 py-1 rounded-md"
                          >
                            Send
                          </button>
                          <button
                            onClick={handleCancelClick}
                            className="bg-gray-300 text-gray-800 px-4 py-1 rounded-md"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Right Section - Course Content */}
        <div className="w-full lg:w-1/3 p-6 bg-white">
          <h2 className="text-2xl font-bold mb-6">Courses Content</h2>
          <div className="h-[800px] overflow-y-auto">
            {Object.entries(sections).map(([sectionName, lessons]) => (
              <div
                key={sectionName}
                className="bg-[#3A8BC3] border p-4 shadow-lg text-white mb-4"
              >
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleSection(sectionName)}
                >
                  <h3 className="text-lg font-semibold">{sectionName}</h3>
                  <span
                    className={`transform transition-transform duration-300 ${
                      expandedSections[sectionName] ? "rotate-180" : "rotate-0"
                    }`}
                  >
                    ▼
                  </span>
                </div>
                {expandedSections[sectionName] && (
                  <div className="mt-2">
                    {lessons.map((lesson) => (
                      <div key={lesson._id} className="flex items-start mb-2">
                        <>
                          <input
                            type="checkbox"
                            checked={!!completedItems[lesson._id]}
                            readOnly
                            className="mr-2 mt-1"
                          />
                          <label
                            className="cursor-pointer"
                            onClick={() => {
                              handleWatchLesson(lesson.videoFileUrl); // Update video when lesson is clicked
                            }}
                          >
                            {lesson.title}
                          </label>
                        </>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseContent;
