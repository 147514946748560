import React from 'react'

const GetHiredBy = () => {
  return (
    <section className=' w-full bg-[#2d81ba] p-3 md:px-10 py-10 flex flex-col gap-14 mt-20'>
        <div className='flex flex-wrap md:flex-nowrap w-full md:gap-0 gap-6 justify-around md:justify-center'>
            <div className=' w-[150px] md:w-[300px] flex flex-col items-center justify-center gap-3 md:border-r-2 border-white'>
                <h1 className='text-white text-2xl font-bold'>2.7+ L</h1>
                <span className='text-sm text-white font-semibold'>Learning Hours</span>
            </div>
            <div className=' w-[150px] md:w-[300px] flex flex-col items-center justify-center gap-3 md:border-r-2 border-white'>
                <h1 className='text-white text-2xl font-bold'>1:1</h1>
                <span className='text-sm text-white font-semibold'>Mentorship</span>
            </div>
            <div className=' w-[100px] md:w-[300px] flex flex-col items-center justify-center gap-3 md:border-r-2 border-white'>
                <h1 className='text-white text-2xl font-bold'>500+</h1>
                <span className='text-sm text-white font-semibold'>Mentors</span>
            </div>
            <div className=' w-[100px] md:w-[300px] flex flex-col items-center justify-center gap-3  border-white'>
                <h1 className='text-white text-2xl font-bold'>100+</h1>
                <span className='text-sm text-white font-semibold'>Companies Hiring</span>
            </div>
        </div>

        <div className=' flex justify-center items-center gap-3'>
            <div className='h-1 w-[400px] bg-white'></div>
            <h1 className=' text-2xl md:text-4xl text-white font-semibold'>Get Hired By</h1>
            <div className='h-1 w-[400px] bg-white'></div>
        </div>

        <div className=' sm:px-0 px-4 flex w-full flex-wrap gap-4  justify-between'>
            <img src='/home-banner/logos/logo1.png' alt='logo-c' />
            <img src='/home-banner/logos/logo2.png' alt='logo-c' />
            <img src='/home-banner/logos/logo3.png' alt='logo-c' />
            <img src='/home-banner/logos/logo4.png' alt='logo-c' />
            <img src='/home-banner/logos/logo5.png' alt='logo-c' />
            <img src='/home-banner/logos/logo6.png' alt='logo-c' />
            <img src='/home-banner/logos/logo7.png' alt='logo-c' />
            <img src='/home-banner/logos/logo8.png' alt='logo-c' />
            <img src='/home-banner/logos/logo9.png' alt='logo-c' />
        </div>
    </section>
  )
}

export default GetHiredBy