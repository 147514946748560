import React, { useState } from "react";
import {
    Select,
    MenuItem,
    Slider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormControl,
    InputLabel,
    TableFooter,
    TablePagination,
  } from "@mui/material";
  const Incomplete = () => {

  const [batch, setBatch] = useState("All");
  const [contentType, setContentType] = useState("All");
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [completionPercentage, setCompletionPercentage] = useState(25);
  const [page, setPage] = useState(0);

  const handleBatchChange = (event) => {
    setBatch(event.target.value);
  };

  const handleContentTypeChange = (event) => {
    setContentType(event.target.value);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

return (
        <>
        {/* Completion Percentage */}
        <div className="mb-6">
          <p className="text-sm sm:text-base">
            Completion Percentage: {completionPercentage}%
          </p>
          <Slider
            value={completionPercentage}
            onChange={(e, newValue) => setCompletionPercentage(newValue)}
            aria-labelledby="completion-percentage"
            valueLabelDisplay="auto"
            max={100}
          />
        </div>

        {/* Batch and Content Type Selectors */}
        <div className="flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0 sm:space-x-6 mb-6">
          <FormControl className="w-full sm:w-1/2">
            <InputLabel
              id="select-batch-label"
              className="select-batch-label"
              shrink={true} // Ensures the label stays above the border
              style={{
                marginBottom: "20px",
                transform: "translateY(-28px)",
              }} // Adjust the position
            >
              Select Batch
            </InputLabel>
            <Select
              labelId="select-batch-label"
              value={batch}
              onChange={handleBatchChange}
              className="w-full mb-6"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Batch 1">Batch 1</MenuItem>
              <MenuItem value="Batch 2">Batch 2</MenuItem>
            </Select>
          </FormControl>

          <FormControl className="w-full sm:w-1/2">
            <InputLabel
              id="select-content-type-label"
              className="select-content-type-label"
              shrink={true} // Ensures the label stays above the border
              style={{
                marginBottom: "20px",
                transform: "translateY(-28px)",
              }} // Adjust the position
            >
              Select Content Type
            </InputLabel>
            <Select
              labelId="select-content-type-label"
              value={contentType}
              onChange={handleContentTypeChange}
              className="w-full"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Lecture">Lecture</MenuItem>
              <MenuItem value="Assignment">Assignment</MenuItem>
            </Select>
          </FormControl>
        </div>

        {/* Content Table */}
        <TableContainer component={Paper} className="overflow-x-auto">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Completion Status</TableCell>
                <TableCell>Deadline</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{/* Add dynamic rows here */}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={3}
                  count={100} // Replace with dynamic count
                  rowsPerPage={itemsPerPage}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleItemsPerPageChange}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </>
    );
};
export default Incomplete;