import React from "react";
import CurvedBarChart from "./CurveChart";
import PieChart from "./pieChart";
import LineChart from "./LineChart";

const InstructorDashboard = () => {
  return (
    <div className="min-h-screen bg-[#F2F7FF] px-4 py-10">
      <div className="grid gap-6 grid-cols-1 md:grid-cols-3">
        {/* Row 1: Welcome and Weekly Activity Section */}
        <div className="col-span-1 md:col-span-1 bg-white rounded-lg shadow-lg overflow-hidden relative">
          <div className="w-full h-56 md:h-64 lg:h-full">
            <img
              src="/images/instDash.png"
              alt="Instructor Dashboard"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="absolute inset-0 flex flex-col items-start p-4 justify-start text-white bg-opacity-50">
            <h2 className="text-gray-300 text-sm p-2">Welcome back,</h2>
            <h1 className="text-2xl font-bold p-2">Mark Johnson</h1>
            <p className="text-gray-300 text-sm p-2">
              Glad to see you again!
              <br /> Ask me anything.
            </p>
          </div>
        </div>

        <div className="col-span-1 md:col-span-2 bg-white p-4 md:p-6 rounded-lg shadow-lg">
          <h2 className="text-lg font-semibold text-gray-800">Weekly Activity</h2>
          <div className="">
            <CurvedBarChart />
          </div>
        </div>

        {/* Row 2: Your Rating, Most Ordered Courses, and Order Section */}
        <div className="col-span-1 bg-white p-4 md:p-6 rounded-lg shadow-lg flex flex-col items-center">
          <h2 className="text-lg font-semibold text-gray-800">Your Rating</h2>
          <p className="text-gray-500 text-xs md:text-sm">
            Lorem ipsum dolor sit amet, consectetur
          </p>
          <div className="flex flex-col items-center mt-4 w-full">
            {/* Clarity Pie Chart - Top */}
            <div className="flex justify-center mb-2">
              <PieChart
                percentage={85}
                label="Clarity"
                color="rgba(100, 99, 214, 0.6)"
                size={{ width: "80px", height: "80px" }} // Default size
              />
            </div>
            <div className="flex flex-col md:flex-row justify-between w-full space-x-0 md:space-x-4">
              {/* Packaging Pie Chart - Left */}
              <div className="flex flex-col items-center mb-4 md:mb-0">
                <PieChart
                  percentage={92}
                  label="Packaging"
                  color="rgba(47, 191, 222, 0.6)"
                  size={{ width: "100px", height: "100px" }} // Medium size
                />
              </div>
              {/* Pie Chart for Doubt Solving - Right */}
              <div className="flex flex-col items-center">
                <PieChart
                  percentage={75}
                  label="Doubt Solving"
                  color="rgba(255, 165, 0, 0.6)"
                  size={{ width: "120px", height: "120px" }} // Larger size
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-1 bg-white p-4 md:p-6 rounded-lg shadow-lg flex-grow">
          <h2 className="text-lg font-semibold text-gray-800">Most Ordered Courses</h2>
          <p className="text-gray-500 text-xs md:text-sm mt-1">
            Adipiscing elit, sed do eiusmod tempor
          </p>
          <ul className="mt-4 space-y-2">
            <li className="flex justify-between items-center text-gray-700 text-sm">
              <span>UI/UX Design</span>
              <span>INR 45,000</span>
            </li>
            <li className="flex justify-between items-center text-gray-700 text-sm">
              <span>Frontend Development</span>
              <span>INR 45,000</span>
            </li>
            <li className="flex justify-between items-center text-gray-700 text-sm">
              <span>Backend Development</span>
              <span>INR 45,000</span>
            </li>
            <li className="flex justify-between items-center text-gray-700 text-sm">
              <span>Cybersecurity</span>
              <span>INR 45,000</span>
            </li>
          </ul>
        </div>
        
        <div className="col-span-1 bg-white p-4 md:p-6 rounded-lg shadow-lg flex-grow">
          <h2 className="text-lg font-semibold text-gray-800">Order</h2>
          <div className="flex justify-between items-center mt-4">
            <div>
              <h1 className="text-xl md:text-2xl font-bold text-gray-900">2,568</h1>
              <span className="text-xs md:text-sm text-red-500">↓ 2.1% vs last week</span>
            </div>
          </div>
          {/* Move the LineChart below the text */}
          <div className="w-full mt-4">
            <LineChart />
          </div>
          <span className="text-gray-500 text-xs md:text-sm mt-4 block">
            Sales from 1-6 Dec, 2020
          </span>
        </div>
      </div>
    </div>
  );
};

export default InstructorDashboard;
