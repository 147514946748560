import { useState } from "react";
import { Link } from "react-router-dom";
const Workplace = () => {

    const [isSwitchOn, setIsSwitchOn] = useState(false);

    const handleSwitchToggle = () => {
        setIsSwitchOn(!isSwitchOn);
      };

      const switchStyles = {
        container: {
          display: "inline-block",
          position: "relative",
          width: "50px",
          height: "24px",
        },
        input: {
          opacity: 0,
          width: 0,
          height: 0,
        },
        slider: {
          position: "absolute",
          cursor: "pointer",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "#ccc",
          transition: ".4s",
          borderRadius: "24px",
        },
        sliderBefore: {
          position: "absolute",
          content: "",
          height: "16px",
          width: "16px",
          borderRadius: "50%",
          left: "4px",
          bottom: "4px",
          backgroundColor: "#3A8BC3", // Blue color when off
          transition: ".4s",
        },
        inputCheckedSlider: {
          backgroundColor: "#3A8BC3", // Blue color when on
        },
        inputCheckedSliderBefore: {
          transform: "translateX(26px)",
          backgroundColor: "white", // White color when on
        },
      };

      const getCurrentTime = () => {
        const now = new Date();
        return now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
      };

      return (
        <>
        <div className="bg-[#3A8BC3] p-4 rounded shadow-lg mb-6">
      <div className="flex flex-col lg:flex-row justify-between items-center mb-6 lg:mb-10 space-y-4 lg:space-y-0">
        {/* Toggle Switch for Taking Doubts */}
        <div className="flex items-center bg-white py-2 px-4 rounded-full justify-between w-full lg:w-auto">
          <span className="text-[#3A8BC3] text-sm pr-3">
            {isSwitchOn ? "Taking Doubts On" : "Start Taking Doubts"}
          </span>
          <label style={switchStyles.container}>
            <input
              type="checkbox"
              style={switchStyles.input}
              checked={isSwitchOn}
              onChange={handleSwitchToggle}
            />
            <span
              style={{
                ...switchStyles.slider,
                ...(isSwitchOn ? switchStyles.inputCheckedSlider : {}),
              }}
            >
              <span
                style={{
                  ...switchStyles.sliderBefore,
                  ...(isSwitchOn ? switchStyles.inputCheckedSliderBefore : {}),
                }}
              ></span>
            </span>
          </label>
        </div>

        <div className="text-white lg:ml-6">9:17 PM</div>
        <div className="text-white lg:ml-6">Doubts Available: 0</div>
      </div>
      <span className="text-center text-white block mb-2">
        You can Accept or Reject from any of the doubts which will be
        displayed to you one by one. You can accept a maximum
      </span>
      <span className="text-white block">of 2 doubts at a time.</span>
</div>
      {/* Doubts Sections */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Incoming New Doubts Section */}
        <div className="space-y-4 mt-6 lg:mt-10">
          <h2 className="text-xl lg:text-2xl text-[#282828] font-bold">
            Incoming New Doubts
          </h2>
          <div className="bg-white p-4 lg:p-6 shadow-lg border-2 border-[#3A8BC3] flex items-center justify-center h-full">
            <p className="text-[#282828] text-center font-bold">
              Click the{" "}
              <Link to="#" className="text-blue-500">
                start taking doubts
              </Link>{" "}
              button to begin your Doubts session.
            </p>
          </div>
        </div>

        {/* My Active Doubts Section */}
        <div className="space-y-4 mt-6 lg:mt-10">
          <h2 className="text-xl lg:text-2xl text-[#282828] font-bold">
            My Active Doubts
          </h2>
          <div className="bg-white p-4 lg:p-6 shadow-lg border-2 border-[#3A8BC3] flex items-center justify-center h-full">
            <p className="text-[#282828] text-center font-bold">
              You have no active doubts. Click the{" "}
              <Link to="#" className="text-blue-500">
                start taking doubts
              </Link>{" "}
              button to begin. Doubts you have accepted will show up here.
            </p>
          </div>
        </div>
      </div>
      </>
      );
    };
    
    export default Workplace;