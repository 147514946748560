import React from 'react'
import { FaStar } from 'react-icons/fa'

const Testimonials = () => {

    const Card = () => {
        return (
            <div className=' flex flex-col  gap-6 w-[300px] lg:w-[500px] rounded-2xl bg-[#FFFFFF] py-5 px-10 shadow-md'>
                <h1 className=' text-lg text-[#2D81BA] font-semibold'>Kathryn Murphy</h1>
                <p className=' text-sm '>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled</p>
                <div className=' flex gap-2 text-[#2D81BA]'><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></div>
            </div>
        )
    }

  return (
    <section className='testimonial mt-20 p-10 lg:p-20 flex flex-col'>
        <h1 className=' text-white text-3xl text-center tracking-wider font-semibold capitalize'>TESTIMONIALS</h1>
        <div className='mt-10 flex lg:flex-row flex-col justify-center gap-10'>
            <Card />
            <Card />
        </div>
        <div className='mt-10 flex lg:flex-row flex-col justify-center gap-10'>
            <Card />
            <Card />
        </div>
    </section>
  )
}

export default Testimonials