import React from 'react'

const AboutUs = () => {
  return (
    <div className=' w-full flex flex-col gap-32 px-10 py-14 '>
        <div className='w-full flex flex-col'>
        <h2
          className="text-black text-2xl md:text-4xl lg:text-4xl tracking-wide"
          style={{ borderLeft: "5px solid #0b5397", paddingLeft: "20px" }}
        >
          About Us
        </h2>
        <div className=' w-full flex gap-20 mt-5 items-center'>
            <p className=' w-1/2 text-gray-900 text-2xl text-wrap'>Chain Edge is more than just an educational platform it's a dynamic community and movement towards a tech-savvy future. Founded by a team of passionate individuals, Chain Edge aims to revolutionize the way people learn about blockchain and AI. We believe in the power of education to empower individuals to shape the world around them, and we're committed to providing accessible, engaging, and effective learning experiences.</p>
            <img src='/images/about-us1.png' alt='about us' className=' w-1/2' />
        </div>
        </div>


        <div className='w-full flex flex-col'>
        <h2
          className="text-black text-2xl md:text-4xl lg:text-4xl tracking-wide"
          style={{ borderLeft: "5px solid #0b5397", paddingLeft: "20px" }}
        >
          Our Mission
        </h2>
        <div className=' w-full flex gap-20 mt-5 items-center'>
            <img src='/images/about-us2.png' alt='about us' className=' w-1/2' />
            <p className=' w-1/2 text-gray-900 text-2xl text-wrap'>Our mission at Chain Edge is to democratize access to knowledge about blockchain and AI. We recognize that these technologies hold immense potential to transform industries and society at large, but the complexity of these subjects can be a barrier to entry for many. That's why we're dedicated to breaking down these complex concepts into bite-sized, understandable lessons that ignite a passion for future-centric technology in learners of all backgrounds.</p>
        </div>
        </div>

        <div className='w-full flex flex-col'>
        <h2
          className="text-black text-2xl md:text-4xl lg:text-4xl tracking-wide"
          style={{ borderLeft: "5px solid #0b5397", paddingLeft: "20px" }}
        >
          Our Vision
        </h2>
        <div className=' w-full flex gap-20 mt-5 items-center'>
            
            <p className=' w-1/2 text-gray-900 text-2xl text-wrap'>At Chain Edge, we envision a world where anyone, regardless of their background or expertise, can harness the power of blockchain and AI to bring their ideas to life. We believe that by providing top-notch education that is both enjoyable and impactful, we can empower individuals to become the innovators and leaders of tomorrow. Our goal is to make this vision a reality by equipping learners with the skills and knowledge they need to thrive in the digital age.</p>
            <img src='/images/about-us3.png' alt='about us' className=' w-1/2' />
        </div>
        </div>
    </div>
  )
}

export default AboutUs