import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faSortAmountAsc,
  faEllipsisV,
  faUserSlash,
  faEye,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const enrollments = [];

const EnrollHistory = () => {
  const [data, setData] = useState(enrollments);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState();
  const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [suspensionReason, setSuspensionReason] = useState("");
  const [customSuspensionReason, setCustomSuspensionReason] = useState("");

  const buttonRefs = useRef([]);
  const dropdownRefs = useRef([]);
  const base_url = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEnrollments = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${base_url}/users?role=Student`, {
          headers: { token },
        });
        console.log(response.data);
        if (Array.isArray(response.data.responseData)) {
          setData(response.data.responseData);
        } else {
          setData([]);
        }
      } catch (err) {
        setError("Failed to fetch enrollment data.");
      } finally {
        setLoading(false);
      }
    };

    fetchEnrollments();
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownIndex !== null &&
        dropdownRefs.current[dropdownIndex] &&
        !dropdownRefs.current[dropdownIndex].contains(event.target)
      ) {
        setDropdownIndex(null);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropdownIndex]);

  const getStatusClass = (is_active) => {
    switch (is_active) {
      case "Active":
        return "bg-green-200 text-green-800";
      case "Suspend":
        return "bg-blue-200 text-blue-800";
      case "Pending":
        return "bg-yellow-200 text-yellow-800";
      default:
        return "bg-gray-200 text-gray-800";
    }
  };
  const getPaymentClass = (payment) => {
    switch (payment) {
      case "Paid":
        return "text-green-500";
      case "Cancelled":
        return "text-red-500";
      case "Pending":
        return "text-yellow-500";
      default:
        return "text-gray-500";
    }
  };

  const handleDropdownAction = (action, index) => {
    if (action === "Suspend") {
      setSelectedUser(data[index]);
      setIsSuspendModalOpen(true);
    }
    setDropdownIndex(null);
  };
  const handleViewDetails = (_id) => {
    navigate(`/admin/enroll-details/${_id}`);
  };

  const handleViewAllCourses = (userId) => {
    navigate(`/admin/enroll-details/${userId}?tab=Courses`);
  };

  const toggleDropdown = (index) => {
    setDropdownIndex(dropdownIndex === index ? null : index);
  };

  const handleCloseSuspendModal = () => {
    setIsSuspendModalOpen(false);
    setSuspensionReason("");
    setCustomSuspensionReason("");
  };

  const handleSuspend = () => {
    console.log(
      `Suspending ${selectedUser.user} for reason: ${
        suspensionReason === "Custom Reason"
          ? customSuspensionReason
          : suspensionReason
      }`
    );
    setIsSuspendModalOpen(false);
  };

  if (loading)
    return <p className="text-[#9EB1C2]">Loading enrollment data...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <div className="flex-grow" />
        <div className="flex items-center space-x-4">
          <button className="p-2 border border-gray-300 rounded-xl bg-white">
            <FontAwesomeIcon icon={faFilter} className="text-[#757D8A]" />
          </button>
          <div className="flex items-center border border-gray-300 rounded-xl bg-white p-2">
            <button>
              <FontAwesomeIcon
                icon={faSortAmountAsc}
                className="text-[#757D8A]"
              />
            </button>
            <span className="ml-2 text-sm font-semibold text-[#757D8A]">
              Sort: Status
            </span>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto lg:overflow-x-visible xl:overflow-x-visible">
        <table className="w-full bg-white rounded shadow relative">
          <thead>
            <tr>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                User
              </th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                Enrolled Course
              </th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                Phone
              </th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                Country
              </th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                Status
              </th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(data) && data.length > 0 ? (
              data.map((entry, index) => (
                <tr
                  key={index}
                  className="border-b text-[#9EB1C2] border-gray-300"
                >
                  <td className="p-2">{entry.fullName}</td>
                  <td className="p-2">
                    {Array.isArray(entry.enrolledCourses) &&
                    entry.enrolledCourses.length > 0 ? (
                      <>
                        {entry.enrolledCourses.slice(0, 2).map((course, i) => (
                          <span key={i}>
                            {course.title}
                            {i <
                              Math.min(1, entry.enrolledCourses.length - 1) &&
                              ", "}
                          </span>
                        ))}
                        {/* Always show the "View All Courses" button if at least one course exists */}
                        <button
                          onClick={() => handleViewAllCourses(entry._id)}
                          className="mt-2 block text-blue-500 hover:underline"
                        >
                          View All Courses
                        </button>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>

                  <td className="p-2">{entry.mobile || "-"}</td>
                  <td className="p-2">{entry.country || "-"}</td>

                  <td className="p-2 flex items-center">
                    <span
                      className={`inline-block px-2 py-1 rounded-full ${getStatusClass(
                        entry.is_active
                      )}`}
                    >
                      {entry.is_active}{" "}
                    </span>
                  </td>
                  <td className="p-2 relative">
                    <button
                      ref={(el) => (buttonRefs.current[index] = el)}
                      onClick={() => toggleDropdown(index)}
                      className="p-2 bg-inherit rounded-full"
                    >
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </button>
                    {dropdownIndex === index && (
                      <div
                        ref={(el) => (dropdownRefs.current[index] = el)}
                        className="absolute z-50 w-40 bg-white border border-gray-300 rounded shadow-lg"
                        style={{
                          top: "50%",
                          right: 0, // Align the dropdown to the right of the button
                          transform: "translateY(8px)", // Add a small offset for better alignment
                        }}
                      >
                        <ul>
                          <li>
                            <button
                              onClick={() =>
                                handleDropdownAction("Suspend", index)
                              }
                              className="w-full text-left p-2 hover:bg-gray-100 flex items-center"
                            >
                              <FontAwesomeIcon
                                icon={faUserSlash}
                                className="mr-2"
                              />
                              Suspend
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => handleViewDetails(entry._id)}
                              className="w-full text-left p-2 hover:bg-gray-100 flex items-center"
                            >
                              <FontAwesomeIcon icon={faEye} className="mr-2" />
                              View Details
                            </button>
                          </li>
                          <li>
                            <button className="w-full text-left p-2 hover:bg-gray-100 flex items-center">
                              <FontAwesomeIcon
                                icon={faUserPlus}
                                className="mr-2"
                              />
                              Active
                            </button>
                          </li>
                        </ul>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="p-2 text-center text-[#9EB1C2]">
                  No enrollment data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {isSuspendModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-xl w-full sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl">
            <h2 className="text-3xl text-center mb-10 text-[#5A5A5A]">
              Suspending Student
            </h2>

            <div className="mb-4 relative">
              <label className="absolute left-16 lg:left-44 md:left-40 sm:left-28 top-[-10px] text-[#9EB1C2] bg-white px-2 text-sm font-semibold">
                Suspension Reason
              </label>
              <select
                value={suspensionReason}
                onChange={(e) => setSuspensionReason(e.target.value)}
                className="max-w-sm w-full p-2 border border-gray-300 rounded ml-0 md:ml-16 placeholder-[#9EB1C2] text-[#9EB1C2] mb-6"
              >
                <option value="">Select Reason</option>
                <option value="Disciplinary Issue">Disciplinary Issue</option>
                <option value="Custom Reason">Custom Reason</option>
              </select>

              {/* Conditional rendering of the text field */}
              {suspensionReason === "Custom Reason" && (
                <div className="mt-4">
                  <label className="block mb-2 text-[#9EB1C2] font-semibold">
                    Custom Suspension Reason
                  </label>
                  <input
                    type="text"
                    value={customSuspensionReason}
                    onChange={(e) => setCustomSuspensionReason(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded text-[#9EB1C2] placeholder-[#9EB1C2]"
                    placeholder="Enter custom reason"
                  />
                </div>
              )}
            </div>

            <div className="flex justify-center md:justify-end mt-6">
              <button
                onClick={handleCloseSuspendModal}
                className="px-4 py-2 bg-[#F4F4F4] rounded mr-2 text-[#848484] mb-4 md:mb-0"
              >
                Cancel
              </button>
              <button
                onClick={handleSuspend}
                className="px-4 py-2 bg-[#3A8BC3] text-white rounded mb-4 md:mb-0"
              >
                Suspend
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnrollHistory;
