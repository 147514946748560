import React from 'react'

const ChainEdgeSection = () => {
  return (
    <div className="text-center w-full px-4 md:px-8 lg:px-16">
      <h2 className="text-3xl md:text-4xl font-semibold text-[#3C3C3C] mt-12 mb-12 md:mt-20 md:mb-20">
        A 3 Stage Learning Model
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
        <div>
          <h3 className="text-2xl md:text-3xl font-bold text-start text-[#3C3C3C] mb-4 md:mb-6">Learn</h3>
          <p className="text-gray-600 text-start">
            Lorem ipsum dolor sit amet consectetur. Morbi duis vitae mi facilisi cursus nibh. Dolor scelerisque porttitor bibendum volutpat amet donec ornare massa.
          </p>
        </div>
        <div>
          <h3 className="text-2xl md:text-3xl font-bold text-start text-[#3C3C3C] mb-4 md:mb-6">Excel</h3>
          <p className="text-gray-600 text-start">
            Lorem ipsum dolor sit amet consectetur. Morbi duis vitae mi facilisi cursus nibh. Dolor scelerisque porttitor bibendum volutpat amet donec ornare massa.
          </p>
        </div>
        <div>
          <h3 className="text-2xl md:text-3xl font-bold text-start text-[#3C3C3C] mb-4 md:mb-6">Standout</h3>
          <p className="text-gray-600 text-start">
            Lorem ipsum dolor sit amet consectetur. Morbi duis vitae mi facilisi cursus nibh. Dolor scelerisque porttitor bibendum volutpat amet donec ornare massa.
          </p>
        </div>
      </div>
    </div>
  )
}

export default ChainEdgeSection