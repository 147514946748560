import React from 'react';
import Banner from "./banner";
import About from "./about";
import Curriculum from "./curriculum";

const CourseDetails = () => {
    return (
        <div className=' w-full flex flex-col mt-10 mb-36 px-20 gap-20'>
          <Banner />
          <About />
          <Curriculum />
        </div>
    )
}

export default CourseDetails;