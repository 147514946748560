import React, { useState } from 'react';

const MyCourses = () => {
  const [activeTab, setActiveTab] = useState('enrolled');

  // Sample data for enrolled courses
  const enrolledCourses = [
    {
      id: 1,
      title: 'Python Foundation with Data Structures',
      category: 'Data Structures and Algorithms',
      startDate: '17 aug, 2024',
      batch: 'Current Batch: Introduction to Python',
      image: '/home-banner/python.png',
    },
    {
      id: 2,
      title: 'Java Foundation with Data Structures',
      category: 'Data Structures and Algorithms',
      startDate: '17 aug, 2024',
      batch: 'Current Batch: Introduction to Java',
      image: '/home-banner/java.png',
    },
  ];

  // Sample data for wishlist courses
  const wishlistCourses = [
    {
      id: 1,
      title: 'Python Foundation with Data Structures',
      category: 'Data Structures and Algorithms',
      startDate: '17 aug, 2024',
      batch: '',
      image: '/home-banner/python.png',
    },
    {
      id: 2,
      title: 'Java Foundation with Data Structures',
      category: 'Data Structures and Algorithms',
      startDate: '17 aug, 2024',
      batch: '',
      image: '/home-banner/java.png',
    },
  ];

  return (
    <div className="w-full max-w-3xl mx-auto p-4">
      {/* Tab buttons */}
      <div className="flex justify-center mb-6">
        <button
          className={`px-4 py-2 font-medium ${activeTab === 'enrolled' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
          onClick={() => setActiveTab('enrolled')}
        >
          Courses Enrolled
        </button>
        <button
          className={`ml-6 px-4 py-2 font-medium ${activeTab === 'wishlist' ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500'}`}
          onClick={() => setActiveTab('wishlist')}
        >
          Wishlist
        </button>
      </div>

      {/* Render content based on activeTab */}
      {activeTab === 'enrolled' && (
        <div className="space-y-4">
          {enrolledCourses.map((course) => (
            <div key={course.id} className="flex flex-col sm:flex-row items-center sm:items-start p-4 border rounded-lg shadow-sm bg-white text-center sm:text-left">
              <img
                src={course.image}
                alt={course.title}
                className="w-24 h-24 sm:w-16 sm:h-16 mb-4 sm:mb-0 sm:mr-4 object-contain mx-auto sm:mx-0"
              />
              <div className="flex-1">
                <h3 className="text-xl text-[#494949] font-semibold">{course.title}</h3>
                <div className="mt-4 sm:mt-10 border rounded-lg p-3">
                  <p className="text-lg font-bold text-[#494949] pb-2 sm:pb-5">{course.category}</p>
                  <div className="flex flex-col sm:flex-row justify-between items-center">
                    <div className="mb-2 sm:mb-0">
                      <p className="text-sm text-gray-500">Start date:</p>
                      <p className="text-sm text-gray-500">{course.startDate}</p>
                    </div>
                    <p className="text-sm text-gray-500">{course.batch}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {activeTab === 'wishlist' && (
        <div className="space-y-4">
          {wishlistCourses.map((course) => (
            <div key={course.id} className="flex flex-col sm:flex-row items-center sm:items-start p-4 border rounded-lg shadow-sm bg-white text-center sm:text-left">
                    <img src={course.image}
                  alt={course.title}
                  className="w-24 h-24 sm:w-16 sm:h-16 mb-4 sm:mb-0 sm:mr-4 object-contain mx-auto sm:mx-0"/>
                <div className="flex-1">
                  <h3 className="text-xl text-[#494949] font-semibold">{course.title}</h3>
                  <div className="mt-4 sm:mt-10 border rounded-lg p-3">
                    <p className="text-lg font-bold text-[#494949] pb-2 sm:pb-5">{course.category}</p>
                    <div className="flex flex-col sm:flex-row justify-between items-center">
                      <div className="mb-2 sm:mb-0">
                        <p className="text-sm text-gray-500">Start date:</p>
                        <p className="text-sm text-gray-500">{course.startDate}</p>
                      </div>
                      <button className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600">
                        Add To Cart
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  export default MyCourses;
