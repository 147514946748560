import React, { useContext } from "react";
import { Disclosure } from "@headlessui/react";
import {BlindsClosedRounded,MenuOutlined} from '@mui/icons-material';
import { Link, useLocation, useNavigate } from "react-router-dom";
// import ProfileMenu from "../elements/profile-menu";
import { Button } from "@material-tailwind/react";
import { AuthContext } from "../../hooks/useAuth";
import { FaHouseUser } from "react-icons/fa";
import { TbLogout } from 'react-icons/tb';
import { FaUserPen } from 'react-icons/fa6';
import { BiSolidUserDetail } from "react-icons/bi";



const navigation = [
  { name: "Courses", href: "/courses", current: false },
  { name: "About Us", href: "/about-us", current: false },
  { name: "Internship", href: "/internship", current: false },
  { name: "Webinar", href: "/webinar", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const location = useLocation();
  let navigate = useNavigate();

  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const userType = localStorage.getItem("userType");

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    setIsLoggedIn(false);
    navigate("/");
    // Add any additional logout logic here
  };



  const handleNavigation = (item) => {
    navigation.forEach((navItem) => {
      navItem.current = navItem.href === item.href;
    });
  };
      
  return (
    <div className="bg-gray-100 border-b border-b-gray-300 ">
      <Disclosure
        as="nav"
        className="bg-white pl-10 pr-10 py-2 lg:py-3 lg:flex lg:justify-center"
      >
        {({ open }) => (
          <>
            <div className="max-w-full  md:w-full lg:w-full  ">
              <div className=" w-full flex items-center justify-between md:h-14 lg:h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0 ">
                    <Link to="/">
                      <img
                        className="h-10"
                        src="/home-banner/logo.png"
                        alt="Your Company"
                      />
                    </Link>
                  </div>
                </div>
                <div className="hidden lg:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        onClick={() => handleNavigation(item)}
                        className={classNames(
                          item.current
                            ? "customRed text-[#3A8bc3] font-semibold"
                            : "text-black  font-semibold  ",
                          "rounded-md px-3 py-2 text-sm font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                    {/* <SearchBar /> */}
                  </div>
                </div>
                {false ? (
                  <div className="hidden md:ml-5 lg:flex items-center justify-between">
                    <div className="">
                      <Link to="/">
                        <Button size="md" color="greenblue">
                          Dashboard
                        </Button>
                      </Link>
                    </div>
                    <div className=" pl-3 ml-3 items-end">
                      {/* <ProfileMenu /> */}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="hidden h-8 w-64 lg:mt-3 lg:h-12 lg:w-72 lg:flex justify-end">
                      {location.pathname !== "/login" && !isLoggedIn && (
                        // if not logged in render Login button
                        <div className="space-x-4">
                          <Link to="/login">
                            <button className="bg-[#3A8bc3] hover:greenblue text-xs lg:text-sm text-white font-bold py-2 px-6 rounded-[40px] focus:outline-none focus:shadow-outline">
                              Login
                            </button>
                          </Link>
                          <Link to="/signup">
                            <button className="bg-[#3A8bc3] hover:greenblue  text-xs lg:text-sm text-white font-bold py-2 px-6 rounded-[40px] focus:outline-none focus:shadow-outline">
                              Sign Up
                            </button>
                          </Link>
                        </div>
                      )}

                      {isLoggedIn && (
                        <button className=" relative z-50 group transition-all min-w-10 min-h-10">
                          <img
                            src="/home-banner/profilePlaceholder.jpeg"
                            alt="user"
                            className="w-10 h-10 rounded-full"
                          />
                          <div className="absolute z-50 right-0 top-10 hidden px-1  flex-col gap-1 rounded-lg bg-white py-3 shadow-md transition-all group-hover:flex">
                            <div className=" w-[160px]">
                              <details className="group">
                                <summary className="flex items-center justify-between font-medium px-5 cursor-pointer list-none">
                                  <span className=" flex text-nowrap">
                                    My Profile
                                  </span>
                                  <span className="transition group-open:rotate-180">
                                    <svg
                                      fill="none"
                                      height="24"
                                      shape-rendering="geometricPrecision"
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1.5"
                                      viewBox="0 0 24 24"
                                      width="24"
                                    >
                                      <path d="M6 9l6 6 6-6"></path>
                                    </svg>
                                  </span>
                                </summary>
                                <div className="mt-3 group-open:animate-fadeIn">
                                  {/* Expanded information */}
                                  <div className="flex flex-col">
                                    <Link
                                      to="/"
                                      className="flex font-semibold cursor-pointe py-1 text-black-400 hover:text-[#2d81ba]"
                                    >
                                      <span className="whitespace-nowrap gap-2 flex items-center pl-3">
                                        <BiSolidUserDetail /> My Courses
                                      </span>
                                    </Link>
                                    <Link
                                      to="/"
                                      className="flex font-semibold cursor-pointer py-1 text-black-400 hover:text-[#2d81ba]"
                                    >
                                      <span className="whitespace-nowrap gap-2 flex items-center pl-3">
                                        <FaUserPen /> Account
                                      </span>
                                    </Link>
                                  </div>
                                </div>
                              </details>
                            </div>

                            <button
                              onClick={handleLogout}
                              className="py-2 px-3 flex gap-2 items-center text-white transition-all bg-[#2d81ba] hover:bg-opacity-75"
                            >
                              <TbLogout /> Logout
                            </button>
                          </div>
                        </button>
                      )}
                    </div>
                  </>
                )}
                <div className="-mr-2 flex lg:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <BlindsClosedRounded className="block h-6 w-6" />
                    ) : (
                      <MenuOutlined className="block h-6 w-6" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="lg:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    onClick={() => handleNavigation(item)}
                    className={classNames(
                      item.current
                        ? "customRed text-black"
                        : " text-black hover:bg-gray-300 ",
                      "block rounded-md px-3 py-2 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <div className=" h-fit w-full lg:mt-3 lg:h-12 lg:w-72 flex justify-start">
                {location.pathname !== "/login" && !isLoggedIn && (
                  <div className="space-x-4">
                    <Link to="/">
                      <button className="bg-[#2d81ba] hover:greenblue text-xs lg:text-sm text-white font-bold py-2 px-4 rounded-[40px] focus:outline-none focus:shadow-outline">
                        Register
                      </button>
                    </Link>
                    <Link to="/">
                      <button className="bg-[#2d81ba] hover:greenblue  text-xs lg:text-sm text-white font-bold py-2 px-4 rounded-[40px] focus:outline-none focus:shadow-outline">
                        Login
                      </button>
                    </Link>
                  </div>
                )}

                {isLoggedIn && (
                  <div className=" w-full">
                    <details className="group">
                      <summary className="flex items-center justify-between font-medium px-5 cursor-pointer list-none">
                        <img
                          src="/home-banner/profilePlaceholder.jpeg"
                          alt="user"
                          className="w-10 h-10 rounded-full"
                        />
                        <span className=" flex text-nowrap">My Profile</span>
                        <span className="transition group-open:rotate-180">
                          <svg
                            fill="none"
                            height="24"
                            shape-rendering="geometricPrecision"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            viewBox="0 0 24 24"
                            width="24"
                          >
                            <path d="M6 9l6 6 6-6"></path>
                          </svg>
                        </span>
                      </summary>
                      <div className="mt-5 group-open:animate-fadeIn bg-white">
                        {/* Expanded information */}
                        <div className="flex flex-col">
                          <Link
                            to="/profile/personal-info"
                            className="flex font-semibold cursor-pointe py-1 text-black-400 hover:text-primary-1"
                          >
                            <span className="whitespace-nowrap gap-2 flex items-center pl-3">
                              <BiSolidUserDetail  /> My Courses
                            </span>
                          </Link>
                          <Link
                            to="/profile"
                            className="flex font-semibold cursor-pointer py-1 text-black-400 hover:text-primary-1"
                          >
                            <span className="whitespace-nowrap gap-2 flex items-center pl-3">
                              <FaUserPen /> Account
                            </span>
                          </Link>
                        </div>
                      </div>
                    </details>
                    <button
                      onClick={handleLogout}
                      className="py-2 px-3 flex gap-2 text-lg w-full mt-5 justify-center items-center text-white transition-all bg-[#2d81ba] hover:bg-opacity-75"
                    >
                      <TbLogout /> Logout
                    </button>
                  </div>
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default Header;
