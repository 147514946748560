import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSortAmountAsc } from '@fortawesome/free-solid-svg-icons';

function TAProgress() {
  const [tas, setTAs] = useState([
    { name: 'Shawn Michael', doubtsSolved: 10, leavesTaken: 5, rating: 5, loginTime: '09:30 A.M', logoutTime: '12:45 P.M' },
    { name: 'Shawn Michael', doubtsSolved: 10, leavesTaken: 5, rating: 5, loginTime: '09:30 A.M', logoutTime: '12:45 P.M' },
    { name: 'Shawn Michael', doubtsSolved: 10, leavesTaken: 5, rating: 5, loginTime: '09:30 A.M', logoutTime: '12:45 P.M' },
    { name: 'Shawn Michael', doubtsSolved: 10, leavesTaken: 5, rating: 5, loginTime: '09:30 A.M', logoutTime: '12:45 P.M' },
    // Add more rows if needed
  ]);

  return (
    <div className="p-4 md:p-6 bg-[#EEF5FF] h-full">
      {/* Search Bar and Filter/Sort */}
      <div className="relative flex flex-col md:flex-row justify-between items-center mb-4">
        {/* Search Input */}
        <div className="relative w-full md:w-1/3 mb-4 md:mb-0">
          <i className="fas fa-search absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"></i>
          <input
            type="text"
            placeholder="Search by Name"
            className="pl-10 p-2 w-full border border-gray-300 rounded-xl text-center"
          />
        </div>
        {/* Filters and Sort */}
        <div className="flex items-center">
          <button className="p-2 border border-gray-300 rounded-xl bg-white mr-4">
            <FontAwesomeIcon icon={faFilter} className="text-[#757D8A]" />
          </button>
          <div className="p-2 border border-gray-300 rounded-xl bg-white flex items-center">
            <button>
              <FontAwesomeIcon icon={faSortAmountAsc} className="text-[#757D8A]" />
            </button>
            <span className="ml-2 text-sm font-semibold text-[#757D8A]">Sort: Login Time</span>
          </div>
        </div>
      </div>

      {/* TA Progress Table */}
      <div className="overflow-x-auto lg:overflow-x-visible xl:overflow-x-visible">
        <table className="w-full bg-white rounded shadow">
          <thead>
            <tr>
              <th className="p-4 text-left text-[#9EB1C2] border-b border-gray-300">TA Name</th>
              <th className="p-4 text-left text-[#9EB1C2] border-b border-gray-300">Doubts Solved</th>
              <th className="p-4 text-left text-[#9EB1C2] border-b border-gray-300">Leaves Taken</th>
              <th className="p-4 text-left text-[#9EB1C2] border-b border-gray-300">Rating</th>
              <th className="p-4 text-left text-[#9EB1C2] border-b border-gray-300">Login Time</th>
              <th className="p-4 text-left text-[#9EB1C2] border-b border-gray-300">Logout Time</th>
            </tr>
          </thead>
          <tbody>
            {tas.map((ta, index) => (
              <tr key={index} className="border-b text-[#9EB1C2] border-gray-300">
                <td className="p-4">{ta.name}</td>
                <td className="p-4">{ta.doubtsSolved}</td>
                <td className="p-4">{ta.leavesTaken}</td>
                <td className="p-4">
                  {Array.from({ length: ta.rating }, (_, i) => (
                    <span key={i} className="text-yellow-500">★</span>
                  ))}
                </td>
                <td className="p-4">{ta.loginTime}</td>
                <td className="p-4">{ta.logoutTime}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TAProgress;
