import React from "react";
import { Pie } from "react-chartjs-2";

const PieChart = ({ percentage, label, color, size }) => {
  const data = {
    labels: ["Used", "Remaining"],
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [
          color, // Used color
          "rgba(200, 200, 200, 0.2)", // Remaining color
        ],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Disable the legend
      },
      tooltip: {
        enabled: false, // Disable the tooltip
      },
    },
  };

  return (
    <div style={{ position: "relative", width: size.width, height: size.height }}>
      <Pie data={data} options={options} />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <span style={{ fontWeight: "bold", fontSize: "12px" }}>{label}</span>
        <br />
        <span style={{ fontSize: "10px" }}>{percentage}%</span>
      </div>
    </div>
  );
};

export default PieChart;
