import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

const AddCourse = () => {
  const [formData, setFormData] = useState({
    courseName: "",
    courseCategory: "",
    courseDifficulty: "",
    courseThumbnail: null,
    totalLessons: "",
    price: "",
    durationInWeeks: "",
    introductionVideo: null,
    tags: "",
    courseDescription: "",
    syllabus: "",
    deliveryMode: "",
    language: "",
  });

  const [loading, setLoading] = useState(false);

  const base_url = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const courseData = new FormData();
    courseData.append("title", formData.courseName);
    courseData.append("category", formData.courseCategory);
    courseData.append("level", formData.courseDifficulty);
    courseData.append("thumbnail", formData.courseThumbnail);
    courseData.append("introVideo", formData.introductionVideo);
    courseData.append("price", formData.price);
    courseData.append("duration", formData.durationInWeeks);
    courseData.append("deliveryMode", formData.deliveryMode);
    courseData.append("language", formData.language);
    courseData.append("tags", formData.tags);
    courseData.append("curriculum", formData.syllabus);
    courseData.append("description", formData.courseDescription);

    try {
      const response = await axios.post(`${base_url}/add-course`, courseData, {
        headers: {
          "Content-Type": "multipart/form-data",
          token: token,
          accept: "*/*",
        },
      });
      console.log("Course added successfully:", response.data.responseData);
      toast("Course added successfully")
      navigate("/instructor/courses");
    } catch (error) {
      console.error("Error adding course:", error);

     
      toast("Failed to add course. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full max-w-[90%] lg:w-[80%] mx-auto bg-white p-4 sm:p-8 rounded-md shadow-md text-[#9A9A9A]">
      <h2 className="text-lg sm:text-2xl font-bold text-center mb-4 sm:mb-6 underline text-[#686868]">
        Add Course
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
          <div className="col-span-1 sm:col-span-2">
            <label className="block mb-1 sm:mb-2">Course Name</label>
            <input
              type="text"
              name="courseName"
              value={formData.courseName}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded p-1 sm:p-2"
              required
            />
          </div>
          <div>
            <label className="block mb-1 sm:mb-2">Course Category</label>
            <select
              name="courseCategory"
              value={formData.courseCategory}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded p-1 sm:p-2"
            >
              <option value="">Select Category</option>
              <option value="programming">Programming</option>
              <option value="design">Design</option>
            </select>
          </div>
          <div>
            <label className="block mb-1 sm:mb-2">Course Difficulty</label>
            <select
              name="courseDifficulty"
              value={formData.courseDifficulty}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded p-1 sm:p-2"
            >
              <option value="">Select Difficulty</option>
              <option value="beginner">Beginner</option>
              <option value="intermediate">Intermediate</option>
              <option value="advanced">Advanced</option>
            </select>
          </div>
          <div>
            <label className="block mb-1 sm:mb-2">Course Thumbnail</label>
            <div className="flex items-center">
              <input
                type="file"
                name="courseThumbnail"
                onChange={handleFileChange}
                className="hidden"
                id="courseThumbnail"
              />
              <label
                htmlFor="courseThumbnail"
                className="flex-1 border border-gray-300 rounded-l p-1 sm:p-2 cursor-pointer"
              >
                {formData.courseThumbnail
                  ? formData.courseThumbnail.name
                  : "Choose File"}
              </label>
              <label
                htmlFor="courseThumbnail"
                className="bg-[#EAEAEA] text-[#707070] rounded-r px-2 sm:px-4 sm:py-2 py-1 cursor-pointer"
              >
                Browse
              </label>
            </div>
          </div>
          <div>
            <label className="block mb-1 sm:mb-2">Total Lessons</label>
            <input
              type="number"
              name="totalLessons"
              value={formData.totalLessons}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded p-1 sm:p-2"
            />
          </div>
          <div>
            <label className="block mb-1 sm:mb-2">Price</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded p-1 sm:p-2"
            />
          </div>
          <div>
            <label className="block mb-1 sm:mb-2">Course Duration</label>
            <input
              type="text"
              name="durationInWeeks"
              value={formData.durationInWeeks}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded p-1 sm:p-2"
            />
          </div>
          <div>
            <label className="block mb-1 sm:mb-2">Introduction Video</label>
            <div className="flex items-center">
              <input
                type="file"
                name="introductionVideo"
                onChange={handleFileChange}
                className="hidden"
                id="introductionVideo"
              />
              <label
                htmlFor="introductionVideo"
                className="flex-1 border border-gray-300 rounded-l p-1 sm:p-2 cursor-pointer"
              >
                {formData.introductionVideo
                  ? formData.introductionVideo.name
                  : "Choose File"}
              </label>
              <label
                htmlFor="introductionVideo"
                className="bg-[#EAEAEA] text-[#707070] rounded-r px-2 py-1 sm:p-4 sm:py-2 cursor-pointer"
              >
                Choose file
              </label>
            </div>
          </div>
          <div>
            <label className="block mb-1 sm:mb-2">Tags</label>
            <input
              type="text"
              name="tags"
              value={formData.tags}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded p-1 sm:p-2"
            />
          </div>
          {/* New Delivery Mode Field */}
          <div>
            <label className="block mb-1 sm:mb-2">Delivery Mode</label>
            <select
              name="deliveryMode"
              value={formData.deliveryMode}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded p-1 sm:p-2"
            >
              <option value="">Select Delivery Mode</option>
              <option value="live">Live</option>
              <option value="recorded">Recorded</option>
              <option value="live + recorded">Live + Recorded</option>
            </select>
          </div>
          {/* New Language Field */}
          <div>
            <label className="block mb-1 sm:mb-2">Language</label>
            <select
              name="language"
              value={formData.language}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded p-1 sm:p-2"
            >
              <option value="">Select Language</option>
              <option value="hindi">Hindi</option>
              <option value="english">English</option>
              <option value="hindi + english">Hindi + English</option>
            </select>
          </div>
          <div className="col-span-1 sm:col-span-2 flex flex-col sm:flex-row gap-4">
            {/* Course Description */}
            <div className="flex-1 flex flex-col">
              <label className="block mb-1 sm:mb-2">Course Description</label>
              <textarea
                name="courseDescription"
                value={formData.courseDescription}
                onChange={handleInputChange}
                rows="8"
                className="flex-1 w-full border rounded-md p-1 sm:p-2 focus:outline-[#3A8BC3] focus:ring-2 focus:ring-[#3A8BC3] transition duration-200"
                placeholder="Write your course description here..."
              />
            </div>

            {/* Syllabus */}
            <div className="flex-1 flex flex-col">
              <label className="block mb-1 sm:md-2">Syllabus</label>
              <textarea
                name="syllabus"
                value={formData.syllabus}
                onChange={handleInputChange}
                rows="8"
                className="flex-1 w-full border rounded-md p-1 sm:p-2 focus:outline-[#3A8BC3] focus:ring-2 focus:ring-[#3A8BC3] transition duration-200"
                placeholder="Write your syllabus here..."
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center gap-4 mt-4 sm:mt-6 flex-wrap">
          <Link to="/instructor/courses">
            <button
              type="button"
              className="bg-[#EAEAEA] text-[#707070] px-2 py-1 sm:px-3 sm:py-1.5 rounded text-sm sm:text-base"
            >
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            className={`bg-[#3A8BC3] text-white px-2 py-1 sm:px-3 sm:py-1.5 rounded text-sm sm:text-base ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            Save Information
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddCourse;
