import React from 'react'
import CourseCard from './CourseCard';

const FeaturedCourses = () => {
    const courses = [
        {
          title: 'Blockchain Foundation for Developers',
          duration: '12 hrs 30 mins',
          pace: 'Self-Paced',
          lectures: 40,
          rating: '4.6',
          reviews: '3K',
          imageUrl: '/home-banner/features.png',
        },
        {
          title: 'Blockchain Fundamentals',
          duration: '12 hrs 30 mins',
          pace: 'Self-Paced',
          lectures: 40,
          rating: '4.6',
          reviews: '3K',
          imageUrl: '/home-banner/features.png', 
        },
        {
          title: 'Blockchain: Foundation and Use Cases',
          duration: '12 hrs 30 mins',
          pace: 'Self-Paced',
          lectures: 40,
          rating: '4.6',
          reviews: '3K',
          imageUrl: '/home-banner/features.png', 
        },
        {
          title: 'Artificial Intelligence Fundamentals',
          duration: '12 hrs 30 mins',
          pace: 'Self-Paced',
          lectures: 40,
          rating: '4.6',
          reviews: '3K',
          imageUrl: '/home-banner/features.png', 
        },
        {
          title: 'Introduction to Cybersecurity',
          duration: '12 hrs 30 mins',
          pace: 'Self-Paced',
          lectures: 40,
          rating: '4.6',
          reviews: '3K',
          imageUrl: '/home-banner/features.png', 
        },
        {
          title: 'Introduction to Cryptocurrency Trading',
          duration: '12 hrs 30 mins',
          pace: 'Self-Paced',
          lectures: 40,
          rating: '4.6',
          reviews: '3K',
          imageUrl: '/home-banner/features.png',
        },
      ];
    
      return (
        <div className="w-full px-4 sm:px-6 lg:px-8 py-6 shadow-lg shadow-gray-500/50 ">
          <div className="flex flex-col md:flex-row justify-between items-center mb-6">
            <h2 className="text-3xl md:text-4xl font-bold text-[#3A8BC3] lg:mt-10 mb-4 md:mb-0">
              Featured Courses
            </h2>
            <button className="bg-[#3A8BC3] text-white font-semibold py-2 px-4 lg:mt-10 rounded hover:bg-blue-700">
              View All
            </button>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-12">
            {courses.map((course, index) => (
              <CourseCard
                key={index}
                title={course.title}
                duration={course.duration}
                pace={course.pace}
                lectures={course.lectures}
                rating={course.rating}
                reviews={course.reviews}
                imageUrl={course.imageUrl}
              />
            ))}
          </div>
        </div>
      );
}

export default FeaturedCourses