import axios from "axios";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../hooks/useAuth";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import Modal from "react-modal";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const redirectUri =
  "https://chain-edge-front-new.vercel.app/auth/google/callback";

const Login = () => {
  let navigate = useNavigate();
  const base_url = process.env.REACT_APP_BASE_URL;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { setIsLoggedIn } = useContext(AuthContext);
  const [role, setRole] = useState(""); 
  const [isRoleModalOpen, setRoleModalOpen] = useState(false);
  const [googleCredential, setGoogleCredential] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
    setEmailError("");
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
    setPasswordError("");
  };

  const validateForm = () => {
    let valid = true;

    if (email.trim() === "") {
      setEmailError("Email cannot be blank");
      valid = false;
    }

    if (password.trim() === "") {
      setPasswordError("Password cannot be blank");
      valid = false;
    }

    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);

      const formData = new URLSearchParams();
      formData.append("email", email);
      formData.append("password", password);

      axios
        .post(`${base_url}/login`, formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            accept: "application/json",
          },
        })
        .then((response) => {
          setLoading(false);
          const success = response.data.success;
          const message = response.data.message;
          if (success) {
            const token = response.data.responseData.token;
            const userType = response.data.responseData.userType;
            const role = response.data.responseData.role;
            localStorage.setItem("token", token);
            localStorage.setItem("userType", userType);
            localStorage.setItem("email", email);
            localStorage.setItem("role", role); 

            setIsLoggedIn(true);

            if (role === "Student") {
              navigate("/courses");
            } else if (role === "Coordinator") {
              navigate("/instructor/dashboard");
            } else if (role === "Admin") {
              navigate("/admin/dashboard");
            } else {
              navigate("/")
            }
          } else {
            console.error("Login failed:", message || "Unknown error");
            setMessage(message || "Unknown error");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Login failed:", error);
          setMessage("Login failed. Please try again later.");
        });
    }
  };

  const handleGoogleSuccess = (response) => {
    const userObject = jwtDecode(response.credential);
    console.log("Google User Info:", userObject);

    
    setRoleModalOpen(true);

    
    setMessage("");
    setRoleModalOpen(true);
    setLoading(false);
    setMessage("");
    setGoogleCredential(response.credential);
  };

  const handleGoogleError = () => {
    console.error("Google login failed");
    setMessage("Google login failed. Please try again.");
  };

  const handleRoleSubmit = () => {
    // Close role selection modal and proceed with API call
    setRoleModalOpen(false);
    if (role) {
      // Send Google credential and selected role to backend
      axios
        .post(`${base_url}/google-signin`, {
          credential: googleCredential,
          role: role,
        })
        .then((res) => {
          console.log("Google Login Success:", res);
          const { token, userType } = res.data;
          localStorage.setItem("token", token);
          localStorage.setItem("userType", userType);
          setIsLoggedIn(true);
          navigate("/");
        })
        .catch((error) => {
          console.error("Google login failed:", error);
          setMessage("Google login failed. Please try again.");
        });
    } else {
      setMessage("Please select a role.");
    }
  };

  return (
    <>
      <div className="flex min-h-screen">
        <div className="flex w-full flex-col md:flex-row">
          {/* Image */}

          {/* Login Section */}
          <div className="flex flex-col md:flex-1 items-center justify-center  ">
            <div className="loginWrapper flex flex-col lg:px-36 md:px-8 px-8 md:py-8 border-2 border-[#2d81ba] rounded-3xl bg-white ">
              {/* Login Header Text */}
              <div className="items-center text-center flex flex-col relative justify-center mx-auto mb-10">
                <img
                  src="/home-banner/logo.png"
                  alt="Logo Login"
                  className="md:w-80 w-64 mx-auto"
                />
              </div>
              <div className="hidden md:block font-medium self-center text-xl sm:text-3xl text-gray-800">
                Login
              </div>

              <div className="md:hidden block my-4">
                <h1 className="text-2xl font-semibold">Login</h1>
              </div>

              {/* Google Auth */}

              <div className="mt-4 mb-6 self-center">
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleError}
                />
              </div>

              {/* Role Selection Modal */}
              {isRoleModalOpen && (
                <Modal
                  isOpen={isRoleModalOpen}
                  onRequestClose={() => setRoleModalOpen(false)}
                  contentLabel="Select Role"
                  className="fixed inset-0 flex items-center justify-center z-50"
                  overlayClassName="fixed inset-0 bg-black bg-opacity-50"
                >
                  <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-1/3">
                    <h2 className="text-xl font-semibold mb-4">
                      Select Your Role
                    </h2>
                    <select
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      className="w-full p-2 mb-4 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <option value="">Select Role</option>
                      <option value="Student">Student</option>
                      <option value="Coordinator">Coordinator</option>
                      <option value="Admin">Admin</option>
                    </select>
                    <button
                      onClick={handleRoleSubmit}
                      className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    >
                      Submit
                    </button>
                  </div>
                </Modal>
              )}

              {/* Login Form */}
              <div className="md:mt-10 mt-4">
                <form onSubmit={handleSubmit}>
                  {/* Email */}
                  <div className="flex flex-col mb-3">
                    <div className="relative">
                      <span className="text-sm">Email ID</span>
                      <input
                        id="email"
                        type="text"
                        name="email"
                        onChange={onChangeEmail}
                        className="text-sm placeholder-gray-500 pl-2 pr-4 rounded-lg border border-gray-400 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400"
                        placeholder="Enter Your Email ID"
                      />
                    </div>
                    {emailError && (
                      <span className="text-red-500 text-sm mt-1 ml-1">
                        {emailError}
                      </span>
                    )}
                  </div>
                  {/* Password */}
                  <div className="flex flex-col mb-6">
                    <div className="relative">
                      <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400"></div>
                      <span className="text-sm">Password</span>
                      <input
                        id="password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={onChangePassword}
                        className="text-sm placeholder-gray-500 pl-2 pr-10 rounded-lg border border-gray-400 w-full md:py-2 py-3 focus:outline-none focus:border-emerald-400"
                        placeholder="Enter Your Password"
                      />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="absolute right-0 top-0 mt-2 mr-2 focus:outline-none"
                      ></button>
                    </div>
                    {passwordError && (
                      <span className="text-red-500 text-sm mt-1 ml-1">
                        {passwordError}
                      </span>
                    )}
                    {message && (
                      <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                        {message}
                      </span>
                    )}
                  </div>

                  {/* Forgot Password Link */}
                  <div className="flex items-center justify-between mb-6 -mt-2 md:-mt-4">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        type="checkbox"
                        className="h-4 w-4 text-emerald-500 focus:ring-emerald-400 border-gray-300 rounded"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-2 block text-sm text-gray-900"
                      >
                        Remember me
                      </label>
                    </div>
                    <div className="flex ml-auto">
                      <Link
                        to=""
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        className="inline-flex font-semibold text-xs sm:text-sm text-emerald-500 hover:text-emerald-700"
                      >
                        forgot password?
                      </Link>
                    </div>
                  </div>

                  {/* Button Login */}
                  <div className="flex w-full justify-center">
                    <button
                      disabled={loading}
                      type="submit"
                      className="flex items-center justify-center focus:outline-none text-white text-sm bg-[#2d81ba] rounded-full md:py-2 py-3 w-1/2 transition duration-150 ease-in"
                    >
                      <span className="mr-2 md:uppercase">
                        {loading ? "Processing...." : "Login"}
                      </span>
                    </button>
                  </div>
                </form>
              </div>
              {/* Register Link */}
              <div className="flex justify-center items-center  my-6 md:mb-0">
                <Link
                  to="/signup"
                  className="inline-flex items-center font-bold text-emerald-500 hover:text-emerald-700 text-xs text-center"
                >
                  <span>
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"></path>
                    </svg>
                  </span>
                  <span className="ml-2">Signup ?</span>
                </Link>
              </div>
              <div className="flex justify-center items-center  my-6 md:mb-0"></div>
            </div>
          </div>
          <div className="md:bg-[#2d81ba] md:min-h-screen flex flex-wrap md:w-1/2">
            <div className="items-center text-center flex flex-col relative justify-center mx-auto">
              <img
                src="/images/login.svg"
                alt="Logo Login"
                className="md:w-96 w-48 mx-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
