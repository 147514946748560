import React from "react";
import axios from "axios";

const base_url = process.env.REACT_APP_BASE_URL;

const EventCard = ({
  id,
  title,
  date,
  description,
  imageUrl,
  tag,
  tagColor = "bg-green-100 text-green-800",
  buttonText,
  buttonLink,
  userId,
}) => {
  const token = localStorage.getItem("token");

  const handleRegisterClick = async (e, webinarId) => {
    e.preventDefault(); // Prevent default behavior for the button click

    try {
      const response = await axios.get(
        `${base_url}/v0/webinar/register?webinarId=${webinarId}`,
        {
          headers: {
            token: `${token}`,
          },
        }
      );

      if (response.data.success) {
        console.log("Registration Success:", response.data);
        alert("Successfully registered for the webinar!");
      } else {
        console.error("Failed to register:", response.statusText);
        alert("Failed to register.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error registering for the webinar.");
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden max-w-xs mx-auto flex flex-col h-full">
      <img className="w-full h-48 object-cover" src={imageUrl} alt={title} />
      <div className="p-4 flex flex-col justify-between flex-grow">
        <div className="flex-grow">
          <span className={`inline-block mb-4 px-2.5 py-1 rounded ${tagColor}`}>
            {tag}
          </span>
          <h3
            className="text-lg font-bold mb-2 line-clamp-2"
            style={{ minHeight: "48px" }}
          >
            {title}
          </h3>
          <p
            className="text-gray-600 text-sm mb-2 line-clamp-3"
            style={{ minHeight: "50px" }}
          >
            {description}
          </p>
          <p className="text-gray-500 text-xs mb-2">{date}</p>
        </div>
        {/* Register Now button with API integration */}
        <button
          onClick={(e) => handleRegisterClick(e, id)}
          className="text-blue-600 font-medium mt-auto inline-block self-start"
        >
          {buttonText} &rarr;
        </button>
      </div>
    </div>
  );
};

export default EventCard;
