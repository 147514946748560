import React, { useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import AddQuiz from "./AddQuiz";
import AddAnnouncements from "./AddAnnouncement";
import axios from "axios";
import toast from "react-hot-toast";

const AddLesson = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    lessonDuration: "",
    section: "",
    order: "",
    videoFile: null,
    studyMaterial: null,
    videoThumbnail: null,
    attachment: null,
    hasAnnouncement: false,
    announcement: "",
    hasQuiz: false,
    quizQuestions: [],
  });

  const [loading, setLoading] = useState(false);
  const base_url = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const [videoFileName, setVideoFileName] = useState("");
  const [studyMaterialFileName, setStudyMaterialFileName] = useState("");
  const [videoThumbnailFileName, setVideoThumbnailFileName] = useState("");
  const [attachmentFileName, setAttachmentFileName] = useState("");

  const handleChange = (event) => {
    const { name, value, type, files, checked } = event.target;

    if (type === "file") {
      if (files && files.length > 0) {
        const fileName = files[0].name;
        setFormData((prevState) => ({
          ...prevState,
          [name]: files[0], 
        }));

        switch (name) {
          case "videoFile":
            setVideoFileName(fileName);
            break;
          case "studyMaterial":
            setStudyMaterialFileName(fileName);
            break;
          case "videoThumbnail":
            setVideoThumbnailFileName(fileName);
            break;
          case "attachment":
            setAttachmentFileName(fileName);
            break;
          default:
            break;
        }
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const handleRemove = (fileType) => {
    switch (fileType) {
      case "videoFile":
        setVideoFileName("");
        break;
      case "studyMaterial":
        setStudyMaterialFileName("");
        break;
      case "videoThumbnail":
        setVideoThumbnailFileName("");
        break;
      case "attachment":
        setAttachmentFileName("");
        break;
      default:
        break;
    }
  };
  const handleQuizData = (quizData) => {
    setFormData((prev) => ({
      ...prev,
      quizQuestions: quizData,
    }));
  };
  const handleQuizChange = (quizVisible) => {
    setFormData((prev) => ({
      ...prev,
      hasQuiz: quizVisible,
    }));
  };
  const handleAnnouncement = (announcementData) => {
    setFormData((prev) => ({
      ...prev,
      announcement: announcementData,
    }));
  };
  const handleAnnouncementChange = (announcementChange) => {
    setFormData((prev) => ({
      ...prev,
      hasAnnouncement: announcementChange,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append("courseId", id);
    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("lessonDuration", formData.lessonDuration);
    formDataToSend.append("section", formData.section);
    formDataToSend.append("order", formData.order);
    formDataToSend.append("hasAnnouncement", formData.hasAnnouncement);
    if (formData.hasAnnouncement) {
      formDataToSend.append("announcement", formData.announcement);
    }
    formDataToSend.append("hasQuiz", formData.hasQuiz);
    if (formData.hasQuiz) {
      formDataToSend.append(
        "quizQuestions",
        JSON.stringify(formData.quizQuestions)
      );
    }
    formDataToSend.append("videoFile", formData.videoFile);
    formDataToSend.append("videoThumbnail", formData.videoThumbnail);
    formDataToSend.append("studyMaterial", formData.studyMaterial);
    formDataToSend.append("attachment", formData.attachment);

    try {
      const response = await axios.post(
        `${base_url}/add-lesson`,
        formDataToSend,
        {
          headers: {
            accept: "application/json",
            token: token,
          },
        }
      );
      toast("Lesson added successfully")
      console.log(response.data);
      navigate("/instructor/courses");
    } catch (error) {
      toast("Failed to add the lesson. Please try again")
      console.error("Error:", error);
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-4 md:p-8 bg-white text-[#9A9A9A]">
      <h1 className="text-center text-xl md:text-2xl font-semibold mb-4 md:mb-6 underline text-[#686868]">
        Add Lessons
      </h1>

      {/* Add Lesson Form */}
      <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 mb-6 md:mb-8">
        <div>
          <label className="block mb-1 md:mb-2">Title</label>
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="border p-2 w-full"
          />
        </div>

        <div className="md:col-span-2 flex flex-col md:flex-row gap-4">
          <div className="flex-1">
            <label className="block mb-1 md:mb-2">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="border p-2 w-full h-11"
              rows={2}
            />
          </div>
          <div className="flex-1">
            <label className="block mb-1 md:mb-2">Lesson Duration</label>
            <input
              name="lessonDuration"
              value={formData.lessonDuration}
              onChange={handleChange}
              className="border p-2 w-full"
            />
          </div>
        </div>

        {/* Video File Upload */}
        <div>
          <label className="block mb-1 md:mb-2">Video File</label>
          <div className="flex items-center">
            <input
              type="file"
              className="hidden"
              id="videoFile"
              name="videoFile"
              onChange={handleChange}
            />
            <label
              htmlFor="videoFile"
              className="flex-1 border border-gray-300 rounded-l p-2 cursor-pointer text-sm"
            >
              {videoFileName ? (
                <div className="flex justify-between items-center">
                  <span>{videoFileName}</span>
                  <button
                    type="button"
                    className="ml-2 text-red-500"
                    onClick={() => handleRemove("videoFile")}
                  >
                    &times;
                  </button>
                </div>
              ) : (
                "Upload File"
              )}
            </label>
            <label
              htmlFor="videoFile"
              className="bg-[#EAEAEA] text-[#707070] rounded-r px-4 py-2 cursor-pointer"
            >
              Choose File
            </label>
          </div>
        </div>

        {/* Study Material Upload */}
        <div>
          <label className="block mb-1 md:mb-2">Study Material</label>
          <div className="flex items-center">
            <input
              type="file"
              className="hidden"
              id="studyMaterial"
              name="studyMaterial"
              onChange={handleChange}
            />
            <label
              htmlFor="studyMaterial"
              className="flex-1 border border-gray-300 rounded-l p-2 cursor-pointer text-sm"
            >
              {studyMaterialFileName ? (
                <div className="flex justify-between items-center">
                  <span>{studyMaterialFileName}</span>
                  <button
                    type="button"
                    className="ml-2 text-red-500"
                    onClick={() => handleRemove("studyMaterial")}
                  >
                    &times;
                  </button>
                </div>
              ) : (
                "Upload File"
              )}
            </label>
            <label
              htmlFor="studyMaterial"
              className="bg-[#EAEAEA] text-[#707070] rounded-r px-4 py-2 cursor-pointer"
            >
              Choose File
            </label>
          </div>
        </div>

        {/* Video Thumbnail Upload */}
        <div>
          <label className="block mb-1 md:mb-2">Video Thumbnail</label>
          <div className="flex items-center">
            <input
              type="file"
              className="hidden"
              id="videoThumbnail"
              name="videoThumbnail"
              onChange={handleChange}
            />
            <label
              htmlFor="videoThumbnail"
              className="flex-1 border border-gray-300 rounded-l p-2 cursor-pointer text-sm"
            >
              {videoThumbnailFileName ? (
                <div className="flex justify-between items-center">
                  <span>{videoThumbnailFileName}</span>
                  <button
                    type="button"
                    className="ml-2 text-red-500"
                    onClick={() => handleRemove("videoThumbnail")}
                  >
                    &times;
                  </button>
                </div>
              ) : (
                "Upload File"
              )}
            </label>
            <label
              htmlFor="videoThumbnail"
              className="bg-[#EAEAEA] text-[#707070] rounded-r px-4 py-2 cursor-pointer"
            >
              Choose File
            </label>
          </div>
        </div>

        {/* Attachment Upload */}
        <div>
          <label className="block mb-1 md:mb-2">Attachment</label>
          <div className="flex items-center">
            <input
              type="file"
              className="hidden"
              id="attachment"
              name="attachment"
              onChange={handleChange}
            />
            <label
              htmlFor="attachment"
              className="flex-1 border border-gray-300 rounded-l p-2 cursor-pointer text-sm"
            >
              {attachmentFileName ? (
                <div className="flex justify-between items-center">
                  <span>{attachmentFileName}</span>
                  <button
                    type="button"
                    className="ml-2 text-red-500"
                    onClick={() => handleRemove("attachment")}
                  >
                    &times;
                  </button>
                </div>
              ) : (
                "Upload File"
              )}
            </label>
            <label
              htmlFor="attachment"
              className="bg-[#EAEAEA] text-[#707070] rounded-r px-4 py-2 cursor-pointer"
            >
              Choose File
            </label>
          </div>
        </div>

        <div>
          <label className="block mb-1 md:mb-2">Select Section</label>
          <select
            name="section"
            value={formData.section}
            onChange={handleChange}
            className="border p-2 w-full"
          >
            <option value="">Select a Section</option>
            <option value="Section 1">Section 1</option>
            <option value="Section 2">Section 2</option>
            <option value="Section 3">Section 3</option>
          </select>
        </div>

        <div>
          <label className="block mb-1 md:mb-2">Select Order</label>
          <input
            type="number"
            name="order"
            value={formData.order}
            onChange={handleChange}
            className="border p-2 w-full"
          />
        </div>

        <div className="md:col-span-2 flex flex-col space-y-4">
          <AddQuiz
            onQuizDataChange={handleQuizData}
            handleChange={handleQuizChange}
          />
          <AddAnnouncements
            handleAnnouncement={handleAnnouncement}
            handleChange={handleAnnouncementChange}
          />
        </div>

        <div className="md:col-span-2 flex justify-end space-x-4 mt-4">
          <Link to="/instructor/courses">
          <button type="button" className="bg-gray-100 text-gray-600 px-6 py-2 rounded-md">
            Cancel
          </button>
          </Link>
          <button
            type="submit"
            className={`bg-[#3A8BC3] text-white px-6 py-2 rounded-md hover:bg-blue-600 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={loading}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddLesson;
