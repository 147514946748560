import React, { useState, useEffect } from "react";

const AddAnnouncement = ({ handleAnnouncement, handleChange }) => {
  const [isAnnouncementVisible, setIsAnnouncementVisible] = useState(false);
  const [announcementText, setAnnouncementText] = useState("");

  const handleSwitchChange = () => {
    setIsAnnouncementVisible((prev) => !prev);
  };

  useEffect(() => {
    if (announcementText.trim()) {
      handleChange(isAnnouncementVisible);
      handleAnnouncement(announcementText);
    }
  }, [announcementText, handleAnnouncement, handleChange]);

  return (
    <div className="mt-8">
      <div className="flex items-center mb-4 gap-2">
        <label className="flex items-center cursor-pointer">
          <input
            type="checkbox"
            checked={isAnnouncementVisible}
            onChange={handleSwitchChange}
            className="sr-only"
          />
          <div
            className={`w-10 h-6 rounded-full relative transition-colors duration-300 ${
              isAnnouncementVisible ? "bg-[#3A8BC3]" : "bg-gray-300"
            }`}
          >
            <div
              className={`w-4 h-4 bg-white rounded-full absolute top-1 transition-transform duration-300 ${
                isAnnouncementVisible ? "translate-x-5" : "translate-x-1"
              }`}
            />
          </div>
        </label>

        <h2 className="text-lg">Add Announcement</h2>
      </div>

      {isAnnouncementVisible && (
        <textarea
          rows="5"
          className="w-full border rounded-md p-2 mb-4 focus:outline-[#3A8BC3] text-sm sm:text-base"
          placeholder="Write your announcement here..."
          value={announcementText}
          onChange={(e) => setAnnouncementText(e.target.value)}
        ></textarea>
      )}
    </div>
  );
};

export default AddAnnouncement;
