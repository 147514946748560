import React, { useState, useContext, useRef, useEffect} from "react";
import { TbLogout } from 'react-icons/tb';
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from '../../hooks/useAuth'; //


const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const {isLoggedIn, setIsLoggedIn} = useContext(AuthContext)

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    localStorage.removeItem('role');
    localStorage.removeItem("accessToken");
    setIsLoggedIn(false);
    navigate("/");
  };

  const handleProfileClick = () => {
    // Perform any profile related action here
    // For example, navigate to the profile page or show profile details
    console.log("Profile clicked");
    setDropdownOpen(false); // Close the dropdown after performing action
  };

  const handleSettingsClick = () => {
    // Perform any settings related action here
    console.log("Settings clicked");
    setDropdownOpen(false); // Close the dropdown after performing action
  };

  return (
    <div className="admin-page flex flex-col lg:flex-row">
      <div className="flex-1 flex flex-col">
        <div className="bg-white p-4 flex items-center justify-between">
          <div className="relative flex items-center space-x-2 ml-auto">
            <i className="fas fa-bell text-gray-500 text-xl mr-4"></i>
            <div className="relative">
              <div
                className="flex items-center space-x-2 cursor-pointer"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <img
                  src="/images/admin.png"
                  alt="Profile"
                  className="w-12 h-12 rounded-full object-cover"
                />
                <span>Admin</span>
                <button className="p-2 rounded-full">
                  <i className="fas fa-chevron-down"></i>
                </button>
              </div>

              {dropdownOpen && (
                <div
                ref={dropdownRef}
                className="absolute right-0 top-full mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
                  <Link
                    to="#"
                    onClick={handleProfileClick}
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    Profile
                  </Link>
                  <Link
                    to="#"
                    onClick={handleSettingsClick}
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    Settings
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
