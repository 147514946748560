import React from 'react';
import EventCard from './eventCard';

const pastWebinars = [
  {
    title: 'Masterclass: Introduction to Data Science',
    date: 'Abhishek Singh | Senior Data Analyst, Course Hero',
    description: 'Introduction to Data Science and Machine Learning',
    imageUrl: '/images/insight1.png',
    tag: 'Data Science',
    tagColor: 'bg-[#3EAA43] text-white',
    buttonText: 'VIEW RECORDING',
    buttonLink: '#',
  },
  {
    title: 'Masterclass: AI and ML',
    date: 'Abhishek Singh | Senior Data Analyst, Course Hero',
    description: 'Introduction to AI and Machine Learning',
    imageUrl: '/images/insight2.png',
    tag: 'AI and ML',
    buttonText: 'VIEW RECORDING',
    tagColor: 'bg-[#3EAA90] text-white',
    buttonLink: '#',
  },
  {
    title: 'Masterclass: Python Programming',
    date: 'Abhishek Singh | Senior Data Analyst, Course Hero',
    description: 'Introduction to Python Programming',
    imageUrl: '/images/insight3.png',
    tag: 'Python',
    tagColor: 'bg-[#3E7DAA] text-white',
    buttonText: 'VIEW RECORDING',
    buttonLink: '#',
  },
  // More past webinars...
];

const WebinarsSection = () => {
  return (
    <section className="my-12">
      <h2 className="text-3xl font-bold text-center lg:mb-14 mb-8">Insightful Past Webinars</h2>
      <div className="flex justify-center items-center mb-8">
        <h3 className="text-3xl text-[#3A8BC3] text-center pb-2 border-b-2 border-[#3A8BC3] inline-block">Masterclass</h3>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {pastWebinars.map((webinar, index) => (
          <EventCard key={index} {...webinar} />
        ))}
      </div>
    </section>
  );
};

export default WebinarsSection;
