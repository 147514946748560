// CurveChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const data = {
  labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
  datasets: [
    {
      label: 'Deposits',
      data: [12, 19, 3, 5, 2, 3, 10], 
      backgroundColor: 'rgba(24, 20, 243)', 
      borderWidth: 1,
      barThickness: 15, 
      borderRadius: {
        topLeft: 10,    
        topRight: 10,   
        bottomLeft: 10, 
        bottomRight: 10 
      },
    },
    {
      label: 'Withdrawals',
      data: [10, 15, 5, 8, 3, 4, 6], 
      backgroundColor: 'rgba(22, 219, 204)', 
      borderWidth: 1,
      barThickness: 15,
      borderRadius: {
        topLeft: 10,    
        topRight: 10,  
        bottomLeft: 10, 
        bottomRight: 10 
      },
    },
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false, // Allow the chart to expand to fill the container
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Weekly Activity Comparison',
    },
  },
  scales: {
    x: {
      stacked: false, 
      grid: {
        display: false, 
      },
      barPercentage: 0.4, 
      categoryPercentage: 0.5, 
    },
    y: {
      beginAtZero: true,
      grid: {
        color: 'rgba(0, 0, 0, 0.1)', 
      },
    },
  },
};

const CurvedBarChart = () => {
  return (
    <div className="w-full h-64 md:h-80 lg:h-96"> {/* Set height dynamically based on screen size */}
      <Bar data={data} options={options} />
    </div>
  );
};

export default CurvedBarChart;
