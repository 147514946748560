import React from 'react'
import { Grid } from '@mui/material';

const data = [
    {
        title: "Job Assistance",
        desc: "Program"
    },
    {
        title: "30th August 2024",
        desc: "Date Of Commencement"
    },
    {
        title: "6 Months",
        desc: "Duration"
    },
    {
        title: "Live + Recorded",
        desc: "Delivery Mode"
    },
    {
        title: "Hindi/English",
        desc: "Language"
    },
]
const CourseDetails = () => {
    const filledStars = 5;
    const totalStars = 6;
    const emptyStars = totalStars - filledStars;
    return (

        <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
                <div className='flex flex-col gap-y-10'>
                    <h2 className='font-semibold text-3xl'>
                        UI/UX Design Course
                    </h2>
                    <p className='font-bold text-lg'>Become a certified UI/UX designer and give your career a head start with the PW Skills UI/UX upskilling program. Uncover the theory and methodologies needed to create a well-coordinated software design.</p>
                    <button className="w-fit bg-[#3A8bc3] hover:greenblue text-xs lg:text-sm text-white font-bold py-2 px-6 rounded-md focus:outline-none focus:shadow-outline">Resume</button>
                    <p className='font-bold text-sm flex items-center gap-2'>
                        {/* Render filled stars */}
                        {Array(filledStars).fill(null).map((_, index) => (
                            <img key={index} className='w-5' src="/images/filledStar.svg" alt="filled star" />
                        ))}
                        {/* Render empty stars */}
                        {Array(emptyStars).fill(null).map((_, index) => (
                            <img key={index + filledStars} className='w-5' src="/images/emptyStar.svg" alt="empty star" />
                        ))}
                        (6,241 Ratings)
                    </p>                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <span className='font-bold text-sm flex items-center gap-2'><img className='w-5' src="/images/updateIcon.svg" alt="tick" />Last updated on 6/2024 </span>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <span className='font-bold text-sm flex items-center gap-2'><img className='w-5' src="/images/languageIcon.svg" alt="tick" />English, Hindi </span>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <span className='font-bold text-sm flex items-center gap-2'><img className='w-5' src="/images/ccIcon.svg" alt="tick" />English </span>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <img src="/images/bannerDetail.png" alt="img" />
            </Grid>
            <Grid item xs={12}>
                <div className='py-10 px-5 flex justify-center rounded-md shadow-custom-even'>
                    <Grid container spacing={2} justifyContent={"center"}>
                        {data.map((data, i) => (
                            <Grid item xs={12} sm={6} md={4} lg={2} key={i}>
                                <p className='font-bold text-lg'>{data.title}</p>
                                <p style={{ color: "grey" }} className='font-bold text-sm '>{data.desc}</p>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Grid>
        </Grid>

    )
}

export default CourseDetails;