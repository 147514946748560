import React from 'react'
import "./homePage.css"
import Hero from './Hero'
import Blogs from './Blogs'
import GetHiredBy from './GetHiredBy'
import Goals from './Goals'
import LearningModel from './LearningModel'
import FeaturedCourses from './FeaturedCourses'
import HandsOnProject from './HandsOnProject'
import Excellence from './Excellence'
import WorldMap from './WorldMap'
import Testimonials from './Testimonials'
import Contact from './Contact'
import Team from './Team'

const Home = () => {
  return (
    <div className='px-3 md:px-10 overflow-x-hidden'>
        <Hero />
        <Blogs />
        <GetHiredBy />
        <Goals />
        <LearningModel/>
        <Team />
        <FeaturedCourses />
        <HandsOnProject />
        <Excellence />
        <WorldMap />
        <Testimonials />
        <Contact />
    </div>
  )
}

export default Home