import React, { useState, useEffect, useCallback } from "react";
import CourseCategory from "./CourseCategory";
import axios from "axios";
import toast from "react-hot-toast";
import debounce from "lodash.debounce"; 

const Courses = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [courses, setCourses] = useState([]);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [searchResults, setSearchResults] = useState([]); 
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);

  useEffect(() => {
    fetchCourses();
    fetchEnrolledCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${baseUrl}/get-courses`, {
        headers: { token },
      });
      setCourses(response.data.responseData);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  const fetchEnrolledCourses = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${baseUrl}/get-enrolled-courses`, {
        headers: { token },
      });
      setEnrolledCourses(response.data.responseData);
    } catch (error) {
      console.error("Error fetching enrolled courses:", error);
    }
  };

  const searchCourses = async (query) => {
    if (!query.trim()) {
      setIsSearchActive(false); 
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${baseUrl}/search-course?name=${query}`,
        { headers: { token } }
      );
      const results = response.data.responseData;
      setSearchResults(results); 
      setIsSearchActive(true); 
    } catch (error) {
      console.error("Error searching courses:", error);
      toast.error("Failed to fetch search results.");
    }
  };

 
  const debouncedSearch = useCallback(
    debounce((query) => searchCourses(query), 500), 
    []
  );

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    debouncedSearch(query); 
  };

  const resetSearch = () => {
    setSearchTerm(""); 
    setSearchResults([]);
    setIsSearchActive(false); 
    fetchCourses(); 
  };

  const addToEnrolledCourses = (course) => {
    setEnrolledCourses((prev) => [...prev, course]);
  };

  return (
    <div className="w-full flex flex-col mt-10 mb-36 px-10 xl:px-20">
     
      <div className="w-full flex justify-center">
        <div className="px-4 w-full max-w-[700px]">
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
              >
                <path
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  strokeWidth="2"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  stroke="currentColor"
                ></path>
              </svg>
            </div>
            <input
              value={searchTerm}
              onChange={handleInputChange}
              placeholder="Search for courses"
              className="block w-full p-4 py-2 rounded-full ps-10 text-lg text-gray-900 border border-gray-300 bg-gray-50 focus:ring-blue-500 outline-none focus:border-blue-500"
              type="search"
            />
          </div>
          {isSearchActive && (
            <button
              type="button"
              onClick={resetSearch}
              className="mt-2 bg-gray-300 text-black font-semibold py-2 px-6 rounded hover:bg-gray-400"
            >
              Reset
            </button>
          )}
        </div>
      </div>

      {isSearchActive ? (
        <div className="w-full mt-32">
          <CourseCategory
            title={"Search Results"}
            data={searchResults}
            addToEnrolledCourses={addToEnrolledCourses}
          />
        </div>
      ) : (
        <>
          <div className="w-full mt-32">
            <CourseCategory title={"Enrolled Courses"} data={enrolledCourses} />
          </div>
          <div className="w-full mt-20">
            <CourseCategory
              title={"Recommended For You"}
              data={courses}
              addToEnrolledCourses={addToEnrolledCourses}
            />
          </div>
          <div className="w-full mt-32">
            <CourseCategory
              title={"Trending Courses"}
              data={courses}
              addToEnrolledCourses={addToEnrolledCourses}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Courses;
