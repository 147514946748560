import React from 'react'
import { FaUserAlt } from 'react-icons/fa'
import { GoClockFill } from 'react-icons/go'
import { IoIosStar } from 'react-icons/io'
import { MdOutlineVideoLibrary } from 'react-icons/md'

const CourseCard = ({
    title,
    duration,
    pace,
    lectures,
    rating,
    reviews,
    imageUrl,
  }) => {
  return (
    <div className="max-w-xs md:max-w-sm pb-5 lg:max-w-md xl:max-w-lg rounded-2xl overflow-hidden shadow-lg shadow-gray-400 my-4 flex flex-col justify-between">
      <img className="w-full h-44 object-cover" src={imageUrl} alt={title} />
      <div className="px-4 py-4 flex-grow">
        <h3 className="font-bold text-lg md:text-xl text-[#3C3C3C] text-center mb-2">
          {title}
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <p className="text-gray-600 text-sm flex items-center">
            <span className="mr-1 text-[#3A8BC3]"><GoClockFill/></span>
            {duration}
            
          </p>
          <p className="text-gray-600 text-sm flex items-center">
            <span className="mr-1 text-[#3A8BC3]"><FaUserAlt/></span>
            {pace}
          </p>
          <p className="text-gray-600 text-sm flex items-center">
            <span className="mr-1 text-[#3A8BC3]"><MdOutlineVideoLibrary/></span>
            {lectures} Lectures
          </p>
          <p className="text-gray-600 text-sm flex items-center">
            <span className="mr-1 text-[#3A8BC3]"><IoIosStar/></span>
            {rating} ({reviews} reviews)
          </p>
        </div>
      </div>
      <div className="px-4 py-2 flex flex-col md:flex-row justify-between">
        <button className="bg-white text-[#646464] font-semibold py-2 px-4 border border-[#646464] rounded hover:bg-[#646464] hover:text-white mb-2 md:mb-0">
          View More
        </button>
        <button className="bg-[#3A8BC3] text-white font-semibold py-2 px-4 border border-[#3A8BC3] rounded hover:bg-blue-700">
          Enroll Now
        </button>
      </div>
    </div>
  )
}

export default CourseCard