import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const menuItems = [
    
    {
        title: "TAships",
        link: "/internship",
    },

    {
        title: "Eligible",
        link: "/internship/eligible",
    },

    {
        title: "Applied",
        link: "/internship/applied",
    },
   
]

const TopBanner = () => {
    const location = useLocation();
    const pathname = location.pathname;
  return (
    <div className='w-full flex my-20 justify-center gap-40'>
        {menuItems.map((item,i) => (
            <Link to={item.link} className={` text-lg ${pathname === item.link ? "text-[#2d81ba] border-b-2 border-b-[#2d81ba]" : "text-gray-500 border-b-0 hover:text-gray-800"}  font-semibold `}>
                {item.icon}
                {item.title}
            </Link>
        ))}
    </div>
  )
}

export default TopBanner;