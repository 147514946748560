import React from 'react'
import ChainEdgeSection from './ChainEdgeSection'
import FeatureRow from './FeatureRow'

const LearningModel = () => {
  return (
    <div className="w-full p-4 md:p-6 lg:p-8 shadow-lg shadow-gray-500/50 mb-10">
      <ChainEdgeSection />

      {/* Headings for all views */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 font-bold text-center mb-6">
        {/* Empty placeholder for the first column */}
        <div></div>

        {/* Logo column */}
        <div className="flex justify-center items-center mb-4 md:mb-0">
          <img
            src="/home-banner/logo.png"
            alt="ChainEdge Logo"
            className="w-auto h-12 md:h-16"
          />
        </div>

        {/* Free Resources column */}
        <div className="flex justify-center items-center text-base md:text-lg font-bold text-[#525252] mb-4 md:mb-0">
          <p>Free Resources</p>
        </div>

        {/* Other Courses column */}
        <div className="flex justify-center items-center text-base md:text-lg font-bold text-[#525252]">
          <p>Other Courses</p>
        </div>
      </div>

      {/* Table layout for mobile view */}
      <div className="block md:hidden w-full overflow-x-auto">
        <table className="table-auto w-full text-center">
          <thead>
            <tr>
              <th></th>
              <th className="font-bold text-base">Chain Edge</th>
              <th className="font-bold text-base">Free Resources</th>
              <th className="font-bold text-base">Other Courses</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="font-bold text-left">Structured + problem-solving based</td>
              <td className="text-center">
                <img src="/home-banner/logo.png" alt="ChainEdge Logo" className="w-auto h-8 mx-auto" />
                ✔️
              </td>
              <td className="text-center">❌</td>
              <td className="text-center">✔️</td>
            </tr>
            <tr>
              <td className="font-bold text-left">Fastest 1:1 doubt support</td>
              <td className="text-center">✔️</td>
              <td className="text-center">❌</td>
              <td className="text-center">❌</td>
            </tr>
            <tr>
              <td className="font-bold text-left">Integrated prep platform</td>
              <td className="text-center">✔️</td>
              <td className="text-center">❌</td>
              <td className="text-center">❌</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* FeatureRow for larger screens */}
      <div className="hidden md:block">
        <FeatureRow
          feature="Structured + problem-solving based"
          isChainEdgeAvailable={true}
          isFreeResourceAvailable={false}
          isOtherCourseAvailable={true}
        />
        <FeatureRow
          feature="Fastest 1:1 doubt support"
          isChainEdgeAvailable={true}
          isFreeResourceAvailable={false}
          isOtherCourseAvailable={false}
        />
        <FeatureRow
          feature="Integrated prep platform"
          isChainEdgeAvailable={true}
          isFreeResourceAvailable={false}
          isOtherCourseAvailable={false}
        />
      </div>
    </div>
  )
}

export default LearningModel
