import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSortAmountAsc } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import { DeleteIcon, EditIcon } from "../../views/Icons";


const CourseCategory = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [courseData, setCourseData] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);
  const [loading, setLoading] = useState(false);

  const base_url = process.env.REACT_APP_BASE_URL;
  const defaultImage = "/images/course-category.png"; 

 const fetchCategory = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${base_url}/get-course-category`, {
        headers: { token: token },
      });

      if (Array.isArray(response.data.responseData)) {
        setCourseData(response.data.responseData);
      } else {
        console.error("Invalid response format: expected an array");
        setCourseData([]);
      }
    } catch (error) {
      console.error("Error fetching course category:", error);
      setCourseData([]);
    }
  };

  // Fetch categories on initial render
  useEffect(() => {
    fetchCategory();
  }, []);

  const handleFileChange = (e) => {
    setThumbnail(e.target.files[0]);
  };

const handleSubmit = async () => {
    setLoading(true);
    const data = {
      name: categoryName,
      description: description,
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${base_url}/add-course-category`,
        data,
        {
          headers: { token: token },
        }
      );

      if (response.data.success) {
        toast.success("Category added successfully!");
        setCategoryName("");
        setDescription("");
        setIsModalOpen(false);
        fetchCategory(); // Refresh the course categories
      } else {
        toast.error("Failed to add category.");
      }
    } catch (error) {
      console.error("Error adding category:", error);
      toast.error("Failed to add category. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  const toolColors = [
    "bg-[#C93232]",
    "bg-[#C9BA32]",
    "bg-[#59C932]",
    "bg-[#32C96E]",
    "bg-[#32AEC9]",
  ];

  return (
    <div className="p-6">
      {/* Header Section */}
      <div className="flex flex-col sm:flex-row sm:justify-between items-center mb-6">
        <div className="flex items-center mb-4 sm:mb-0">
          <button className="p-2 border border-gray-300 rounded-xl bg-white">
            <FontAwesomeIcon icon={faFilter} className="text-[#757D8A]" />
          </button>
          <div className="ml-4 p-3 text-[#757D8A] text-sm border border-gray-300 rounded-xl bg-white">
            <button>
              <FontAwesomeIcon
                icon={faSortAmountAsc}
                className="text-[#757D8A]"
              />
              Sort: Graphic Design
            </button>
          </div>
        </div>

        
        <button
          className="bg-[#3A8BC3] text-white px-4 py-2 rounded-md"
          onClick={() => setIsModalOpen(true)}
        >
          + Add Category
        </button>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center p-4 z-10">
          <div className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Add Category</h2>

            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                Category Name
              </label>
              <input
                type="text"
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                Description
              </label>
              <textarea
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                Category Thumbnail
              </label>
              <input type="file" onChange={handleFileChange} />
            </div>

            <div className="flex justify-end">
              <button
                className="bg-[#3A8BC3] text-white px-4 py-2 rounded-md mr-2"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Saving..." : "Save Information"}
              </button>
              <button
                className="bg-gray-400 text-white px-4 py-2 rounded-md"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Courses Grid */}
      {Array.isArray(courseData) && courseData.length > 0 ? (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {courseData?.map((course, index) => (
          <div
            key={index}
            className="bg-white shadow-md rounded-lg overflow-hidden hover:scale-105 transition-transform duration-300"
          >
            <img
              src={course?.image || defaultImage}
              alt={course?.name || "Course Thumbnail"}

              className="w-full h-48 object-cover"
            />
            <div className="p-4">
              <h3 className="text-xl font-semibold mb-2"> {course?.name || "Untitled Course"}</h3>
              <p className="text-gray-600 mb-4"> {course?.description || "No description available."}</p>
              <div className="flex flex-wrap gap-2">
              {Array.isArray(course?.tools) &&
                  course.tools.map((tool, i) => (
                    <span
                      key={i}
                      className={`text-white ${
                        toolColors[i % toolColors.length]
                      } text-sm font-medium py-1 px-3 rounded-lg`}
                    >
                      {tool}
                    </span>
                  ))}
              </div>
            </div>
          {/* Edit and Delete Buttons */}
          <div className="relative bottom-2 left-2 flex gap-2">
              <button className="">
                <EditIcon/>
              </button>
              <button className="">
                <DeleteIcon/>
              </button>
            </div>
          </div>
        ))}
      </div>
       ) : (
        <p>No categories available</p> 
      )}
    </div>
  );
};

export default CourseCategory;
