import React from 'react'

const Excellence = () => {
  return (
    <section className=' w-full bg-[#0b5397] text-white py-10 px-10 lg:px-60 mt-20 flex flex-col items-center justify-center gap-8'>
        <h1 className=' text-4xl text-center font-bold'>Centre of Excellence</h1>
        <p className='text-lg font-semibold text-center'>Our Centre of Excellence is the heart of Chain Edge, featuring the latest technology and resources. Here, you'll engage in cutting-edge research and development, and collaborate with experts on innovative projects.</p>

        <button className=' bg-white px-6 py-2 text-[#0b5397] rounded-full'>Explore More</button>
    </section>
  )
}

export default Excellence