import React, {useState} from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Cell,
  } from "recharts";
  import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
  import Speedometer from "react-d3-speedometer";
const DashboardTab = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const barData = [
        { name: "Jan", value: 40 },
        { name: "Feb", value: 50 },
        { name: "Mar", value: 60 },
        { name: "Apr", value: 45 },
        { name: "May", value: 55 },
        { name: "Jun", value: 65 },
        { name: "Jul", value: 50 },
        { name: "Aug", value: 80 },
        { name: "Sep", value: 60 },
        { name: "Oct", value: 70 },
        { name: "Nov", value: 50 },
        { name: "Dec", value: 40 },
      ];
    
      const gaugeData1 = 65;
      const gaugeData2 = 68;
      const speedometerValue = 75;
      const gaugeData3 = 65;
      const gaugeData4 = 65;
  return (
    <div className="dashboard-content">
            {/* Stats and Graphs Section */}
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              <div className="lg:col-span-2 bg-white p-4 rounded shadow">
                <h2 className="text-lg font-semibold mb-4">
                  Monthly Performance
                </h2>
                <ResponsiveContainer width="100%" height={250}>
                  <BarChart data={barData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip
                      cursor={{ fill: "transparent" }}
                      contentStyle={{
                        backgroundColor: "#f9fafb",
                        borderRadius: "5px",
                        border: "1px solid #e5e7eb",
                      }}
                    />
                    <Bar dataKey="value" radius={[10, 10, 0, 0]} fill="#3A8BC3">
                      {barData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={index === hoveredIndex ? "#3A8BC3" : "#D1D5DB"}
                          fillOpacity={1}
                          onMouseOver={() => setHoveredIndex(index)}
                          onMouseOut={() => setHoveredIndex(null)}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>

              <div className="bg-white p-4 rounded shadow">
                <h2 className="text-lg font-semibold mb-4">August Summary</h2>
                <div className="flex flex-col items-center justify-between">
                  {/* Circular Chart with Percentage */}
                  <div style={{ width: 200, height: 200 }}>
                    <CircularProgressbar
                      value={gaugeData4}
                      text={`${gaugeData4}%`}
                      styles={buildStyles({
                        pathColor: "#3A8BC3",
                        textColor: "black",
                        trailColor: "#D1D5DB",
                      })}
                    />
                  </div>

                  {/* Small Bar Chart with Hidden Values */}
                  <ResponsiveContainer
                    width="100%"
                    height={100}
                    className="mt-4"
                  >
                    <BarChart data={barData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" tick={false} />
                      <YAxis tick={false} />
                      <Tooltip />
                      <Bar dataKey="value" fill="#3A8BC3">
                        {barData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill="#3A8BC3" />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>

            {/* Gauge Stats Section */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-5">
              <div className="group bg-white p-4 rounded shadow text-center transition-colors duration-300 ease-in-out hover:bg-blue-gray-200 hover:text-white flex flex-col items-center justify-between h-full">
                <CircularProgressbar
                  value={gaugeData1}
                  text={`${gaugeData1}%`}
                  styles={buildStyles({
                    pathColor: "#3A8BC3",
                    textColor: "black",
                    trailColor: "#D1D5DB",
                  })}
                />
                <h3 className="text-lg font-semibold mt-4">Stat title here</h3>
              </div>

              <div className="group bg-white p-4 rounded shadow text-center transition-colors duration-300 ease-in-out hover:bg-blue-gray-200 hover:text-white flex flex-col items-center justify-between h-full">
                <CircularProgressbar
                  value={gaugeData2}
                  text={`${gaugeData2}%`}
                  styles={buildStyles({
                    pathColor: "#3A8BC3",
                    textColor: "black",
                    trailColor: "#D1D5DB",
                  })}
                />
                <h3 className="text-lg font-semibold mt-4">Stat title here</h3>
              </div>

              <div className="group bg-white p-4 rounded shadow text-center transition-colors duration-300 ease-in-out hover:bg-blue-gray-200 hover:text-white flex flex-col items-center justify-between h-full w-full md:w-auto max-w-full">
                <Speedometer
                  value={speedometerValue}
                  needleHeightRatio={0.7}
                  maxValue={100}
                  segments={10}
                  ringWidth={20}
                  needleColor="#3A8BC3"
                  textColor="black"
                  segmentColors={["#3A8BC3", "#D3D3D3"]}
                  customSegmentStops={[0, speedometerValue, 100]}
                  height={150} // Default height
                  width={240} // Width set for medium screen sizes
                  style={{ maxWidth: "100%" }} // Ensure it doesn't overflow
                />
                <h3 className="text-lg font-semibold mt-4">Stat title here</h3>
              </div>

              <div className="group bg-white p-4 rounded shadow text-center transition-colors duration-300 ease-in-out hover:bg-blue-gray-200 hover:text-white flex flex-col items-center justify-between h-full">
                <CircularProgressbar
                  value={gaugeData3}
                  text={`${gaugeData3}%`}
                  styles={buildStyles({
                    pathColor: "#3A8BC3",
                    textColor: "black",
                    trailColor: "#D1D5DB",
                  })}
                />
                <h3 className="text-lg font-semibold mt-4">Stat title here</h3>
              </div>
            </div>
            </div>
            

  );
};

export default DashboardTab;
