import React from "react";
import { Line } from "react-chartjs-2";

const LineChart = () => {
  const data = {
    labels: ["Dec 1", "Dec 2", "Dec 3", "Dec 4", "Dec 5", "Dec 6"],
    datasets: [
      {
        label: "Last 6 Days",
        data: [2000, 2500, 3000, 2200, 2700, 2568], 
        borderColor: "rgba(100, 99, 214, 0.6)", 
       
      },
      {
        label: "Last Week",
        data: [2100, 2300, 2500, 2400, 2000, 2200], 
        borderColor: "rgba(200, 200, 200, 0.6)", 
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, 
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
        grid: {
          display: false, 
        },
      },
      y: {
        title: {
          display: true,
          text: "Sales",
        },
        grid: {
          borderDash: [2, 2], 
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default LineChart;
