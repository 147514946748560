import React, { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthGuard from '../../component/authGuard';

const AppContent = ({ routes }) => {

  AppContent.propTypes = {
    routes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        component: PropTypes.elementType.isRequired,
        exact: PropTypes.bool,
        guard: PropTypes.bool,
        routes: PropTypes.array,
        layout: PropTypes.elementType,  
      })
    ).isRequired,
  };

  return (
    <Fragment>
      <Routes>
        {routes.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard ? AuthGuard : Fragment;
          const Layout = route.layout || Fragment;

          return (
            <Route
              key={i}
              path={route.path}
              element={
                <Guard allowedRoles={route.allowedRoles}>
                  <Layout>
                    {/* If there are nested routes, render them using another <Routes> */}
                    {route.routes ? (
                      <Routes>
                        {route.routes.map((nestedRoute, j) => {
                          const NestedComponent = nestedRoute.component;
                          const NestedGuard = nestedRoute.guard ? AuthGuard : Fragment;
                          const NestedLayout = nestedRoute.layout || Fragment;

                          return (
                            <Route
                              key={j}
                              path={nestedRoute.path}
                              element={
                                <NestedGuard allowedRoles={nestedRoute.allowedRoles}>
                                  <NestedLayout>
                                    <NestedComponent />
                                  </NestedLayout>
                                </NestedGuard>
                              }
                            />
                          );
                        })}
                      </Routes>
                    ) : (
                      <Component />
                    )}
                  </Layout>
                </Guard>
              }
            />
          );
        })}
      </Routes>
    </Fragment>
  );
};

export default AppContent;