import React from 'react'
import { Grid } from '@mui/material';

const data = [
    {
        title: "Industry Mentorship",
        desc: "Network with industry experts and be mentored by them"
    },
    {
        title: "Project Portfolio",
        desc: "Build job-ready profile with dynamic portfolio"
    },
    {
        title: "Interview Opportunities",
        desc: "Get interviews for desired roles in the top companies"
    },
    {
        title: "Alumni Network",
        desc: "Leverage high-impact alumni network of PW Skills learners"
    },
    {
        title: "Career Growth",
        desc: "LGet opportunities to elevate and fast track your career"
    },
    {
        title: "Certification",
        desc: "Certification Attain industry renowned certificates for internship and course completion"
    }
]
const CourseDetails = () => {
    return (

        <Grid container spacing={4}>
            <Grid item xs={12}>
                <h2 className='font-semibold text-3xl text-center'>
                    About the Course
                </h2>
                <br />
            </Grid>
            <Grid item xs={12} md={6}>
                <img src="/images/bannerDetail.png" alt="img" />
            </Grid>
            <Grid item xs={12} md={6}>
                <div className='flex flex-col gap-y-10'>

                    <p className=' text-md '>Enroll in our Job Assistance UI/UX program and learn to create and optimize crucial designs and strategies. Equip yourself with skills and fundamentals to become a top-notch designer and explore multiple opportunities in top IT Firms.
                        Industry Mentorship
                        Project Portfolio
                        Network with industry experts and be mentored by them
                        Build job-ready profile with dynamic portfolio
                        Interview Opportunities
                        Alumni Network
                        Get interviews for desired roles in the top companies
                        Leverage high-impact alumni network of PW Skills learners
                        Career Growth
                        Certification
                        Get opportunities to elevate and fast track your career
                        Attain industry renowned certificates for internship and course completion</p>
                    <Grid container spacing={4}>
                        {data.map((data,i)=>{
                            return(
                                <Grid item xs={12} md={6}>
                                    <p className='font-bold text-sm flex items-center gap-2'><img className='w-5' src="/images/greentick.svg" alt="tick" />{data.title}</p>
                                    <p className='text-slate-600 text-xs'>{data.desc}</p>
                                </Grid>
                            )
                        })}
                   
                    </Grid>
                </div>
            </Grid>


        </Grid>

    )
}

export default CourseDetails;