import React, { useState, useEffect } from "react";
import { DeleteIcon } from "../../views/Icons";

const AddQuiz = ({ onQuizDataChange, handleChange }) => {
  const [isQuizVisible, setIsQuizVisible] = useState(false);
  const [questions, setQuestions] = useState([
    { question: "", options: [""], correctOption: 0 },
  ]);

  useEffect(() => {
    const formattedQuizData = questions.map((q) => ({
      questionText: q.question,
      options: q.options.map((option, index) => ({
        optionText: option,
        isCorrect: index === q.correctOption,
      })),
    }));
    handleChange(isQuizVisible);
    onQuizDataChange(formattedQuizData);
  }, [questions, onQuizDataChange, handleChange]);

  const handleSwitchChange = () => {
    setIsQuizVisible((prev) => !prev);
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      { question: "", options: [""], correctOption: 0 },
    ]);
  };

  const deleteQuestion = (index) => {
    const updatedQuestions = questions.filter((_, qIndex) => qIndex !== index);
    setQuestions(updatedQuestions);
  };

  const addOption = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].options.push("");
    setQuestions(updatedQuestions);
  };

  const deleteOption = (qIndex, optIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].options.splice(optIndex, 1);
    setQuestions(updatedQuestions);
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].question = value;
    setQuestions(updatedQuestions);
  };

  const handleOptionChange = (qIndex, optIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].options[optIndex] = value;
    setQuestions(updatedQuestions);
  };

  const handleCorrectOptionChange = (qIndex, optIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[qIndex].correctOption = optIndex;
    setQuestions(updatedQuestions);
  };

  return (
    <div className="mt-8">
      <div className="flex items-center mb-4 gap-2">
        <label className="flex items-center cursor-pointer">
          <input
            type="checkbox"
            checked={isQuizVisible}
            onChange={handleSwitchChange}
            className="sr-only"
          />
          <div
            className={`w-10 h-6 rounded-full relative transition-colors ${
              isQuizVisible ? "bg-[#3A8BC3]" : "bg-gray-300"
            }`}
          >
            <div
              className={`w-4 h-4 bg-white rounded-full absolute top-1 transition-transform ${
                isQuizVisible ? "translate-x-5" : "translate-x-1"
              }`}
            />
          </div>
        </label>
        <h2 className="text-lg">Add Quiz</h2>
      </div>

      {isQuizVisible && (
        <div>
          {questions.map((question, qIndex) => (
            <div key={qIndex} className="border p-4 mb-4">
              <input
                type="text"
                placeholder={`Question ${qIndex + 1}`}
                value={question.question}
                onChange={(e) => handleQuestionChange(qIndex, e.target.value)}
                className="border p-2 w-full mb-2 text-sm"
              />
              {question.options.map((option, optIndex) => (
                <div key={optIndex} className="flex items-center gap-4 mb-2">
                  <input
                    type="text"
                    placeholder={`Option ${optIndex + 1}`}
                    value={option}
                    onChange={(e) =>
                      handleOptionChange(qIndex, optIndex, e.target.value)
                    }
                    className="border p-2 w-full sm:w-1/2 lg:w-1/4 text-sm"
                  />
                  <input
                    type="radio"
                    name={`correctOption-${qIndex}`}
                    checked={question.correctOption === optIndex}
                    onChange={() => handleCorrectOptionChange(qIndex, optIndex)} className="ml-2"
                  />
                  <button
                    type="button"
                    onClick={() => deleteOption(qIndex, optIndex)}
                    className="ml-2 text-sm"
                  >
                    <DeleteIcon />
                  </button>
                </div>
              ))}
              <div className="flex flex-wrap gap-2 mt-4">
              <button
                type="button"
                onClick={() => addOption(qIndex)}
                className="bg-[#3A8BC3] text-white px-4 py-2 rounded text-xs sm:text-sm"
              >
                Add Option
              </button>
              {/* Conditional rendering for the Delete Question button */}
              {qIndex > 0 && (
                <button
                  type="button"
                  onClick={() => deleteQuestion(qIndex)}
                  className="bg-red-500 text-white px-4 py-2 rounded text-xs sm:text-sm"
                >
                  Delete Question
                </button>
              )}
            </div>
            </div>
          ))}

          <button
            type="button"
            onClick={addQuestion}
            className="bg-[#3A8BC3] text-white px-4 py-2 rounded mt-4 text-xs sm:text-sm"
          >
            Add Question
          </button>
        </div>
      )}
    </div>
  );
};

export default AddQuiz;
