import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { jwtDecode } from 'jwt-decode';

export default function AuthGuard({ children, allowedRoles }) {
  const [auth, setAuth] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true); // State to manage loading

  useEffect(() => {
    const token = window.localStorage.getItem("token");

    if (token) {
      try {
        const decodedToken = jwtDecode(token); // Decode the token
        setUserRole(decodedToken.role); // Assuming 'role' is a property in your token
        setAuth(true);
      } catch (error) {
        console.error("Token is invalid", error);
        setAuth(false);
      }
    } else {
      setAuth(false); // No token found
    }
    setLoading(false); // Set loading to false after checking the token
  }, []);

  // While loading, you might want to show a spinner or just keep the guard active
  if (loading) {
    return <div>Loading...</div>; // You can replace this with a spinner or any loading component
  }

  // Redirect to login if not authenticated
  if (!auth) {
    return <Navigate to="/login" replace />;
  }

  // Redirect if the user's role is not in allowedRoles
  if (allowedRoles && !allowedRoles.includes(userRole)) {
    return <Navigate to="/unauthorized" replace />;
  }

  return <>{children}</>;
}

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};
