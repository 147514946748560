import React from 'react'

const Eligible = () => {
  return (
    <div className='w-full mb-20'>
        <div className='w-full flex flex-col justify-center items-center '>
            <h1 className='text-4xl font-sans text-center'>Coming Soon!</h1>
            <img src='/images/wait.svg' alt='wait' />
        </div>
    </div>
  )
}

export default Eligible;