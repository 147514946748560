import React from "react";
import { FaUserAlt } from "react-icons/fa";
import { GoClockFill } from "react-icons/go";
import { IoIosStar } from "react-icons/io";
import { MdOutlineVideoLibrary } from "react-icons/md";
import { Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

const CourseCategory = ({ title, data, addToEnrolledCourses }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const Card = ({ course, title }) => {
    const handleEnroll = async (id) => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${baseUrl}/enroll-course?courseId=${course._id}`,
          {
            headers: {
              token: token,
            },
          }
        );

        if (response.data.success) {
          toast("Enrolled SuccessFully!");
          addToEnrolledCourses(course);
        }
      } catch (error) {
        console.error("Error enrolling in course:", error);
        toast.error("Failed to enroll in course.");
      }
    };

    return (
      <div className="flex flex-col items-center w-[350px] h-[450px] shadow-lg rounded-lg">
        <img
          src={course.thumbnailUrl}
          alt={course.title}
          className="w-full h-[200px] object-cover rounded-t-lg"
        />
        <h2 className="text-xl font-semibold text-center mt-5 text-gray-700">
          {course.title}
        </h2>
        <div className="grid grid-cols-2 mt-5 gap-x-8 gap-y-2">
          <p className="text-gray-600 text-sm flex items-center">
            <span className="mr-1 text-[#3A8BC3]">
              <GoClockFill />
            </span>
            {course.duration} hrs
          </p>
          <p className="text-gray-600 text-sm flex items-center">
            <span className="mr-1 text-[#3A8BC3]">
              <FaUserAlt />
            </span>
            {course.deliveryMode === "live" ? "Live" : "Self-Paced"}
          </p>
          <p className="text-gray-600 text-sm flex items-center">
            <span className="mr-1 text-[#3A8BC3]">
              <MdOutlineVideoLibrary />
            </span>
            {course.lessons.length} Lessons
          </p>
          <p className="text-gray-600 text-sm flex items-center">
            <span className="mr-1 text-[#3A8BC3]">
              <IoIosStar />
            </span>
            4.6 (3K reviews)
          </p>
        </div>
        <div
          className={`px-10 w-full py-2 flex mt-5 ${
            title === "Enrolled Courses" ? "justify-center" : "justify-between"
          }`}
        >
          <Link
            to={
              title === "Enrolled Courses"
                ? `/course-content/${course._id}`
                : `/course-details/${course._id}`
            }
          >
            <button
              className={`bg-white text-[#646464] font-semibold py-2 ${
                title === "Enrolled Courses" ? "px-20" : "px-4"
              } 
      border border-[#646464] rounded hover:bg-[#646464] hover:text-white mb-2`}
            >
              View More
            </button>
          </Link>

          {title !== "Enrolled Courses" && (
            <button
              onClick={() => handleEnroll(course._id)}
              className="bg-[#3A8BC3] text-white font-semibold py-2 px-4 border border-blue-500 rounded hover:bg-blue-700 mb-2"
            >
              Enroll Now
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <section className="flex flex-col w-full gap-5">
      <h1 className="text-3xl font-semibold text-gray-700">{title}</h1>
      {data.length === 0 && title === "Enrolled Courses" ? (
         <p className="text-center text-gray-500 mt-10">
         You're not enrolled in any course.
       </p>
      ) : (
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {data.map((course) => (
          <Card key={course._id} course={course} title={title} />
        ))}
      </div>
      )}
    </section>
  );
};

export default CourseCategory;
