import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dashboard,
  Instructor,
  Enrollment,
  Invoice,
  TA,
} from "../../component/Icon";

const Sidebar = () => {
  const [isCoursesDropdownOpen, setCoursesDropdownOpen] = useState(false);
  const [isEnrollmentDropdownOpen, setEnrollmentDropdownOpen] = useState(false);
  const [isInvoicesDropdownOpen, setInvoicesDropdownOpen] = useState(false);
  const [isTADropdownOpen, setTADropdownOpen] = useState(false);

  return (
    <div className="lg:w-64 w-full bg-white p-4 lg:relative lg:h-full lg:top-0 lg:left-0 lg:bottom-0 lg:z-10 lg:overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-200">
      {/* Logo */}
      <Link to="/">
        <img
          src="/home-banner/logo.png"
          alt="Admin Panel Logo"
          className="w-40 h-8 mb-6"
        />
      </Link>

      {/* Menu Items */}
      <ul>
        <Link to="/instructor/dashboard">
          <li>
            <button className="block p-4 w-full text-left flex items-center bg-white text-[#61677F]">
              Dashboard
            </button>
          </li>
        </Link>

        {/* Instructor Dropdown */}
        <Link to="/instructor/courses">
          <li>
            <button className="p-4 w-full text-left flex items-center justify-between text-[#61677F]">
              Courses
            </button>
          </li>
        </Link>
        {/* Courses Dropdown */}
        {/* <li>
          <button
            onClick={() => setCoursesDropdownOpen(!isCoursesDropdownOpen)}
            className="p-4 w-full text-left flex items-center justify-between text-[#61677F]"
          >
            Add Course
            <span
              className={`transform transition-transform ${
                isCoursesDropdownOpen ? "rotate-90" : "rotate-0"
              }`}
            >
              ⮞
            </span>
          </button>
          {isCoursesDropdownOpen && (
            <ul className="pl-6">
              <Link to="">
                <li className="block p-2 w-full text-left text-[#61677F]">
                  Categories
                </li>
              </Link>
              <Link to="">
                <li className="block p-2 w-full text-left text-[#61677F]">
                  Course List
                </li>
              </Link>
            </ul>
          )}
        </li> */}

        <Link to="/instructor/student">
          <li>
            <button className="p-4 w-full text-left flex items-center justify-between text-[#61677F]">
              Student
            </button>
          </li>
        </Link>
          {/* <Link to="/instructor/instructor-profile">
        <li>
          <button className="p-4 w-full text-left flex items-center justify-between text-[#61677F]">
            My Profile
          </button>
        </li>
        </Link> */}
      </ul>

      {/* Footer Image */}
      <div className="mt-auto p-4">
        <img
          src="/images/admin-sidebar-bottom.png"
          alt="Footer Image"
          className="w-44 h-auto"
        />
      </div>
    </div>
  );
};

export default Sidebar;
