import React from 'react';

const taData = [
    {
      title: "Data Structures in C++, Introduction to C++",
      startDate: "08/08/24",
      endDate: "15/08/24",
      doubtsResolved: 308,
      overallRating: 4.64,
      projectsEvaluated: 3,
    },
    {
      title: "Data Structures in C++",
      startDate: "08/08/24",
      endDate: "15/08/24",
      doubtsResolved: 100,
      overallRating: 2.14,
      projectsEvaluated: 7,
    },
    {
      title: "Data Structures in C++",
      startDate: "08/08/24",
      endDate: "15/08/24",
      doubtsResolved: 50,
      overallRating: 3.12,
      projectsEvaluated: 4,
    },
  ];

const Record = () => {
    return(
        <div className="bg-white min-h-screen">
        <div className="max-w-4xl mx-auto p-5">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:grid-cols-4 mb-6">
            {/* Responsive grid layout with different column counts for small, medium, and large screens */}
            <div className="bg-[#3A8BC3] text-white text-center p-4 rounded-lg">
              <h2 className="text-3xl sm:text-5xl font-semibold pb-4">
                308
              </h2>
              <p className="mt-2 text-sm sm:text-base">Doubts Resolved</p>
            </div>
            <div className="bg-[#3A8BC3] text-white text-center p-4 rounded-lg">
              <h2 className="text-3xl sm:text-5xl font-semibold pb-4">
                4.64
              </h2>
              <p className="mt-2 text-sm sm:text-base">Average Rating</p>
            </div>
            <div className="bg-[#3A8BC3] text-white text-center p-4 rounded-lg">
              <h2 className="text-3xl sm:text-5xl font-semibold pb-4">
                2.71:1
              </h2>
              <p className="mt-2 text-sm sm:text-base">
                Assign to Accept Ratio
              </p>
            </div>
            <div className="bg-[#3A8BC3] text-white text-center p-4 rounded-lg">
              <h2 className="text-3xl sm:text-5xl font-semibold pb-4">
                2.30
              </h2>
              <p className="mt-2 text-sm sm:text-base">
                Avg. Doubts Resolved Per Day
              </p>
            </div>
          </div>
          <h2 className="text-2xl sm:text-3xl font-bold mb-4 pt-8 pb-8">
            Your TAships
          </h2>
          {taData.map((ta, index) => (
            <div
              key={index}
              className="border border-gray-300 rounded-lg p-4 sm:p-5 mb-4"
            >
              <h3 className="text-base sm:text-lg font-semibold text-[#616161]">
                {ta.title}
              </h3>
              <p className="text-sm sm:text-base text-gray-600 mb-4">
                Start Date - {ta.startDate} | End Date - {ta.endDate}
              </p>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 text-center">
                <div>
                  <h4 className="text-3xl sm:text-5xl font-semibold text-[#616161]">
                    {ta.doubtsResolved}
                  </h4>
                  <p className="text-sm sm:text-base text-gray-500">
                    Doubts Resolved
                  </p>
                </div>
                <div>
                  <h4 className="text-3xl sm:text-5xl font-semibold text-[#616161]">
                    {ta.overallRating}
                  </h4>
                  <p className="text-sm sm:text-base text-gray-500">
                    Overall Rating
                  </p>
                </div>
                <div>
                  <h4 className="text-3xl sm:text-5xl font-semibold text-[#616161]">
                    {ta.projectsEvaluated}
                  </h4>
                  <p className="text-sm sm:text-base text-gray-500">
                    Total Evaluated Projects
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
};
export default Record;