import React from 'react'

const HandsOnProject = () => {
  return (
    <div className='w-full bg-white pr-10 lg:grid lg:grid-cols-2 lg:gap-10 mt-20'>
            <div className="flex flex-col">
                <h2 className="text-black text-2xl md:text-4xl lg:text-4xl tracking-wide" style={{borderLeft: '5px solid #0b5397', paddingLeft: '20px'}}>Hands-On Projects</h2>
                <p className="my-4 lg:my-10 text-black text-left text-xl lg:text-2xl lg:tracking-wide">In addition to theoretical learning, Chain Edge emphasizes hands-on projects as a core component of our courses. These projects are designed to challenge learners to apply their knowledge to real-world problems, providing valuable practical experience and skill development. By working on hands-on projects, learners not only reinforce their understanding of key concepts but also build a portfolio of work that showcases their skills to potential employers.</p>
            </div>
            <div className=' w-full bg-[#0b5397] gap-8 p-8 rounded-none sm:rounded-r-full flex flex-col text-white'>
                <h1 className=' text-2xl font-semibold'>Project Names</h1>

                <div className='flex flex-col gap-8'>
                    <div >
                        <h2 className='text-lg font-semibold'>0.1 Project Name 1</h2>
                        <p className='text-sm pr-5'>Lorem ipsum dolor sit amet, consectetuer adipiscingLorem ipsum dolor sit amet, consectetuer adipiscing</p>
                    </div>
                    <div >
                        <h2 className='text-lg font-semibold'>0.1 Project Name 1</h2>
                        <p className='text-sm pr-5'>Lorem ipsum dolor sit amet, consectetuer adipiscingLorem ipsum dolor sit amet, consectetuer adipiscing</p>
                    </div>
                    <div >
                        <h2 className='text-lg font-semibold'>0.1 Project Name 1</h2>
                        <p className='text-sm pr-5'>Lorem ipsum dolor sit amet, consectetuer adipiscingLorem ipsum dolor sit amet, consectetuer adipiscing</p>
                    </div>
                </div>

            </div>
        </div>
  )
}

export default HandsOnProject