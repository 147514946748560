import React, { useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const Contact = () => {
    const [value, setValue] = useState()
  return (
    <section className=' w-full flex flex-col my-20'>
        <h2 className="text-black text-2xl md:text-4xl lg:text-4xl tracking-wide" style={{borderLeft: '5px solid #0b5397', paddingLeft: '20px'}}>Contact Us</h2>
        <div className=' flex w-full lg:flex-row flex-col gap-10 mt-10 '>
            <div className=' w-full lg:w-1/2'>
                <img src='/home-banner/contact.svg' className=' w-full' />
            </div>
            <div className=' w-full lg:w-1/2 p-10 rounded-xl border-2 border-[#0B5397] flex flex-col gap-5'>
                <h1 className=' text-2xl font-semibold text-[#0B5397] '>Get In Touch</h1>
                <span className=' text-lg'>We are here for you. How can we help?</span>
                <form className=' grid grid-cols-2 gap-5'>
                    <input type='text' placeholder='Enter your first name' className=' col-span-1 rounded-lg p-5  border border-gray-800' />
                    <input type='text' placeholder='Enter your last name' className=' col-span-1 rounded-lg p-5  border border-gray-800' />
                    <input type='email' placeholder='Enter your email address' className='col-span-2 rounded-lg p-5  border border-gray-800' />
                    <PhoneInput defaultCountry="IN" value={value} onChange={setValue} placeholder='Enter your phone number' className='flex col-span-2 rounded-lg p-5  border border-gray-800'  />
                    <textarea placeholder='Enter your message' className='h-44 col-span-2 rounded-lg p-5 border border-gray-800' />
                    <div className=' col-span-2 flex justify-center'>
                    <button className=' px-6 py-2 place-self-center text-lg bg-[#0B5397] text-white rounded-full'>Contact us</button>
                    </div>
                </form>
            </div>
        </div>
    </section>
  )
}

export default Contact