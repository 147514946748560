import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFilter, faSortAmountAsc, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function TAList() {
  const [TAs, setTAs] = useState([
    { name: 'Shawn Michael', email: 'thewarhorse41@gmail.com', phone: '+91 9895472301', country: 'Australia', activeCourses: 10, status: 'Active' },
    { name: 'Dave Batista', email: 'thewarhorse41@gmail.com', phone: '+91 8710231230', country: 'Italy', activeCourses: 7, status: 'Inactive' },
    { name: 'Shawn Michael', email: 'thewarhorse41@gmail.com', phone: '+91 9895472301', country: 'Australia', activeCourses: 10, status: 'Pending' },
    { name: 'Dave Batista', email: 'thewarhorse41@gmail.com', phone: '+91 8710231230', country: 'Italy', activeCourses: 7, status: 'Suspend' },
    // Add more TAs here
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false);
  const [suspensionReason, setSuspensionReason] = useState('');
  const [customSuspensionReason, setCustomSuspensionReason] = useState('');

  const [newTA, setNewTA] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    state: '',
    country: '',
    address: ''
  });

  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const dropdownRef = useRef(null);
  const buttonRefs = useRef([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTA((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ta = {
      name: `${newTA.firstName} ${newTA.lastName}`,
      email: newTA.email,
      phone: newTA.phone,
      country: newTA.country,
      activeCourses: 0,
      status: 'Active',
    };
    setTAs((prevState) => [...prevState, ta]);
    setIsModalOpen(false);
    setNewTA({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      state: '',
      country: '',
      address: ''
    });
  };

  const handleDropdownAction = (action, taIndex) => {
    if (action === 'Suspend') {
      setIsSuspendModalOpen(true);  // Open suspend modal
    } else if (action === 'View Details') {
      console.log('View details action for:', TAs[taIndex]);
    }
    setDropdownIndex(null);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'Active':
        return 'text-green-700 bg-green-200';
      case 'Inactive':
        return 'text-red-700 bg-red-200';
      case 'Pending':
        return 'text-yellow-700 bg-yellow-200';
      case 'Suspend':
        return 'text-blue-700 bg-blue-200';
      default:
        return 'text-gray-700 bg-gray-200';
    }
  };

  const toggleDropdown = (index) => {
    setDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownIndex(null);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
 
  const handleCloseSuspendModal = () => {
    setIsSuspendModalOpen(false);
    setSuspensionReason('');
    setCustomSuspensionReason('');
  };
  const handleSuspend = () => {
    const updatedTAs = [...TAs];
    const index = dropdownIndex;
    updatedTAs[index].status = 'Suspend';
    updatedTAs[index].suspensionReason = suspensionReason === 'Custom Reason' ? customSuspensionReason : suspensionReason;
    setTAs(updatedTAs);
    handleCloseSuspendModal();
  };

  return (
    <div className="ta-tab p-4 md:p-6 bg-[#EEF5FF] h-full">
      {/* Search Bar and Add TA Button */}
      <div className="relative flex flex-col md:flex-row justify-between items-center mb-4">
        {/* Container for the search input and icon */}
        <div className="relative w-full md:w-1/3 mb-4 md:mb-0">
          <i className="fas fa-search absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"></i>
          <input
            type="text"
            placeholder="Search by Name"
            className="pl-10 p-2 w-full border border-gray-300 rounded-xl text-center"
          />
        </div>
        {/* Add TA Button */}
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-[#3A8BC3] text-white p-2 rounded-lg"
        >
          + Add TA
        </button>
      </div>

      {/* Filters and Sorting Options */}
      <div className="flex flex-col md:flex-row justify-between items-center mt-8 mb-4">
        <div className="flex items-center mb-4 md:mb-0">
          <button className="p-2 border border-gray-300 rounded-xl bg-white">
            <FontAwesomeIcon icon={faFilter} className="text-[#757D8A]" />
          </button>
          <div className="ml-4 p-2 border border-gray-300 rounded-xl bg-white">
            <button>
              <FontAwesomeIcon icon={faSortAmountAsc} className="text-[#757D8A]" />
            </button>
            <span className="ml-2 text-sm font-semibold text-[#757D8A]">Sort: Status</span>
          </div>
        </div>
      </div>

      {/* TA List */}
      <div className="overflow-x-auto lg:overflow-x-visible xl:overflow-x-visible">
        <table className="w-full bg-white rounded shadow">
          <thead>
            <tr>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">TA</th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">Email</th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">Phone</th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">Country</th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">Active Courses</th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">Status</th>
              <th className="p-2 text-left text-[#9EB1C2] border-b border-gray-300">Actions</th>
            </tr>
          </thead>
          <tbody>
            {TAs.map((ta, index) => (
              <tr key={index} className="border-b text-[#9EB1C2] border-gray-300">
                <td className="p-2">{ta.name}</td>
                <td className="p-2">{ta.email}</td>
                <td className="p-2">{ta.phone}</td>
                <td className="p-2">{ta.country}</td>
                <td className="p-2">{ta.activeCourses}</td>
                <td className="p-2 flex items-center">
                  <span className={`inline-block px-2 py-1 rounded-full ${getStatusClass(ta.status)}`}>
                    {ta.status}
                  </span>
                </td>
                <td className="p-2 relative">
                  <button
                    ref={(el) => (buttonRefs.current[index] = el)}
                    onClick={() => toggleDropdown(index)}
                    className="p-2 bg-inherit rounded-full"
                  >
                    &#65049;
                  </button>
                  {dropdownIndex === index && (
                    <div ref={dropdownRef} className="fixed w-40 right-2 bg-white border border-gray-300 rounded shadow-lg z-50">
                      <ul>
                        <li>
                          <button
                            onClick={() => handleDropdownAction('Suspend', index)}
                            className="w-full text-left p-2 hover:bg-gray-100"
                          >
                            <FontAwesomeIcon icon={faUserSlash} className="mr-2" />
                            Suspend
                          </button>
                        </li>
                        <Link to="/admin/ta-detail" >
                        <li>
                          
                          <button
                            onClick={() => handleDropdownAction('View Details', index)}
                            className="w-full text-left p-2 hover:bg-gray-100"
                          >
                            <FontAwesomeIcon icon={faEye} className="mr-2" />
                            View Details
                          </button>
                        </li>
                        </Link>
                      </ul>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

       {/* Suspend Modal */}
{isSuspendModalOpen && (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-xl w-full sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl">
      <h2 className="text-3xl text-center mb-10 text-[#5A5A5A]">
        Suspending TA
      </h2>

      <div className="mb-4 relative">
        <label className="absolute left-16 lg:left-44 md:left-40 sm:left-28 top-[-10px] text-[#9EB1C2] bg-white px-2 text-sm font-semibold">
          Suspension Reason
        </label>
        <select
          value={suspensionReason}
          onChange={(e) => setSuspensionReason(e.target.value)}
          className="max-w-sm w-full p-2 border border-gray-300 rounded ml-0 md:ml-16 placeholder-[#9EB1C2] text-[#9EB1C2] mb-6"
        >
          <option value="">Select Reason</option>
          <option value="Disciplinary Issue">Disciplinary Issue</option>
          <option value="Custom Reason">Custom Reason</option>
        </select>

        {/* Conditional rendering of the text field */}
        {suspensionReason === "Custom Reason" && (
          <div className="mt-4">
            <label className="block mb-2 text-[#9EB1C2] font-semibold">
              Custom Suspension Reason
            </label>
            <input
              type="text"
              value={customSuspensionReason}
              onChange={(e) => setCustomSuspensionReason(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded text-[#9EB1C2] placeholder-[#9EB1C2]"
              placeholder="Enter custom reason"
            />
          </div>
        )}
      </div>

      <div className="flex justify-center md:justify-end mt-6">
        <button
          onClick={handleCloseSuspendModal}
          className="px-4 py-2 bg-[#F4F4F4] rounded mr-2 text-[#848484] mb-4 md:mb-0"
        >
          Cancel
        </button>
        <button
          onClick={handleSuspend}
          className="px-4 py-2 bg-[#3A8BC3] text-white rounded mb-4 md:mb-0"
        >
          Suspend
        </button>
      </div>
    </div>
  </div>
)}

      {/* Add TA Modal */}
      {isModalOpen && (
        <div className="modal fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
          <div className="modal-content bg-white p-6 rounded shadow-lg max-w-lg w-full">
            <h2 className="text-xl font-bold mb-4">Add Teaching Assistant</h2>
            <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4 text-[#9A9A9A]">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={newTA.firstName}
                  onChange={handleInputChange}
                  className="p-2 border w-full"
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={newTA.lastName}
                  onChange={handleInputChange}
                  className="p-2 border w-full"
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={newTA.email}
                  onChange={handleInputChange}
                  className="p-2 border w-full"
                />
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone Number"
                  value={newTA.phone}
                  onChange={handleInputChange}
                  className="p-2 border w-full"
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <input
                  type="text"
                  name="state"
                  placeholder="State"
                  value={newTA.state}
                  onChange={handleInputChange}
                  className="p-2 border w-full"
                />

  <select
    name="country"
    value={newTA.country}
    onChange={handleInputChange}
    className="p-2 border w-full"
  >
    <option value="" disabled>
      Select Country
    </option>
    <option value="India">India</option>
    <option value="Australia">Australia</option>
    <option value="Italy">Italy</option>
    <option value="USA">USA</option>
    <option value="UK">UK</option>
    {/* Add more countries as needed */}
  </select>

              </div>

              <input
                type="text"
                name="address"
                placeholder="Address"
                value={newTA.address}
                onChange={handleInputChange}
                className="p-2 border h-28 mb-2 w-full"
              />

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="mr-4 p-2 bg-gray-200 rounded"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="p-2 bg-[#3A8BC3] text-white rounded"
                >
                  Add TA
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default TAList;
