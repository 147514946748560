import React from 'react'
import { MdArrowRightAlt } from 'react-icons/md'

const BlogItem = [
    {
        title: 'Blockchain Courses that drive you Forward',
        description: 'Lorem ipsum dolor sit amet consectetur. Morbi duis vitae mi facilisi cursus nibh. Dolor scelerisque porttitor bibendum volutpat amet donec ornare massa.',
        image: '/home-banner/logo1.png',
    },
    
    {
        title: 'Scholarship and Internship opportunities ',
        description: 'Lorem ipsum dolor sit amet consectetur. Morbi duis vitae mi facilisi cursus nibh. Dolor scelerisque porttitor bibendum volutpat amet donec ornare massa.',
        image: '/home-banner/logo2.png',
    },
    {
        title: 'Blockchain Product suites for Enterprises',
        description: 'Lorem ipsum dolor sit amet consectetur. Morbi duis vitae mi facilisi cursus nibh. Dolor scelerisque porttitor bibendum volutpat amet donec ornare massa.',
        image: '/home-banner/logo3.png',
    },
]

const Blogs = () => {
    return (
        <section className='  flex mt-32 flex-wrap md:flex-nowrap gap-8 justify-center '>
            {BlogItem.map((d,i) => (
                <div className=' w-[400px] p-5 flex flex-col gap-5 shadow-lg rounded-md border border-blue-gray-100'>
                <img src={d.image} className=' w-24' alt='blog-logo' />
                <h1 className='text-2xl font-semibold text-customFontColorBlack text-wrap'>{d.title}</h1>
                <img src='/home-banner/chain.png' alt='blog' />
                <p className=' text-base '>{d.description}</p>
                <h1 className=' flex gap-2 items-center text-base text-[#2d81ba] cursor-pointer group'>Read More <MdArrowRightAlt className=' text-xl group-hover:translate-x-2 transition-all' /></h1>
            </div>
            ))}
        </section>
      )
}

export default Blogs