import React from 'react'
import { Grid } from '@mui/material';
const button = [
    "Getting to know UI/UX",
    "Mastering the look and feel",
    "Drawing and planning designs",
    "Learning Figma"

]
const data = [
    {
        title: "Industry Mentorship",
        desc: "Network with industry experts and be mentored by them"
    },
    {
        title: "Project Portfolio",
        desc: "Build job-ready profile with dynamic portfolio"
    },
    {
        title: "Interview Opportunities",
        desc: "Get interviews for desired roles in the top companies"
    },
    {
        title: "Alumni Network",
        desc: "Leverage high-impact alumni network of PW Skills learners"
    },
    {
        title: "Career Growth",
        desc: "LGet opportunities to elevate and fast track your career"
    },
    {
        title: "Certification",
        desc: "Certification Attain industry renowned certificates for internship and course completion"
    }
]
const CourseDetails = () => {
    return (

        <Grid container spacing={4}>
            <Grid item xs={12}>
                <h2 className='font-semibold text-3xl text-center'>
                    Course Curriculum
                </h2>
                <br />
            </Grid>
            <Grid item xs={12} >
                <div className='w-full overflow-x-auto flex gap-5 justify-center'>
                    {button.map((data, i) => {
                        return (
                            <button key={i} className="w-fit bg-[#3A8bc3] hover:greenblue text-xs lg:text-sm text-white font-bold py-2 px-6 rounded-md focus:outline-none focus:shadow-outline">{data}</button>
                        )
                    })}
                </div>
            </Grid>
          
            <Grid item xs={12} >
                <div className='p-4 rounded-lg border-1 border-customgrey'>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <div className='border-b border-customgrey py-2 px-2'>
                                <p className='text-xs'>Module 1</p>
                                <p className='text-md font-bold'>What is UI/UX?</p>

                            </div>
                            <div className='border-b border-customgrey py-2 px-2'>
                                <p className='text-xs'>Module 1</p>
                                <p className='text-md font-bold'>What is UI/UX?</p>

                            </div>
                            <div className='border-b border-customgrey py-2 px-2'>
                                <p className='text-xs'>Module 1</p>
                                <p className='text-md font-bold'>What is UI/UX?</p>

                            </div>
                            <div className='border-b border-customgrey py-2 px-2'>
                                <p className='text-xs'>Module 1</p>
                                <p className='text-md font-bold'>What is UI/UX?</p>

                            </div>
                            <div className='border-b border-customgrey py-2 px-2'>
                                <p className='text-xs'>Module 1</p>
                                <p className='text-md font-bold'>What is UI/UX?</p>

                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <div className=' rounded-lg border-1 border-customgrey'>
                                <div className='border-b border-customgrey py-2 px-2'>
                                    <p style={{ color: "grey" }} className='font-bold text-sm flex items-center justify-between'>Lecture 1 : UI Design Principles<img className='w-5' src="/images/playIcon.svg" alt="tick" /></p>
                                </div>
                                <div className='border-b border-customgrey py-2 px-2'>
                                    <p style={{ color: "grey" }} className='font-bold text-sm flex items-center justify-between'>Lecture 2 : Typography<img className='w-5' src="/images/lockIcon.svg" alt="tick" /></p>
                                </div>
                                <div className='border-b border-customgrey py-2 px-2'>
                                    <p style={{ color: "grey" }} className='font-bold text-sm flex items-center justify-between'>Lecture 1 : UX Concepts<img className='w-5' src="/images/lockIcon.svg" alt="tick" /></p>
                                </div>
                            </div>

                        </Grid>
                    </Grid>
                </div>
            </Grid>

        </Grid>

    )
}

export default CourseDetails;