import React from 'react'
import { Link } from 'react-router-dom'

const Taships = () => {

  const JobCard = () => {
    return (
        <div className='w-full rounded-lg flex gap-6 p-10 bg-[#f6f6f6] border border-gray-300 shadow-md'>
            <div className=' w-[10%] flex justify-center'>
                <img src='/home-banner/logo-foot.png' alt='tiger' className='w-20 h-20' />
            </div>
            <div className='w-[70%] flex flex-col gap-8 text-gray-800'>
                <h1 className=' text-3xl font-semibold'>Competitive Programming (C++) | Teaching Assistant</h1>
                <span className='text-lg'>Blockmosaic</span>
                <div className='flex gap-6'>
                    <span className='text-sm'>Work from Home</span>
                    <span className='text-sm'>Internship</span>
                    <span className='text-sm'>Upto 25K (Performance Based for complete 3 month Duration)</span>
                </div>

                <span className=' mt-10 font-semibold text-base'>Application Deadline - Dec, 31 2026, 11:59 PM</span>
            </div>
            <div className='w-[20%] flex justify-center items-end'>
                <Link to='/job-description' ><button className='bg-[#2d81ba] text-white font-semibold py-3 px-10 rounded-md shadow-sm'>View Details</button></Link>
            </div>
        </div>
    )
  } 


  return (
    <div className='w-full flex flex-col gap-10 mb-20'>
        <JobCard />
        <JobCard />
        <JobCard />
    </div>
  )
}

export default Taships;