import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppContent } from './views';
import routes from './routes';
import "./app.css";
import { Toaster } from "react-hot-toast";


function App() {
  return (
    <Router>
      <Toaster />
      <AppContent routes={routes} />
    </Router>
  );
}

export default App;
