import React from 'react'

const Goals = () => {
  return (
    <section className='flex flex-col lg:flex-row w-full mt-20'>
        <div className=' w-[500px] self-center lg:w-[40%] rounded-lg'>
            <img src='/home-banner/goals.png' className='w-full' alt='goals' />
        </div>
        <div className=' w-full lg:w-[60%] p-10 flex flex-col gap-3'>
            <h1 className=' text-[#2d81ba] text-3xl lg:text-5xl font-semibold text-center text-wrap'>
            Achieve your Goals with Chainedge
            </h1>

            <p className='ml-0 lg:ml-10 mt-10 flex gap-6 font-medium items-center text-xl lg:text-3xl text-gray-700'>
            <img src='/home-banner/pencil.png' alt='goals' />
            Get 1:1 Mentorship by Top 1%
            </p>
            <p className='ml-0 lg:ml-10 mt-10 flex gap-6 font-medium items-center text-xl lg:text-3xl text-gray-700'>
            <img src='/home-banner/pencil.png' alt='goals' />
            Network with aspirational Peer Group
            </p>
            <p className='ml-0 lg:ml-10 mt-10 flex gap-6 font-medium items-center text-xl lg:text-3xl text-gray-700'>
            <img src='/home-banner/pencil.png' alt='goals' />
            Get Interview Ready
            </p>
            <p className='ml-0 lg:ml-10 mt-10 flex gap-6 font-medium items-center text-xl lg:text-3xl text-gray-700'>
            <img src='/home-banner/pencil.png' alt='goals' />
            Undertake Structured Courses by Experts
            </p>

        </div>
    </section>
  )
}

export default Goals