import React from 'react'
import { FaRegCircleDot } from 'react-icons/fa6'
import { MdCircle } from 'react-icons/md'

const Applied = () => {

    const JobCard = () => {
        return (
            <div className='w-full rounded-lg flex flex-col bg-[#f6f6f6] border border-gray-300 shadow-md'>
               <div className='w-full flex gap-6 p-10 border-b-2 border-gray-300'>
                    <div className=' w-[10%] flex justify-center'>
                        <img src='/home-banner/logo-foot.png' alt='tiger' className='w-20 h-20' />
                    </div>
                    <div className='w-[70%] flex flex-col gap-8 text-gray-800'>
                        <h1 className=' text-3xl font-semibold'>Competitive Programming (C++) | Teaching Assistant</h1>
                        <span className='text-lg'>Blockmosaic</span>
                        <div className='flex gap-6'>
                            <span className='text-sm'>Work from Home</span>
                            <span className='text-sm'>Internship</span>
                            <span className='text-sm'>Upto 25K (Performance Based for complete 3 month Duration)</span>
                        </div>
        
                        <span className=' mt-10 font-semibold text-base'>Application Deadline - Dec, 31 2026, 11:59 PM</span>
                    </div>
                    <div className='w-[20%] flex justify-center items-end'>
                        <button className='bg-[#2d81ba] text-white font-semibold py-3 px-10 rounded-md shadow-sm'>View Details</button>
                    </div>
               </div>
               <div className='w-full p-10 flex flex-col'>
                    <h1 className=' text-2xl text-gray-800 font-semibold'>Application Status</h1>
                    <ul class=" grid max-w-md grid-cols-1 sm:mt-16  lg:max-w-5xl lg:grid-cols-4">
                        <li class="flex-start group relative flex lg:flex-col">
                            <span
                                class="absolute  top-14 h-[calc(100%_-_32px)] w-[3px] bg-green-300 lg:right-0  lg:top-[18px] lg:h-[3px] lg:w-[calc(100%_-_2px)]"
                                aria-hidden="true"></span>
                            <div
                                className="inline-flex h-10 w-10 shrink-0 items-center justify-start text-green-300 ">
                                <MdCircle />
                            </div>
                            <div class="ml-6 lg:ml-0 lg:mt-10">
                                <h3
                                    class="text-base font-bold text-gray-900 before:mb-2 before:block before:font-mono before:text-sm before:text-gray-500">
                                    Applied
                                </h3>
                                <h4 class="mt-2 text-base text-gray-700">jun 13, 2020</h4>
                            </div>
                        </li>
            
                        <li class="flex-start group relative flex lg:flex-col">
                            <span
                                class="absolute  top-14 h-[calc(100%_-_32px)] w-[3px] bg-green-300 lg:right-0  lg:top-[18px] lg:h-[3px] lg:w-[calc(100%)]"
                                aria-hidden="true"></span>
                            <div
                                className="inline-flex h-10 w-10 shrink-0 items-center rounded-xl  transition-all duration-200 text-green-300 ">
                                <MdCircle />
                            </div>
                            <div class="ml-6 lg:ml-0 lg:mt-10">
                                <h3
                                    class="text-base font-bold text-gray-900 before:mb-2 before:block before:font-mono before:text-sm before:text-gray-500">
                                    Test Link Sent
                                </h3>
                                <h4 class="mt-2 text-base text-gray-700">jun 13, 2020</h4>
                            </div>
                        </li>
            
                        <li class="flex-start group relative flex lg:flex-col">
    
                            <div
                                className="inline-flex h-10 w-10 shrink-0 items-center  rounded-xl  transition-all duration-200 text-green-300 ">
                                <MdCircle />
                            </div>
                            <div class="ml-6 lg:ml-0 lg:mt-10">
                                <h3
                                    class="text-base font-bold text-gray-900 before:mb-2 before:block before:font-mono before:text-sm before:text-gray-500">
                                    Test Taken
                                </h3>
                                <h4 class="mt-2 text-base text-gray-700">jun 13, 2020</h4>
                            </div>
                        </li>
            
                    </ul>

               </div>
            </div>
        )
      } 
    


  return (
    <div className='w-full flex flex-col gap-10 mb-20'>
        <JobCard />
        <JobCard />
        <JobCard />
    </div>
  )
}

export default Applied