import React from 'react';

const tableData = [
    {
      applicationDate: "Aug 26, 2024",
      startDate: "Aug 27, 2024",
      endDate: "Aug 27, 2024",
      reason: "Lorem ipsum dolor sos santos",
      status: "Approved",
      action: "N.A",
    },
    {
      applicationDate: "Aug 26, 2024",
      startDate: "Aug 27, 2024",
      endDate: "Aug 27, 2024",
      reason: "Lorem ipsum dolor sos santos",
      status: "Pending",
      action: "N.A",
    },
    {
      applicationDate: "Aug 26, 2024",
      startDate: "Aug 27, 2024",
      endDate: "Aug 27, 2024",
      reason: "Lorem ipsum dolor sos santos",
      status: "Declined",
      action: "N.A",
    },
    {
      applicationDate: "Aug 26, 2024",
      startDate: "Aug 27, 2024",
      endDate: "Aug 27, 2024",
      reason: "Lorem ipsum dolor sos santos",
      status: "Approved",
      action: "N.A",
    },
    {
      applicationDate: "Aug 26, 2024",
      startDate: "Aug 27, 2024",
      endDate: "Aug 27, 2024",
      reason: "Lorem ipsum dolor sos santos",
      status: "Approved",
      action: "N.A",
    },
  ];

  const getStatusTextColor = (status) => {
    switch (status) {
      case "Approved":
        return "text-green-600";
      case "Pending":
        return "text-yellow-500";
      case "Declined":
        return "text-red-500";
      default:
        return "text-gray-600";
    }
  };

const Leave = () => {
    return(
        <>
        <div className="max-w-5xl mx-auto p-3 sm:p-5">
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border">
              <thead className="bg-[#3A8BC3] text-white">
                <tr>
                  <th className="py-2 px-2 sm:px-4 border text-xs sm:text-sm">
                    Application Date
                  </th>
                  <th className="py-2 px-2 sm:px-4 border text-xs sm:text-sm">
                    Start Date
                  </th>
                  <th className="py-2 px-2 sm:px-4 border text-xs sm:text-sm">
                    End Date
                  </th>
                  <th className="py-2 px-2 sm:px-4 border text-xs sm:text-sm">
                    Reason
                  </th>
                  <th className="py-2 px-2 sm:px-4 border text-xs sm:text-sm">
                    Request Status
                  </th>
                  <th className="py-2 px-2 sm:px-4 border text-xs sm:text-sm">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr
                    key={index}
                    className={`text-center ${getStatusTextColor(
                      row.status
                    )}`}
                  >
                    <td className="py-2 px-2 sm:px-4 border text-xs sm:text-sm">
                      {row.applicationDate}
                    </td>
                    <td className="py-2 px-2 sm:px-4 border text-xs sm:text-sm">
                      {row.startDate}
                    </td>
                    <td className="py-2 px-2 sm:px-4 border text-xs sm:text-sm">
                      {row.endDate}
                    </td>
                    <td className="py-2 px-2 sm:px-4 border text-xs sm:text-sm">
                      {row.reason}
                    </td>
                    <td className="py-2 px-2 sm:px-4 border text-xs sm:text-sm">
                      {row.status}
                    </td>
                    <td className="py-2 px-2 sm:px-4 border text-xs sm:text-sm">
                      {row.action}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
};
export default Leave;